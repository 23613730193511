export default function parse (item) {
  return {
    id: item.id,
    notes: item.notes,
    status: item.status,
    injury_id: item.injury.id,
    body_part_id: item.body_part.id,
    injury_type_id: item.injury_type ? item.injury_type.id : null,
  };
}
