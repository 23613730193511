export default class TextareaDialogController {
 /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog) {
    this._Dialog = Dialog;
  }

  submit (value) {
    if (!this.form) {
      throw new Error('Form not found.');
    }

    if (this.form.$invalid) {
      return;
    }

    this._Dialog.hide(value);
  };

  cancel () {
    this._Dialog.cancel();
  };
}
