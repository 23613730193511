import angular from 'angular';
import Incident from './components/incident/incident.component';
import FlaggedIncidentDialog from './services/flag-incident-dialog/flag-incident-dialog.service';
import FlaggedIncidentResource from './resources/flagged-incident.resource';
import IncidentDialog from './services/incident-dialog/incident-dialog.service';
import ResolveIncidentDialog from './services/resolve-incident-dialog/resolve-incident-dialog.service';
import IncidentLog from './components/incident-log/incident-log.component';
import IncidentResource from './resources/incident.resource';
import IncidentSummary from './components/incident-summary/incident-summary.component';

const module = angular
  .module('gnaas.core.incidents', [])
  .service('FlaggedIncidentDialog', FlaggedIncidentDialog)
  .service('FlaggedIncidentResource', FlaggedIncidentResource)
  .service('IncidentDialog', IncidentDialog)
  .service('IncidentResource', IncidentResource)
  .service('ResolveIncidentDialog', ResolveIncidentDialog)
  .component('incident', Incident)
  .component('incidentLog', IncidentLog)
  .component('incidentSummary', IncidentSummary);

export default module.name;
