export default function FormSelect () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      classes: '@feClasses',
      disabled: '=feDisabled',
      errors: '=feErrors',
      isEmbedded: '@feIsEmbedded',
      isSearchable: '=feSearchable',
      label: '@feLabel',
      model: '=feModel',
      multiple: '=feMultiple',
      name: '@feName',
      noFloat: '=feNoFloat',
      onChange: '&feOnChange',
      options: '=feOptions',
      optionLabelKey: '@feOptionLabelKey',
      optionValueKey: '@feOptionValueKey',
      optionOrderByKey: '=feOptionOrderByKey',
      required: '=feRequired',
      trackByValue: '=feTrackByValue',
    },
    controller: function ($scope) {
      'ngInject';

      this.optionLabelKey = this.optionLabelKey || 'name';
      this.optionValueKey = this.optionValueKey || null;

      this.clearSearchTerm = () => {
        if (this.isSearchable) {
          this.searchTerm = '';
        }
      };

      $scope.$watch(() => this.model, (newVal, oldVal) => {
        if (newVal && newVal['$$hashKey']) {
          delete newVal['$$hashKey'];
        }
      });
    },
    controllerAs: '$ctrl',
    bindToController: true,
    template: ($element, $attrs) => {
      let searchBox = '';
      if ($attrs.feSearchable) {
        searchBox = `<md-select-header class="form-select__search-header" ng-if="$ctrl.isSearchable">
            <input type="search" class="form-select__search-input md-text" placeholder="Search…"
                ng-model="$ctrl.searchTerm" ng-keydown="$event.stopPropagation()">
        </md-select-header>`;
      }

      let nameAttr = '';
      if ($attrs.feName) {
        nameAttr = 'name="{{ $ctrl.name }}"';
      }

      let modelOptionsTrackBy = '$value.id';
      let trackByAttr = 'track by option.id';
      let optionLabel = '{{ option[$ctrl.optionLabelKey] }}';
      if ($attrs.feTrackByValue) {
        trackByAttr = 'track by option';
        optionLabel = '{{ option }}';
      }
      if ($attrs.feOptionValueKey) {
        trackByAttr = `track by option.${$attrs.feOptionValueKey}`;
      }
      if ($attrs.feTrackByValue || $attrs.feOptionValueKey) {
        modelOptionsTrackBy = '$value';
      }

      let onChange = '';
      if ($attrs.feOnChange) {
        onChange = `ng-change="$ctrl.onChange({ $event: { name: '${$attrs.feName}', value: $ctrl.model } })"`;
      }

      let orderByAttr = '$ctrl.optionLabelKey';
      if ($attrs.feOptionOrderByKey) {
        orderByAttr = $attrs.feOptionOrderByKey;
      }

      const multiple = ~['1', 'true'].indexOf($attrs.feMultiple) ? 'true' : 'false';

      return `
        <div ng-class="{'s-is-disabled': $ctrl.disabled, 'form-select--embedded': $ctrl.isEmbedded}"
                class="form-select">
            <md-input-container md-no-float="$ctrl.noFloat">
                <label ng-if="!$ctrl.noFloat">{{ $ctrl.label }}</label>
                <md-select ng-model="$ctrl.model"
                        class="{{ $ctrl.classes }}"
                        ${nameAttr}
                        ng-disabled="$ctrl.disabled"
                        ng-required="$ctrl.required"
                        ${onChange}
                        multiple="${multiple}"
                        md-on-close="$ctrl.clearSearchTerm()"
                        ng-model-options="{trackBy: '${modelOptionsTrackBy}'}"
                        placeholder="{{ $ctrl.noFloat ? $ctrl.label : '' }}">
                    ${searchBox}
                    <md-option ng-if="!$ctrl.required && $ctrl.model" value=""></md-option>
                    <md-option ng-repeat="option in $ctrl.options
                                | filter:$ctrl.searchTerm
                                | orderBy:${orderByAttr}
                                ${trackByAttr}"
                            ng-value="$ctrl.optionValueKey ? option[$ctrl.optionValueKey] : option"
                            ng-disabled="!!option.disabled">
                        ${optionLabel}
                    </md-option>
                </md-select>
                <div class="errors" ng-messages="$ctrl.errors">
                    <div ng-message="required">This is required.</div>
                </div>
            </md-input-container>
        </div>
      `;
    },
  };
};
