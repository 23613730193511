export default function parse (item) {
  let parsed = {
    id: item.id,
    intervention_id: item.intervention.id,
    body_part_id: item.body_part.id,
    users: [],
    notes: item.notes,
    performed_by_third_party: item.performed_by_third_party,
  };

  if (item.crew_members && Object.keys(item.crew_members).length) {
    parsed.users.push({ id: item.crew_members[0].id });

    if (item.crew_members[1]) {
      parsed.users.push({ id: item.crew_members[1].id });
    }
  }

  if (item.attributes && item.attributes.length) {
    parsed.attributes = item.attributes.map(attribute => {
      let parsedAttribute = {
        id: attribute.id,
        intervention_attribute_id: attribute.intervention_attribute.id,
        value: attribute.value,
      };

      if (attribute.intervention_attribute_value) {
        parsedAttribute.intervention_attribute_value_id = attribute.intervention_attribute_value.id;
      }

      return parsedAttribute;
    });
  }

  return parsed;
};
