const ACTION_COL_TPL = `<div class="ui-grid-cell-contents t-no-padding" title="actions">
      <md-button class="md-button-low md-button-narrow"
          ng-repeat="action in grid.appScope.$ctrl.actions track by $index"
          ng-if="!action.isHidden(row.entity)"
          ng-click="action.callback(row.entity)">{{ action.name }}</md-button>
  </div>`;

const VALID_COLUMN_DEF_PROPS = ['cellClass', 'cellFilter', 'defaultSort', 'sort', 'type', 'width'];

const DEFAULT_GRID_OPTIONS = {
  columnDefs: [],
  data: [],
  enableColumnResizing: true,
  enableFiltering: true,
  enableSorting: true,
  paginationTemplate: '<span></span>',
  rowHeight: 35,
  useExternalPagination: false,
  useExternalSorting: false,
};

const ACTION_COL_DEFAULTS = {
  name: 'actions',
  displayName: '',
  enableFiltering: false,
  enableSorting: false,
  enableHiding: false,
  field: 'widgets',
  width: '*',
  cellTemplate: ACTION_COL_TPL,
};

export class SearchGridController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (uiGridConstants, $scope) {
    this._uiGridConstants = uiGridConstants;
    this._scope = $scope;
  }

  $onInit () {
    this.gridOptions = Object.assign({}, DEFAULT_GRID_OPTIONS, this.options || {});
    this.columns = this.columns || [];

    if (this.options && this.options.enablePagination) {
      this._setupPagination();
    }

    this.gridOptions.data = this.data;
    this.gridOptions.columnDefs = this._buildColumnDefs();

    this.gridOptions.onRegisterApi = gridApi => {
      this.gridApi = gridApi;

      if (this.pagination) {
        gridApi.core.on.sortChanged(this._scope, this._onSortingChanged.bind(this));
        gridApi.pagination.on.paginationChanged(this._scope, this._onPaginationChanged.bind(this));
      }
    };
  }

  $onChanges (changed) {
    if (!changed.data || !this.gridOptions) return;

    if (changed.data && changed.data.currentValue) {
      if (this.options && this.options.totalItems) { this.gridOptions.totalItems = this.options.totalItems; }
      if (this.options && this.options.pageNumber && this.pagination.pageNumber !== this.options.pageNumber) {
        this.pagination.pageNumber = this.options.pageNumber;
        this.gridApi.pagination.seek(this.pagination.pageNumber);
      }

      this.gridOptions.columnDefs = [];
      if (changed.data.currentValue.length) {
        this.gridOptions.columnDefs = this._buildColumnDefs();
      }

      this.gridOptions.data = changed.data.currentValue.map(item => {
        return Object.keys(item).reduce((acc, cur) => ({ ...acc, [cur]: item[cur] }), {});
      });

      this.gridApi.core.notifyDataChange(this._uiGridConstants.dataChange.COLUMN);
    }
  }

  _setupPagination () {
    this.gridOptions.paginationPageSizes = [25, 50, 75];
    this.gridOptions.paginationPageSize = 25;
    this.gridOptions.paginationTemplate = 'common/components/search-grid/search-grid-pagination.tpl.html';
    this.gridOptions.totalItems = this.options.totalItems ? this.options.totalItems : 0;

    if (this.options.useExternalPagination) {
      this.gridOptions.enableFiltering = false;
      this.gridOptions.enableSorting = false;
    }

    this.pagination = {
      pageNumber: this.options.pageNumber || 1,
      pageSize: 25,
      sort: null,
    };
  }

  _buildColumnDefs () {
    const colDefs = Object.entries(this.columns)
      .map(([key, value]) => {
        const col = {
          name: key,
          displayName: value.name,
        };

        for (const prop of VALID_COLUMN_DEF_PROPS) {
          if (value[prop]) col[prop] = value[prop];
        }

        return col;
      });

    if (this.actionColumn) {
      this.actions = this.actionColumn.actions.map(item => Object.assign({}, item));
      const actionCol = Object.assign({}, ACTION_COL_DEFAULTS, this.actionColumn.options || {});
      if (this.actionColumn.position && this.actionColumn.position.toLowerCase() === 'end') {
        colDefs.push(actionCol);
      } else {
        colDefs.unshift(actionCol);
      }
    }

    return colDefs;
  }

  _onPaginationChanged (pageNumber, pageSize) {
    if (!this.pagination) { return; }

    this.pagination.pageNumber = pageNumber;
    this.pagination.pageSize = pageSize;

    if (this.options && this.options.resolvePage) {
      this.options.resolvePage(pageNumber, pageSize);
    }
  }

  _onSortingChanged (grid, sortColumns) {
    if (!this.pagination) { return; }

    if (sortColumns.length === 0) {
      this.pagination.sort = null;
    } else {
      this.pagination.sort = sortColumns[0].sort.direction;
    }
  };

}

export default {
  controller: SearchGridController,
  templateUrl: 'common/components/search-grid/search-grid.tpl.html',
  bindings: {
    columns: '<',
    data: '<',
    options: '<',
    actionColumn: '<',
  },
};
