import ResourceDialog from 'common/resource/services/resourceDialog';
import ResourceDialogController from './consumable-order-form-dialog.controller';

export default class ConsumableOrderFormDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(ResourceDialogController);
    const serviceName = 'consumable-order-form-dialog';
    this.setTemplateUrl(`core/consumables/services/${serviceName}/${serviceName}.tpl.html`);
  }
}
