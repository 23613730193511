import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class DeviceDialogController extends ResourceDialogController {
  init () {
    super.init();
    this.DeviceResource = this.$injector.get('DeviceResource');
    this.ID = uuid.v4();

    if (!this.item) {
      this.item = { active: true };
    }
  }

  create (formData) {
    formData.id = this.ID;
    this.DeviceResource
      .create(formData)
      .then(() => { this.Dialog.hide(formData); })
      .catch(console.log.bind(console));
  }

  update (formData) {
    this.DeviceResource
      .update(formData.id, formData, { include: 'vehicleCallsign' })
      .then(data => { this.Dialog.hide(data); })
      .catch(console.log.bind(console));
  }

  delete (id) {
    this.Dialog.confirm('Are you sure?').then(() => {
      this.DeviceResource.destroy(id).then(() => {
        this.Dialog.cancel(this.item);
      });
    });
  }
}
