export class IncidentSummaryController {
  hasPrf () {
    if (!this.incident) return false;
    return this.incident.deployments.length &&
      this.incident.deployments[0].patient_report_forms.length;
  }

  getDateOfBirth (dob) {
    if (!dob) return null;
    return `${dob.getFullYear()}-${('0' + (dob.getMonth() + 1)).slice(-2)}-${('0' + dob.getDate()).slice(-2)}`;
  }

  getPatientInfo () {
    const prf = this.incident.deployments[0].patient_report_forms[0];
    const dob = this.getDateOfBirth(prf.date_of_birth);

    return `${prf.first_name || ''} ${prf.last_name || ''} / ${dob || ''} / ${prf.nhs_number || ''}`;
  }
}

export default {
  bindings: {
    incident: '<',
  },
  templateUrl: 'core/incidents/components/incident-summary/incident-summary.tpl.html',
  controller: IncidentSummaryController,
};
