import ResourceDialog from 'common/resource/services/resourceDialog';
import LaboratoryFindingsDialogController from './laboratory-findings-dialog.controller';

export default class LaboratoryFindingsDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(LaboratoryFindingsDialogController);
    this.setTemplateUrl('core/prf/services/laboratory-findings-dialog/laboratory-findings-dialog.tpl.html');
  }
}
