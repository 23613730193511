import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ModuleConfig from './module.config';
import DevicePage from './components/device-page/device-page.component';

const module = angular
  .module('gnaas.pages.admin.devices', [
    uiRouter,
  ])
  .config(ModuleConfig)
  .component('devicePage', DevicePage);

export default module.name;
