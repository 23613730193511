import moment from 'moment';
import { copy } from 'angular';
import { DATE_TIME_FORMAT } from 'common/resource/module.constants';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class TransferBatchController extends ResourceDialogController {

  init () {
    super.init();

    this.StockLocationResource = this.$injector.get('StockLocationResource');
    this.DrugStockTransactionResource = this.$injector.get('DrugStockTransactionResource');
    this.DrugBatchesResource = this.$injector.get('DrugBatchesResource');
    this.q = this.$injector.get('$q');
    this.TransactionApprovalService = this.$injector.get('TransactionApprovalService');

    this.StockLocationResource.index().then(items => { this.stockLocations = items; });

    this.item = {
      batches_transferred: [],
      timestamp: moment().startOf('minute').toDate(),
    };

    this._resetData();
    this._createWatcher();
  }

  newBatch () {
    this.item.batches_transferred.push({});
  }

  create (input) {
    this.TransactionApprovalService
      .attempt(this._createTransaction.bind(this), input)
      .then(data => {
        this.Dialog.hide(data);
      });
  }

  _resetData () {
    this.drugMap = {};
    this.drugs = [];
    this.item.batches_transferred = [];
  }

  _createWatcher () {
    this.$injector.get('$rootScope').$new().$watch(
      () => this.fromLocationId,
      (newVal, oldVal) => {
        if (oldVal === newVal || this._resetFromLocation) {
          this._resetFromLocation = false;
          return;
        }

        this.q.resolve((() => {
          if (!this.item.batches_transferred.length) return;
          return this.Dialog.confirm('Changing location will remove all existing transfer batches.');
        })()).then(() => {
          this._onFromStockLocationChanged(newVal);
        }).catch(() => {
          this._resetFromLocation = true;
          this.fromLocationId = oldVal;
        });
      }
    );
  }

  _createTransaction (input) {
    const data = this._parseData(input, this.toLocationId);
    return this.DrugStockTransactionResource.create(data);
  }

  _parseData (data, toStockLocationId) {
    const d = copy(data);

    for (const item of d.batches_transferred) {
      item.stock_location_id = toStockLocationId;
      delete item.drug_id;
      delete item.expires_on;
    }

    d.transaction_type = 'transferred';
    d.timestamp = moment(d.timestamp).format(DATE_TIME_FORMAT);

    return d;
  }

  _onFromStockLocationChanged (locationId) {
    this._resetData();
    this.toStockLocations = this.stockLocations.filter(item => item.id !== locationId);

    this.DrugBatchesResource
      .index({ location_id: locationId, expiry_mode: 'non-expired', quantity_mode: 'non-empty', include: 'drug' })
      .then(drugBatches => {
        this.drugMap = this._mapDrugs(drugBatches);
        this.drugs = Object.values(this.drugMap).map(item => { return { id: item.id, name: item.name }; });
      })
      .catch(console.log.bind(console));
  }

  _mapDrugs (batches) {
    return batches.reduce((acc, item) => {
      const drugId = item.drug.id;
      if (!acc.hasOwnProperty(drugId)) {
        acc[drugId] = { id: drugId, name: item.drug.name, expiryMap: {}, expiries: [] };
      }

      const expiryMap = acc[drugId].expiryMap;
      const expiries = acc[drugId].expiries;
      const expiry = item.expires_on.split(' ')[0];
      if (!expiryMap.hasOwnProperty(expiry)) {
        expiryMap[expiry] = [];
        expiries.push(expiry);
      }

      expiryMap[expiry].push(item);

      return acc;
    }, {});
  }
}
