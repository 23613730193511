import uuid from 'node-uuid';

class AttachmentTabController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Toast, Dialog, PRFResource) {
    this.Toast = Toast;
    this.Dialog = Dialog;
    this.PRFResource = PRFResource;
  }

  getDialogLocals () {
    return {
      Dialog: this.Dialog,
      PRFResource: this.PRFResource,
      Toast: this.Toast,
      prf: this.prf,
      onPrfUpdate: this.onPrfUpdate,
    };
  }

  showUploadDialog ($event) {
    this.Dialog.show({
      targetEvent: $event,
      template: `
        <md-dialog class="o-dialog o-dialog--small">
          <md-dialog-content>
            <div layout="column" class="u-padding-normal">
              <label class="f-label u-margin-narrow-bottom">Files</label>
              <input type="file" file="$ctrl.file" class="u-margin-narrow-bottom">
              <md-progress-linear class="md-warn" md-mode="buffer" ng-if="$ctrl.isUploading" 
                    value="{{ $ctrl.fileUploadPercentage }}">
              </md-progress-linear>
            </div>
          </md-dialog-content>
          <md-dialog-actions layout="row">
            <span flex></span>
            <md-button class="md-raised" ng-click="$ctrl.close()">Cancel</md-button>
            <md-button class="md-primary md-raised" ng-click="$ctrl.upload()">Upload</md-button>
          </md-dialog-actions>
        </md-dialog>
      `,
      locals: this.getDialogLocals(),
      controller: function () {
        'ngInject';

        const ID = uuid.v4();

        this.close = () => {
          this.Dialog.hide();
        };

        this.upload = () => {
          if (!this.file) return;

          this.isUploading = true;
          this.PRFResource.uploadAttachment(this.prf.id, this.file, ID).then((data) => {
            this.isUploading = false;
            this.prf.attachments = [...this.prf.attachments, data];
            if (typeof this.onPrfUpdate === 'function') {
              this.onPrfUpdate({ prf: this.prf });
            }
            this.Toast.showSimple('Attachment uploaded.');
            this.close();
          }, (err) => {
            console.log(err);
            this.Toast.showSimple('Error uploading attachment.');
          }, (value) => {
            this.fileUploadPercentage = (value.loaded / value.total) * 100;
          });
        };
      },
    });
  }

  showActionsDialog ($event, attachment) {
    this.Dialog.show({
      targetEvent: $event,
      template: `
        <md-dialog class="o-dialog o-dialog--small">
          <md-dialog-content>
            <div layout="column" class="u-padding-normal">
              <span>{{ $ctrl.attachment.file_name }}.{{ $ctrl.attachment.file_extension }}</span>
            </div>
          </md-dialog-content>
          <md-dialog-actions layout="row">
            <md-button class="md-raised md-warn" ng-click="$ctrl.delete()">Delete</md-button>
            <span flex></span>
            <md-button class="md-raised" ng-click="$ctrl.close()">Cancel</md-button>
            <md-button class="md-primary md-raised" ng-click="$ctrl.open()">Open</md-button>
          </md-dialog-actions>
        </md-dialog>
      `,
      locals: this.getDialogLocals(),
      controller: function ($window) {
        'ngInject';

        this.attachment = attachment;

        this.close = () => {
          this.Dialog.hide();
        };

        this.delete = () => {
          this.Dialog
            .confirm('Are you sure you want to delete this attahcment?')
            .then(() => this.PRFResource.deleteAttachment(this.prf.id, attachment.id))
            .then(
              () => {
                this.prf.attachments = this.prf.attachments.filter(i => i.id !== attachment.id);
                if (typeof this.onPrfUpdate === 'function') {
                  this.onPrfUpdate({ prf: this.prf });
                }
                this.Toast.showSimple('Attachment deleted.');
                this.close();
              },
              () => {
                this.Toast.showSimple('Error deleting attachment.');
              }
            );
        };

        this.open = () => {
          $window.open(attachment.url, '_blank');
          this.close();
        };
      },
    });
  }
}

export default {
  controller: AttachmentTabController,
  templateUrl: 'pages/requestLog/components/attachment-tab/attachment-tab.tpl.html',
  bindings: {
    isEditing: '<',
    prf: '<',
    onPrfUpdate: '&',
  },
};
