import ResourceDialog from 'common/resource/services/resourceDialog';
import InjuryDialogController from './injury-dialog.controller';

export default class InjuryDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(InjuryDialogController);
    this.setTemplateUrl('core/prf/services/injury-dialog/injury-dialog.tpl.html');
  }
}
