import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ModuleConfig from './module.config';
import ConsumableListController from './controllers/consumable-list.controller';
import ConsumableStockController from './controllers/consumable-stock.controller';

const module = angular
  .module('gnaas.pages.admin.consumables', [
    uiRouter,
    'ui.grid',
    'ui.grid.pagination',
    'ui.grid.autoResize',
    'ui.grid.resizeColumns',
  ])
  .config(ModuleConfig)
  .controller('ConsumableListController', ConsumableListController)
  .controller('ConsumableStockController', ConsumableStockController);

export default module.name;
