export class TableListController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($attrs) {
    this._attrs = $attrs;
  }

  $onInit () {
    this.fields = this.fields || {};
    this.items = this.items || [];
    this.orderBy = this.orderBy || (Object.keys(this.fields)[0] || 'name');
    this.orderByReverse = this.orderByReverse || false;
    this.noResultsMessage = this.noResultsMessage || 'No records found.';
    if (this.showButton === undefined) this.showButton = true;
    if (this.search === undefined) this.search = true;
  }

  $onChanges (changes) {
    if (!changes.items || !changes.items.currentValue) return;

    if (this._attrs.rowBackgroundWarn) {
      this.items = changes.items.currentValue.map(item => {
        return Object.assign({}, item, { __bgWarn: this.rowBackgroundWarn({ item }) });
      });
    }
  }

  listItemClickAction (evt, item) {
    if (this._attrs.onItemClick) {
      this.onItemClick({event: evt, item: item});
    }
  }

  transformValue (value) {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    return value;
  }

  getFieldValue (item, key) {
    if (!item) return;
    if (key.indexOf('.') === -1) return this.transformValue(item[key]);
    try {
      return key.split('.').reduce((acc, part) => {
        if (!acc.hasOwnProperty(part)) {
          throw new Error();
        }
        return this.transformValue(acc[part]);
      }, item);
    } catch (e) {
      return null;
    }
  }
}

export default {
  bindings: {
    fields: '<',
    items: '<',
    onItemClick: '&',
    listToSidenav: '<',
    search: '<',
    showButton: '<',
    rowBackgroundWarn: '&',
    noResultsMessage: '@',
    orderBy: '@',
    orderByReverse: '<',
  },
  controller: TableListController,
  templateUrl: 'common/lists/components/table-list/table-list.tpl.html',
};
