export default {
  bindings: {
    incident: '<',
    statusDialog: '&',
  },
  controller: function () {
    this.getLatestDeploymentStatus = (statuses) => {
      return statuses[statuses.length - 1];
    };
  },
  templateUrl: 'core/incidents/components/incident-log/incident-log.tpl.html',
};
