import angular from 'angular';
import uiRouter from 'angular-ui-router';
import VehicleResourceController from './controllers/vehicleResourceController';
import VehicleResource from './services/vehicleResource';

const moduleConfig = function ($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.vehicles', {
    url: '/vehicles',
    controller: VehicleResourceController,
    controllerAs: '$ctrl',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    data: {
      title: 'Vehicles',
    },
  });
};

const module = angular
  .module('gnaas.pages.admin.vehicles', [
    uiRouter,
  ])
  .config(moduleConfig)
  .service('VehicleResource', VehicleResource);

export default module.name;
