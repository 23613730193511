import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ModuleConfig from './module.config';

import DeploymentPage from './components/deployment-page/deployment-page';
import IncidentPage from './components/incident-page/incident-page';
import PrfPage from './components/prf-page/prf-page';
import RequestListItem from './components/requestListItem';
import RequestLog from './components/request-log-page/request-log-page';

import filterDailyRequests from './filters/filterDailyRequests';
import filterRequests from './filters/filterRequests';

import AttachmentTab from './components/attachment-tab/attachment-tab';
import DeploymentPageService from './components/deployment-page/deployment-page.service';
import HandoverNotesTab from './components/handover-notes-tab/handover-notes-tab';
import IncidentPageService from './components/incident-page/incident-page.service';
import PatientDetailsTab from './components/patient-details-tab/patient-details-tab';
import PhotosTab from './components/photos-tab/photos-tab';
import PrfPageService from './components/prf-page/prf-page.service';
import RequestLogPageService from './components/request-log-page/request-log-page.service';
import RecordDrugUseDialogService from 'core/prf/services/record-drug-use-dialog/record-drug-use-dialog.service';
import SurveyTab from './components/survey-tab/survey-tab';
import ClinicalInicidentTab from './components/clinical-incident-tab/clinical-incident-tab';

const module = angular
  .module('gnaas.pages.requestLog', [
    uiRouter,
  ])
  .config(ModuleConfig)
  .component('attachmentTab', AttachmentTab)
  .component('deploymentPage', DeploymentPage)
  .component('handoverNotesTab', HandoverNotesTab)
  .component('incidentPage', IncidentPage)
  .component('patientDetailsTab', PatientDetailsTab)
  .component('photosTab', PhotosTab)
  .component('prfPage', PrfPage)
  .component('requestListItem', RequestListItem)
  .component('requestLogPage', RequestLog)
  .component('surveyTab', SurveyTab)
  .component('clinicalIncidentTab', ClinicalInicidentTab)
  .filter('filterDailyRequests', filterDailyRequests)
  .filter('filterRequests', filterRequests)
  .service('DeploymentPageService', DeploymentPageService)
  .service('IncidentPageService', IncidentPageService)
  .service('PrfPageService', PrfPageService)
  .service('RequestLogPageService', RequestLogPageService)
  .service('RecordDrugUseDialogService', RecordDrugUseDialogService);

export default module.name;
