import uuid from 'node-uuid';
import { toDateTimeString, toDateTimeObject } from 'common/utils/services/dateTime';


/**
 * Get an ID based map of administered infusions. Details are mapped by timestamps.
 *
 * InfusionMap
 * {
 *   [infusion_id]: {
 *     [detail_timestamp]: { id: ..., rate: ..., administered_infusion_id: ... }
 *   }
 * }
 *
 * @param administeredInfusions Administered infusions
 */
export function getAdministeredInfusionMap (administeredInfusions) {
  return administeredInfusions.reduce((infusionMap, infusion) => ({
    ...infusionMap,
    [infusion.id]: {
      prescription: infusion.prescription,
      details: infusion.details.reduce((detailMap, detail) => ({
        ...detailMap,
        [toDateTimeString(detail.date, detail.time)]: {
          id: detail.id,
          rate: detail.rate,
          administered_infusion_id: infusion.id,
        },
      }), {}),
    },
  }), {});
}

/**
 * Get unique timestamps from administered infusion details.
 *
 * @param administeredInfusions Administered infusions
 */
export function getUniqueInfusionDetailTimestamps (administeredInfusions) {
  return administeredInfusions
    // Flatten all administered infusion details
    .reduce((acc, cur) => cur.details ? acc.concat(cur.details) : acc, [])
    // Get all unique timestamps as an array of strings
    .reduce((acc, cur) => {
      const dtString = toDateTimeString(cur.date, cur.time);
      return ~acc.indexOf(dtString) ? acc : [...acc, dtString];
    }, []);
}

/**
 * Denormalize administered infusions, so that they can be used within the administered-infusions component.
 * This is also done in order to encapsulate data manipulation within a single component. Can be done either within
 * the parent component (survey-tab), or administered-infusions component itself.
 * The difference between the two is at what point denormalization and normalization happen.
 *
 * Details
 * [
 *   {
 *     date: ...,
 *     time: ...,
 *     details: [{ id: ..., rate: ..., administered_infusion_id: ... }, ...]
 *   }
 * ]
 *
 * @param infusions array Normalized administered infusions
 */
export function denormalizeAdministeredInfusionDetails (infusions) {
  const infusionMap = getAdministeredInfusionMap(infusions);
  const uniqueTimestamps = getUniqueInfusionDetailTimestamps(infusions);

  return uniqueTimestamps.map(timestamp => ({
    date: toDateTimeObject(timestamp),
    time: toDateTimeObject(timestamp),
    rates: Object.keys(infusionMap).map(infusionId => {
      if (infusionMap[infusionId].details[timestamp]) return infusionMap[infusionId].details[timestamp];
      return {
        id: uuid.v4(),
        administered_infusion_id: infusionId,
        rate: null,
      };
    }),
  }));
}

/**
 * Normalize administered infusion & detail/rate data.
 *
 * @param infusions Denormalized administered infusions
 * @param details Denormalized administered infusion details
 */
export function normalizeAdministeredInfusions (infusions, details) {
  const normalized = infusions.map(i => ({ ...i, details: [] }));

  details.forEach(detail => {
    detail.rates.forEach(rate => {
      if (rate.rate == null) return;
      const infusion = normalized.find(i => i.id === rate.administered_infusion_id);
      infusion.details.push({
        date: toDateTimeObject(detail.date),
        time: toDateTimeObject(detail.time),
        id: rate.id,
        rate: rate.rate,
      });
    });
  });

  return normalized;
}
