import { mapSearchResultHeading } from 'swacctsMapper';

function getColumnWidth (len) {
  if (len < 5) return len * 20;
  if (len < 10) return len * 15;
  if (len > 19) return len * 11;
  if (len > 13) return len * 14;
  return len * 15;
}

function formatKey (str) {
  return str.replace(/\./, '_');
}

function prettify (str) {
  const parts = str.split('.');
  const table = ucFirst(parts[0]) + ': ';
  const column = parts[1].split('_').map(item => ucFirst(item)).join(' ');
  return table + column;
}

function ucFirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function isIdCol (key) {
  return ~[
    'incidents_id', 'deployments_id', 'patient_report_forms_id',
    'incidents.id', 'deployments.id', 'patient_report_forms.id',
  ].indexOf(key);
}

const ACTIONS_COL_TPL = `<div class="ui-grid-cell-contents t-no-padding" title="actions">
  <md-button target="_blank" class="md-button-low md-button-narrow" ng-if="row.entity.incidents_id"
      ui-sref="incident({id: row.entity.incidents_id})">Referral</md-button>
  <md-button target="_blank" class="md-button-low md-button-narrow" ng-if="row.entity.deployments_id"
      ui-sref="deployments({id: row.entity.deployments_id})">Deployment</md-button>
  <md-button target="_blank" class="md-button-low md-button-narrow" ng-if="row.entity.patient_report_forms_id"
      ui-sref="prf.patientDetails({id: row.entity.patient_report_forms_id})">PRF</md-button>
</div>`;

const GRID_OPTIONS = {
  enableFiltering: true,
  enableSorting: true,
  useExternalPagination: false,
  useExternalSorting: false,
  enableColumnResizing: true,
  columnDefs: [],
  data: [],
  height: 900,
  rowHeight: 35,
  paginationPageSizes: [25, 50, 75],
  paginationPageSize: 25,
  paginationTemplate: 'core/search/components/search-results/search-results-pagination.tpl.html',
};

export class SearchResultsController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (uiGridConstants, $timeout, $scope) {
    this._uiGridConstants = uiGridConstants;
    this._timeout = $timeout;
    this._scope = $scope;
  }

  $onInit () {
    this.searchTriggered = false;

    this.pagination = {
      pageNumber: 1,
      pageSize: 25,
      sort: null,
    };

    this.gridOptions = Object.assign({}, GRID_OPTIONS, {
      onRegisterApi: (gridApi) => {
        this.gridApi = gridApi;
        this.gridApi.core.on.sortChanged(this._scope, this._onSortingChanged.bind(this));
        this.gridApi.pagination.on.paginationChanged(this._scope, this._onPaginationChanged.bind(this));
      },
    });
  }

  exportCsv () {
    if (!this.data.length) return;
    const keys = Object.keys(this.data[0]);
    const data = this.data.map(item => Object.values(item).map(v => v === null ? '""' : `"${v}"`));
    const csvContent = [keys, ...data].reduce((acc, cur) => `${acc}${cur.join(',')}\r\n`, '');
    this._downloadCsv(csvContent);
  }

  _downloadCsv (csvContent) {
    const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'audit-tool-results.csv');
    link.setAttribute('target', '_blank');

    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link); // Required for FF
  }

  _onPaginationChanged (pageNumber, pageSize) {
    this.pagination.pageNumber = pageNumber;
    this.pagination.pageSize = pageSize;
  }

  _onSortingChanged (grid, sortColumns) {
    if (sortColumns.length === 0) {
      this.pagination.sort = null;
    } else {
      this.pagination.sort = sortColumns[0].sort.direction;
    }
  };

  $onChanges (changesObj) {
    if (!changesObj.data) return;

    if (changesObj.data && changesObj.data.currentValue) {
      this.searchTriggered = true;

      // Reset columndefs
      this.gridOptions.columnDefs = [];

      if (changesObj.data.currentValue.length) {
        // Get columnDefs from first row of data
        const firstRow = changesObj.data.currentValue[0];
        const linkButtonCount = this._getLinksCount(firstRow);
        this.gridOptions.columnDefs = Object.keys(firstRow).map(key => this._getColumnDef(key));
        this._setupActionCol(linkButtonCount);
      }

      // Transform data
      this.gridOptions.data = changesObj.data.currentValue.map(item => {
        return Object.keys(item).reduce((acc, cur) => {
          acc[formatKey(cur)] = item[cur];
          return acc;
        }, {});
      });

      // Notify UI Grid about column change & update column widths
      this.gridApi.core.notifyDataChange(this._uiGridConstants.dataChange.COLUMN);
      this._timeout(() => {
        this.updateColumnWidths(this.gridOptions.data);
      });
    }
  }

  updateColumnWidths (data) {
    const colWidths = this.gridApi.grid.columns.reduce((acc, cur) => {
      acc[cur.name] = (cur.name && cur.name.length > 8) ? cur.name.length : 8;
      return acc;
    }, {});

    if (!data.length) return;

    data.forEach(item => {
      Object.keys(item).forEach(key => {
        if (!item.hasOwnProperty(key) || item[key] === null ||
            !item[key].length || item[key].length <= colWidths[key]) return;
        colWidths[key] = item[key].length;
      });
    });

    Object.keys(colWidths).forEach(key => {
      if (key === '$$hashKey' || key === 'actions') return;
      const col = this.gridApi.grid.getColumn(key);
      if (isIdCol(key)) {
        col.colDef.visible = false;
      } else {
        col.width = getColumnWidth(colWidths[key]);
        col.colDef.enableHiding = false;
      }
    });
  }

  _getColumnDef (key) {
    const displayName = prettify(mapSearchResultHeading(key));
    return {
      name: formatKey(key),
      displayName,
      width: getColumnWidth(displayName),
    };
  }

  _getLinksCount (row) {
    let c = 0;
    for (const key in row) {
      if (isIdCol(key)) c++;
    }
    return c;
  }

  _setupActionCol (width) {
    width = width || 0;
    this.gridOptions.columnDefs.unshift({
      name: 'actions',
      displayName: '',
      enableFiltering: false,
      enableSorting: false,
      enableHiding: false,
      field: 'widgets',
      width: width === 3 ? (width * 90) : (width === 2 ? (width * 107) : 117),
      visible: !!width,
      cellTemplate: ACTIONS_COL_TPL,
    });
  }
}

export default {
  bindings: {
    data: '<',
  },
  controller: SearchResultsController,
  templateUrl: 'core/search/components/search-results/search-results.tpl.html',
};
