import { format as formatBodyPart } from 'core/categories/resources/body-part.resource';

export default function format (item) {
  return {
    id: item.id,
    notes: item.notes,
    status: item.status,
    body_part: formatBodyPart(item.bodyPart.data),
    illness: item.illness.data,
    illness_type: item.illnessType ? item.illnessType.data : null,
  };
}
