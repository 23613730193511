import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class ClinicalIncidentDialogController extends ResourceDialogController {
  init () {
    super.init();
    // This resource will temporarily use the PRF resource and update clinical incident as part of the PRF.
    // This is to save significand amount of time on development in the short term (especially iPad & offline sync).
    // Eventually this is to be refactored to use the proper ClinicalInicdentResource.
    this._resource = this.$injector.get('PRFResource');
    this._ID = uuid.v4();
  }

  $onInit () {
    if (!this.item.clinical_incident) {
      this.item.clinical_incident = { clinical_incident_recorded: false };
    }
  }

  create (data) {
    data.clinical_incident.id = this._ID;
    if (data.clinical_incident.clinical_incident_recorded && data.clinical_incident.datix_completed == null) {
      data.clinical_incident.datix_completed = false;
    }

    this._resource.update(data.id, data, { include: 'clinicalIncident' }).then(item => {
      this.Dialog.hide(item);
    });
  }

  update (data) {
    this._resource.update(data.id, data, { include: 'clinicalIncident' }).then(item => {
      this.Dialog.hide(item);
    }).catch();
  }
}
