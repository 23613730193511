import angular from 'angular';
import DeviceDialog from './services/device-dialog/device-dialog.service';
import DeviceResource from './resources/device.resource';

const module = angular
  .module('gnaas.core.devices', [])
  .service('DeviceDialog', DeviceDialog)
  .service('DeviceResource', DeviceResource);

export default module.name;
