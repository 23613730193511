export default {
  bindings: {
    crewMember: '<',
    onEditButtonClick: '&',
  },
  controller: function (Session) {
    'ngInject';

    this.hasPermissionsToEdit = function (crewMember) {
      const user = Session.user();
      return user.group.slug === 'system-administrators';
    };
  },
  templateUrl: 'pages/crewMembers/templates/crewMemberProfile.tpl.html',
};
