/* global atob */

const TOKEN_KEY = 'X_AUTH_TOKEN';

export default class JWT {
  /**
   * Set JWT.
   *
   * @param token
   */
  set (token) {
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  /**
   * Get JWT.
   *
   * @returns {string | null}
   */
  get () {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  /**
   * JWT exists.
   *
   * @returns {boolean}
   */
  exists () {
    const token = window.localStorage.getItem(TOKEN_KEY);
    return typeof token !== 'undefined' && token != null;
  }

  /**
   * Destroy JWT.
   */
  destroy () {
    window.localStorage.removeItem(TOKEN_KEY);
  }

  parse () {
    if (this.get() == null) return null;

    const base64Url = this.get().split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
}
