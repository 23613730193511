import RestResource from 'common/resource/services/restResource';

export function format (item) {
  const i = {
    id: item.id,
    clinical_incident_recorded: !!item.clinical_incident_recorded,
    datix_completed: !!item.datix_completed,
    datix_incident_number: item.datix_incident_number,
  };

  if (item.communication) i.communication = item.communication.data;
  if (item.equipmentProblem) i.equipment_problem = item.equipmentProblem.data;
  if (item.organisationalDelay) i.organisational_delay = item.organisationalDelay.data;
  if (item.physiologicalDeterioration) i.physiological_deterioration = item.physiologicalDeterioration.data;

  return i;
}

export function parse (item) {
  const i = {
    id: item.id,
    clinical_incident_recorded: item.clinical_incident_recorded ? 1 : 0,
    datix_completed: item.datix_completed ? 1 : 0,
    datix_incident_number: item.datix_incident_number || null,
  };

  if (item.communication) i.communication_id = item.communication.id;
  if (item.equipment_problem) i.equipment_problem_id = item.equipment_problem.id;
  if (item.organisational_delay) i.organisational_delay_id = item.organisational_delay.id;
  if (item.physiological_deterioration) i.physiological_deterioration_id = item.physiological_deterioration.id;

  return i;
}

export default class ClinicalIncidentResource extends RestResource {
  init () {
    this.resourcePath = 'clinical-incidents';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
