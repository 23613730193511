import { copy } from 'angular';
import CreateCrewMemberController from 'pages/crewMembers/controllers/addCrewMemberDialog';

export class CrewMembersController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (CrewMemberResource, ProfessionResource, GroupResource, Toast, Dialog, Session) {
    this._CrewMemberResource = CrewMemberResource;
    this._ProfessionResource = ProfessionResource;
    this._GroupResource = GroupResource;
    this._Toast = Toast;
    this._Dialog = Dialog;
    this._Session = Session;
  }

  $onInit () {
    this.categories = {};
    this.activeCrewMember = null;
    this.showLoader = true;

    let requestsFinished = 0;
    const requestsRequired = 3;

    this._CrewMemberResource.index({ include: 'group,profile.profession,profile.image' }).then(crewMembers => {
      this.crewMembers = crewMembers;
      this._sortCrewMembers();
      this.selectCrewMember({ crewMember: crewMembers[0] });
    }).finally(() => (this._handleLoadingIndicator(++requestsFinished, requestsRequired)));

    this._ProfessionResource.index()
      .then(professions => { this.categories.professions = professions; })
      .finally(() => (this._handleLoadingIndicator(++requestsFinished, requestsRequired)));

    this._GroupResource.index()
      .then(groups => { this.categories.groups = groups; })
      .finally(() => (this._handleLoadingIndicator(++requestsFinished, requestsRequired)));
  }

  hasPermissionsToAdd () {
    const user = this._Session.user();
    if (!user.group) {
      return false;
    }
    return user.group.slug === 'system-administrators';
  }

  selectCrewMember ({ crewMember }) {
    if (!crewMember) {
      return;
    }
    const filtered = this.crewMembers.filter(({ id }) => id === crewMember.id);
    this.activeCrewMember = filtered[0];
  }

  showCreateDialog ({ evt, crewMember }) {
    const isEditing = !!crewMember;

    const dialogParams = {
      controller: CreateCrewMemberController,
      locals: {
        editing: isEditing,
        crewMember: copy(crewMember),
        categories: this.categories,
      },
      targetEvent: evt,
      templateUrl: 'pages/crewMembers/templates/addCrewMemberDialog.tpl.html',
    };

    this._Dialog
      .show(dialogParams)
      .then(this._handleCreateOrUpdate.bind(this, isEditing))
      .catch(this._handleDelete.bind(this));
  }

  _sortCrewMembers () {
    this.crewMembers.sort((a, b) => {
      if (!a['first_name']) { return 1; }
      if (!b['first_name']) { return -1; }
      a = a['first_name'].toLowerCase();
      b = b['first_name'].toLowerCase();
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
  }

  _handleCreateOrUpdate (isEditing, crewMember) {
    if (isEditing) {
      this.crewMembers = this.crewMembers.map(
        item => item.id === crewMember.id ? Object.assign({}, item, crewMember) : item
      );
      this.selectCrewMember({ crewMember: crewMember });
      if (this._Session.isAuthenticatedUser(crewMember.id)) {
        this._Session.setUser(crewMember);
      }
      this._Toast.showSimple('Crew member updated.');
    } else {
      this.crewMembers = [crewMember, ...this.crewMembers];
      this._Toast.showSimple('New crew member created.');
    }
    this._sortCrewMembers();
  }

  _handleDelete (crewMemberId) {
    if (!crewMemberId) {
      return;
    }
    this.crewMembers = this.crewMembers.filter(({ id }) => id !== crewMemberId);
    this.selectCrewMember({ crewMember: this.crewMembers[0] });
    this._Toast.showSimple('Crew member deleted.');
  }

  _handleLoadingIndicator (requestsFinished, requestsRequired) {
    if (requestsFinished === requestsRequired) {
      this.showLoader = false;
    }
  }
};

export default {
  controller: CrewMembersController,
  templateUrl: 'pages/crewMembers/templates/crewMembers.tpl.html',
};
