import ResourceDialog from 'common/resource/services/resourceDialog';
import DeploymentListDialogController from './deployment-list-dialog.controller';

export default class DeploymentListDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(DeploymentListDialogController);
    this.setTemplateUrl('core/deployments/services/deployment-list-dialog/deployment-list-dialog.tpl.html');
  }
}
