export default class MapService {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($q, BaseResource, LocationResource, HospitalResource, VehicleCallsignResource) {
    this._q = $q;
    this._BaseResource = BaseResource;
    this._LocationResource = LocationResource;
    this._HospitalResource = HospitalResource;
    this._VehicleCallsignResource = VehicleCallsignResource;
  }

  getVehicleMarkerData () {
    return this._VehicleCallsignResource.index({ tracking: 'true' });
  }

  getMarkerData () {
    let results = {};

    return this._q.all([
      this._BaseResource.index(),
      this._HospitalResource.index(),
      this._LocationResource.index({
        include: 'locationType',
      }),
      this.getVehicleMarkerData(),
    ]).then((data) => {
      results.bases = data[0] || [];
      results.hospitals = data[1] || [];
      results.locations = data[2] || [];
      results.vehicles = data[3] || [];
      return results;
    });
  }
}
