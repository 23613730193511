import angular from 'angular';
import 'angular-ui-grid';
import SearchCategories from './components/search-categories/search-categories.component';
import SearchCriteria from './components/search-criteria/search-criteria.component';
import SearchResults from './components/search-results/search-results.component';
import SearchQueryItem from './components/search-query-item/search-query-item.component';
import SearchDatepicker from './directives/search-datepicker/search-datepicker.directive';
import SearchInput from './directives/search-input/search-input.directive';
import SearchSelect from './directives/search-select/search-select.directive';
import ColumnsToDisplayDialog from './services/columns-to-display-dialog/columns-to-display-dialog.service';

const module = angular
  .module('gnaas.core.search', [
    'ui.grid',
    'ui.grid.pagination',
    'ui.grid.autoResize',
    'ui.grid.resizeColumns',
  ])
  .component('searchCategories', SearchCategories)
  .component('searchCriteria', SearchCriteria)
  .component('searchResults', SearchResults)
  .component('searchQueryItem', SearchQueryItem)
  .directive('searchDatepicker', SearchDatepicker)
  .directive('searchInput', SearchInput)
  .directive('searchSelect', SearchSelect)
  .service('ColumnsToDisplayDialog', ColumnsToDisplayDialog);

export default module.name;
