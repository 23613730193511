/* global FormData */

import RestResource from 'common/resource/services/restResource';
import format from 'core/prf/services/prf-formatter.service';
import parse from 'core/prf/services/prf-parser.service';

function formatApproval (data) {
  if (!data) return data;
  const approval = { id: data.id };
  if (data.firstUser) {
    approval.first_user = data.firstUser.data;
  }
  if (data.secondUser) {
    approval.second_user = data.secondUser.data;
  }
  return approval;
}

export default class PRFResource extends RestResource {
  init () {
    this.resourcePath = 'patient-report-forms';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }

  patch (id, data) {
    const url = `${this.apiUrl}${this.resourcePath}/${id}`;
    return this._http.patch(url, this._parseData(data)).then(response => this._formatData(response.data.data));
  }

  approve (id, data) {
    const url = `${this.apiUrl}${this.resourcePath}/${id}/approve`;
    return this._http.post(url, data).then(response => formatApproval(response.data.data));
  }

  secondApproval (id, data) {
    const url = `${this.apiUrl}${this.resourcePath}/${id}/second-approval`;
    return this._http.post(url, data).then(response => formatApproval(response.data.data));
  }

  reject (id) {
    const url = `${this.apiUrl}${this.resourcePath}/${id}/reject`;
    return this._http.post(url).then(response => formatApproval(response.data.data));
  }

  uploadImage (id, image, imageId) {
    const url = `${this.apiUrl}${this.resourcePath}/${id}/image`;
    const deferred = this._q.defer();
    const requestData = { imageId, image };
    this._http({
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': undefined,
      },
      uploadEventHandlers: {
        progress: function (e) {
          deferred.notify(e);
        },
      },
      data: requestData,
      transformRequest: function (data, headersGetter) {
        const formData = new FormData();
        formData.append('image_id', data.imageId);
        formData.append('image', data.image);
        return formData;
      },
    }).then(response => {
      deferred.resolve(response.data.data);
    }).catch(err => {
      deferred.reject(err);
    });

    return deferred.promise;
  }

  deleteImage (id, imageId) {
    const url = `${this.apiUrl}${this.resourcePath}/${id}/image/${imageId}`;
    return this._http.delete(url).then(response => response.data);
  }

  uploadAttachment (id, attachment, attachmentId) {
    const url = `${this.apiUrl}${this.resourcePath}/${id}/attachment`;
    const deferred = this._q.defer();
    const requestData = { attachmentId, attachment };
    this._http({
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': undefined,
      },
      uploadEventHandlers: {
        progress: function (e) {
          deferred.notify(e);
        },
      },
      data: requestData,
      transformRequest: function (data, headersGetter) {
        const formData = new FormData();
        formData.append('attachment_id', data.attachmentId);
        formData.append('attachment', data.attachment);
        return formData;
      },
    }).then(response => {
      deferred.resolve(response.data.data);
    }).catch(err => {
      deferred.reject(err);
    });

    return deferred.promise;
  }

  deleteAttachment (id, attachmentId) {
    const url = `${this.apiUrl}${this.resourcePath}/${id}/attachment/${attachmentId}`;
    return this._http.delete(url).then(response => response.data);
  }
}
