export default function nl2br ($sce) {
  'ngInject';

  return function nl2br (input) {
    if (typeof input === 'undefined' || input == null) {
      return input;
    }

    input = input.replace(/\n/g, '<br>');
    return $sce.trustAsHtml(input);
  };
};
