import { AUTH_EVENTS } from 'common/auth/module.constants';

const SIDENAV = 'sidenav-main';


export function TopbarController ($rootScope, $state, $mdSidenav, JWT, Session, AuthResource, IdleCheck) {
  'ngInject';

  const updateUser = () => {
    let user = Session.user();
    this.username = 'User';
    if (user) {
      this.username = (user.first_name && user.last_name)
        ? user.first_name + ' ' + user.last_name
        : user.email;
      this.profile_image = user.profile_image;
    }
  };

  this.$onInit = function () {
    updateUser();
    $rootScope.$on(AUTH_EVENTS.sessionUserUpdated, updateUser.bind(this));
  };

  this.toggleSidenav = function () {
    $mdSidenav(SIDENAV).toggle();
  };

  this.openUserDropdownMenu = function ($mdOpenMenu, ev) {
    $mdOpenMenu(ev);
  };

  this.logout = function () {
    AuthResource.logout().then(function () {
      JWT.destroy();
      Session.destroy();
      IdleCheck.stop();
      $state.go('login');
    }).catch(console.log.bind(console));
  };
}

export default {
  templateUrl: 'common/navigation/templates/topbar.tpl.html',
  controller: TopbarController,
};
