import uuid from 'node-uuid';

export default function CreateCrewMemberController (Dialog, CrewMemberResource, Toast, $timeout, Session) {
  'ngInject';

  const ID = uuid.v4();

  const create = (data, image) => {
    this.isUploading = true;
    CrewMemberResource.create(data).then(() => {
      if (!image) {
        Dialog.hide(data);
        return;
      }
      return this._uploadProfilePicture(image, data);
    }).catch(() => {
      Toast.showSimple('Error while creating a crew member.');
    });
  };

  const update = (data, image) => {
    CrewMemberResource
      .update(data.id, data, { include: 'group,profile.profession,profile.image' })
      .then(crewMember => {
        if (!image) {
          Dialog.hide(crewMember);
          return;
        }
        return this._uploadProfilePicture(image, crewMember);
      })
      .catch(() => {
        Toast.showSimple('Error while creating a crew member.');
      });
  };

  if (!this.crewMember) {
    this.crewMember = {
      profile_active: true,
      user_active: true,
    };
  }

  this._uploadProfilePicture = function (image, crewMember) {
    this.isUploading = true;
    return CrewMemberResource.uploadProfileImage(image, crewMember.id, {
      id: uuid.v4(),
      imageable_id: crewMember.id,
    }).then((newCrewMember) => {
      this.isUploading = false;
      Dialog.hide(newCrewMember);
    }, () => {
      $timeout(() => {
        Toast.showSimple('Error while uploading profile photo.');
      }, 750);
      Dialog.hide(crewMember);
    }, (value) => {
      this.profileImagePercentUploaded = (value.loaded / value.total) * 100;
    });
  };

  this.isCurrentUser = function (id) {
    return Session.isAuthenticatedUser(id);
  };

  this.submit = function (crewMember) {
    if (this.form.$invalid) {
      return;
    }

    if (this.editing) {
      update(crewMember, this.uploadImage);
    } else {
      crewMember.id = ID;
      create(crewMember, this.uploadImage);
    }
  };

  this.cancel = function () {
    Dialog.cancel();
  };

  this.delete = function (id) {
    Dialog.confirm('Are you sure you want to delete this crew member?').then(() => {
      CrewMemberResource.destroy(id).then(function () {
        Dialog.cancel(id);
      });
    });
  };
};
