import uuid from 'node-uuid';

export default class CreateCalendarEventController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Utils, Dialog, CalendarEventResource) {
    this._Utils = Utils;
    this._Dialog = Dialog;
    this._CalendarEventResource = CalendarEventResource;

    this.ID = uuid.v4();

    this.selectedCrewMembers = [];

    if (!this.item) {
      this.item = {
        start_date: this.date.clone().add(1, 'h'),
        start_time: this.date.clone().add(1, 'h').minutes(0).seconds(0).milliseconds(0),
        end_date: this.date.clone().add(2, 'h'),
        end_time: this.date.clone().add(2, 'h').minutes(0).seconds(0).milliseconds(0),
      };
    } else {
      this.selectedCrewMembers = this.item.crew_members || [];
    }
  }

  submit (item) {
    if (this.form.$invalid) {
      return false;
    }

    if (this.editing) {
      this._update(item, this.selectedCrewMembers);
    } else {
      this._create(item, this.selectedCrewMembers);
    }
  }

  cancel () {
    this._Dialog.cancel();
  }

  delete (id) {
    this._Dialog.confirm().then(() => {
      this._CalendarEventResource.destroy(id).then(() => {
        this._Dialog.cancel(this.item);
      });
    });
  }

  toggleCrewMember (item) {
    let object = this._Utils.findObjectInArrayById(this.selectedCrewMembers, item.id);

    if (object) {
      this.selectedCrewMembers.splice(this.selectedCrewMembers.indexOf(object), 1);
    } else {
      this.selectedCrewMembers.push(item);
    }
  }

  crewMemberExists (item) {
    return this._Utils.findObjectInArrayById(this.selectedCrewMembers, item.id);
  }

  _create (data, crewMembers) {
    data.id = this.ID;
    data.crew_members = crewMembers;

    this._CalendarEventResource.create(data).then(() => {
      this._Dialog.hide(data);
    }).catch(console.log.bind(console));
  }

  _update (data, crewMembers) {
    data.users = crewMembers;

    this._CalendarEventResource.update(data.id, data, {
      include: 'type,vehicle,users,base',
    }).then((calendarEvent) => {
      this._Dialog.hide(calendarEvent);
    }).catch(console.log.bind(console));
  }
}
