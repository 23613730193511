/* global Blob URL */
import moment from 'moment';

class PrfPageController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $state, $stateParams, Toast, PrfPageService, Dialog, Session, PRFResource,
    PrintablePRFResource, RecordDrugUseDialog, ReviewDrugUseDialog, PRFApprovalDialog
  ) {
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.Toast = Toast;
    this.PrfPageService = PrfPageService;
    this.Dialog = Dialog;
    this.Session = Session;
    this.PRFResource = PRFResource;
    this.PrintablePRFResource = PrintablePRFResource;
    this.RecordDrugUseDialog = RecordDrugUseDialog;
    this.ReviewDrugUseDialog = ReviewDrugUseDialog;
    this.PRFApprovalDialog = PRFApprovalDialog;
  }

  $onInit () {
    this.relationData = {};
    this.isEditing = false;

    this.tabs = this._getTabs();
    this.selectedTab = this.tabs.find(tab => tab.state === this.$state.current.name).id;

    this.PrfPageService.getPrf(this.$stateParams.id).then(prf => {
      this.prf = prf;
      this.formattedDob = this.calculateFormattedDob(prf);
      this.prf.deployment.crew_members = this.prf.deployment.crew_members.map(i => {
        if (i.type !== 'observer' || i.type === 'observer' && i.id != null) return i;
        return { ...i, first_name: 'OTHER', profession: { title: 'Observer' } };
      });

      this.medics = [...this.prf.deployment.crew_members];
      this.breadcrumbRoutes = this.PrfPageService.getBreadcrumbRoutes(prf.incident.id, this.prf.deployment.id);

      this._setPermissions(prf);
      this._setApprovalPendingFromUser(prf);
    });

    if (this._userHasPermissionToEdit()) {
      this.PrfPageService.getRelationData(this.$stateParams.id).then(relationData => {
        this.relationData = { ...this.relationData, ...relationData };
      });
    }
  }

  //
  // CALLBACKS
  //

  onPrfUpdate (prf) {
    this.prf = { ...this.prf, ...prf };
    this.formattedDob = this.calculateFormattedDob(prf);
  }

  //
  // TABS
  //

  selectTab (tab) {
    if (this.tabs.map(item => item.id).indexOf(tab.id) === -1) {
      throw new Error('Invalid tab.');
    }
    this.$state.go(tab.state);
  }

  submitPrfApproval ({ $event }) {
    if (!this.userCanApprovePRF) return;

    this.Dialog.confirm('Are you sure you want to approve the PRF?').then(() => {
      const hasAdministeredDrugs = this.prf.surveys.some(survey => {
        return survey.administered_drugs && survey.administered_drugs.length;
      });

      const users = this.medics.map(i => ({ email: i.email, name: `${i.first_name} ${i.last_name}` }));
      if (this.prf.approval_status === 1) {
        let data = null;
        if (this.pendingUser) {
          data = { second_user: { email: this.pendingUser.email } };
        } else if (this.userCanApprovePRF) {
          data = { second_user: { email: this._getUser().email } };
        }

        // If stock transactions are recorded - review them, otherwise show approval dialog
        if (this.prf.stock_transactions && this.prf.stock_transactions.length) {
          this.ReviewDrugUseDialog.show({
            $event,
            prf: this.prf,
            onApproveClick: ({ $event }) => {
              return this.PRFApprovalDialog
                .showSingle({ $event, users, data })
                .then(users => this.secondPrfApproval(users));
            },
            onRejectClick: () => {
              return this.rejectPrf();
            },
          });
        } else {
          this.PRFApprovalDialog
            .showSingle({ $event, users, data })
            .then(users => this.secondPrfApproval(users));
        }
      } else if (hasAdministeredDrugs) {
        this.RecordDrugUseDialog.show({
          $event,
          locals: {
            prf: this.prf,
            onFormSubmit: this.approvePrf.bind(this),
          },
        });
      } else {
        const data = { first_user: { email: this._getUser().email } };
        this.PRFApprovalDialog
          .show({ $event, users, data })
          .then(users => this.approvePrf({ ...users }));
      }
    });
  }

  approvePrf (data) {
    if (data.second_user && (!data.second_user.email || !data.second_user.password)) {
      delete data.second_user;
    }

    return this.PRFResource
      .approve(this.prf.id, data)
      .then(this._handleApprovalSuccess.bind(this))
      .catch(this._handleApprovalError.bind(this));
  }

  secondPrfApproval (data) {
    return this.PRFResource
      .secondApproval(this.prf.id, data)
      .then(this._handleApprovalSuccess.bind(this))
      .catch(this._handleApprovalError.bind(this));
  }

  rejectPrf () {
    if (!this.userCanRejectPRF) return;

    return this.Dialog.confirm('Are you sure you want to reject the PRF?').then(() => {
      return this.PRFResource
        .reject(this.prf.id)
        .then(approval => {
          this.prf.approval = approval;
          this.prf.approval_status = 0;
          this._setPermissions(this.prf);
          this._setApprovalPendingFromUser(this.prf);
          this.Toast.showSimple('Patient report form rejected.');
        })
        .catch(err => {
          if (err.status === 403) {
            this.Dialog.alert(err.data.message);
          } else {
            this.Dialog.alert('Error while trying to reject the patient report form.');
          }
          throw err;
        });
    });
  }

  _handleApprovalSuccess (approval) {
    this.PrfPageService.getPrf(this.$stateParams.id).then(prf => {
      this.prf = prf;
      if (this.medics.length > 1) {
        this.prf.approval_status = approval.first_user && approval.second_user ? 2 : 1;
      } else {
        this.prf.approval_status = 2;
      }
      this._setPermissions(this.prf);
      this._setApprovalPendingFromUser(this.prf);
      this.Toast.showSimple('Patient report form approved.');
    });
  }

  _handleApprovalError (err) {
    if (!err || !err.status) return;
    if (err.status === 422 && err.data.errors && err.data.errors.quantity) {
      this.Dialog.alert(err.data.errors.quantity[0]);
    } else if (err.status === 428 || err.status === 403) {
      this.Dialog.alert(err.data.message);
    } else {
      this.Dialog.alert('Error while trying to approve the patient report form.');
    }
    throw err;
  }

  _setApprovalPendingFromUser (prf) {
    if (prf.approval_status !== 1 || this.medics.length > 2 || !prf.approval || !prf.approval.first_user) {
      this.pendingUser = null;
      return;
    }
    const pendingUsers = this.medics.filter(item => item.id !== prf.approval.first_user.id);
    this.pendingUser = pendingUsers.length ? pendingUsers[0] : null;
  }

  _userHasApprovalPending (approval) {
    if (!approval || !approval.first_user) return false;
    return this._userHasPermissionToEdit() && this._userIsOnCrew() && approval.first_user.id !== this._getUser().id;
  }

  //
  // PRINTABLE PRF
  //

  getPrintablePDF () {
    this.PrintablePRFResource.show(this.prf.id).then(pdf => {
      const file = new Blob([pdf], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }).catch(() => {
      this.Toast.showSimple('PDF generation error.');
    });
  }

  //
  // PERMISSIONS
  //

  _setPermissions (prf) {
    const userCanEdit = this._userHasPermissionToEdit();
    // TODO Replace deployment status check with user group check (desk staff)
    const userIsOnCrew = this._userIsOnCrew() || prf.deployment.status === 'referral';
    this.isEditing = prf.approval_status === 0 && userCanEdit && userIsOnCrew;
    if (prf.deployment.status === 'transfer') {
      const userHasApprovalPending = (this._userHasApprovalPending(prf.approval) && prf.approval_status === 1);
      this.userCanRejectPRF = (this.Session.isSysadminUser() && prf.approval_status === 2) || userHasApprovalPending;
      this.userCanApprovePRF = (this.isEditing && prf.approval_status === 0) || userHasApprovalPending;
    } else {
      this.userCanRejectPRF = false;
      this.userCanApprovePRF = false;
    }
    this.userCanGeneratePdf = this._userHasPermissionToGeneratePdf();
  }

  _getUser () {
    return this.Session.user();
  }

  _userHasPermissionToEdit () {
    const user = this._getUser();
    if (!user || !user.group) return false;
    return ~['system-administrators', 'medical-staff', 'medical-staff-prescribers', 'call-handlers'].indexOf(user.group.slug);
  }

  _userHasPermissionToGeneratePdf () {
    const user = this._getUser();
    if (!user || !user.group) return false;
    return ~['system-administrators', 'medical-staff', 'medical-staff-prescribers'].indexOf(user.group.slug);
  }

  _userIsOnCrew () {
    const user = this._getUser();
    const matchingMedics = this.medics.filter(item => item.id === user.id);
    return matchingMedics.length > 0;
  }

  _getTabs () {
    const user = this._getUser();
    if (!user.group) return [];

    const tabs = [
      { id: 'patient-details', name: 'Patient Details', state: 'prf.patientDetails' },
      { id: 'surveys', name: 'Surveys', state: 'prf.surveys' },
      { id: 'handover', name: 'Handover', state: 'prf.handover' },
      { id: 'photos', name: 'Photos', state: 'prf.photos' },
      { id: 'attachments', name: 'Attachments', state: 'prf.attachments' },
      { id: 'clinical-incident', name: 'Clinical Incident', state: 'prf.clinicalIncident' },
    ];

    if (user.group.slug === 'call-handlers') return [tabs[0]];
    return tabs;
  }

  calculateFormattedDob (prf) {
    if (!prf || !prf.date_of_birth) {
      return null;
    }

    return moment(prf.date_of_birth).format('DD/MM/YYYY');
  }
}

export default {
  controller: PrfPageController,
  templateUrl: 'pages/requestLog/components/prf-page/prf-page.tpl.html',
};
