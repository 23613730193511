import { RESOURCE_ITEM_FIELD_TYPES } from 'common/resource/module.constants';

const FIELDS = {
  name: 'Name',
  expected_amount: 'Expected',
  actual_amount: 'Actual',
  resolvedYN: 'Resolved',
};

const SIDEBAR_FIELDS = [
  {
    key: 'name',
    label: 'Name',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  }, {
    key: 'expected_amount',
    label: 'Expected',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  }, {
    key: 'actual_amount',
    label: 'Actual',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  }, {
    key: 'resolvedYN',
    label: 'Resolved',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  }, {
    key: 'initial_comments',
    label: 'Initial Comments',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  }, {
    key: 'resolve_comments',
    label: 'Resolve Comments',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
];

export default class StockCheckController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($scope, $stateParams, $mdSidenav, DrugStockCheckResource, DrugStockCheckDialog) {
    this.$scope = $scope;
    this._stateParams = $stateParams;
    this._mdSidenav = $mdSidenav;
    this._DrugStockCheckResource = DrugStockCheckResource;
    this._DrugStockCheckDialog = DrugStockCheckDialog;
  }

  $onInit () {
    this.fields = Object.assign({}, FIELDS);
    this.sidebarFields = [...SIDEBAR_FIELDS];

    this._DrugStockCheckResource
      .show(this._stateParams.id, { include: 'stockLocation,user,stockCheckItems' })
      .then(item => {
        this.item = Object.assign(item, {
          drug_stock_check_items: item.drug_stock_check_items.map(item => this._formatItem(item)),
        });
      });
  }

  showSidenav (item) {
    this.selectedItem = item;
    this._mdSidenav('resource-item').open();
  }

  showDialog (event, item) {
    this._DrugStockCheckDialog.show({ $event: event, item }).then(data => {
      const formatted = this._formatItem(data);
      this.selectedItem = formatted;
      this.item.drug_stock_check_items = this.item.drug_stock_check_items.map(item => {
        return item.id === data.id ? formatted : item;
      });
    });
  }

  rowBgWarn (item) {
    return !item.resolved;
  }

  _formatItem (item) {
    item.resolvedYN = item.resolved ? 'Yes' : 'No';
    item.name = item.drug.name;
    return item;
  }
}
