import ResourceDialog from 'common/resource/services/resourceDialog';
import ShiftDialogController from './shift-dialog.controller';

export default class ShiftDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(ShiftDialogController);
    this.setTemplateUrl('core/shifts/services/shift-dialog/shift-dialog.tpl.html');
  }
}
