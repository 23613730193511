function matchesIncidentNumber (incidentNumber, value) {
  return incidentNumber.toLowerCase().indexOf(value.toLowerCase()) > -1;
}

function matchesDate (date, value) {
  return date.format('DD-MM-YYYY').indexOf(value) > -1;
}

export default function filterDailyRequests () {
  return function (items, value) {
    if (typeof value === 'undefined') {
      return items;
    }

    return items.filter(function (item) {
      return matchesDate(item.date, value) || matchesIncidentNumber(item.incident_number, value);
    });
  };
};
