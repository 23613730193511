import moment from 'moment';
import { parse as parseClinicalIncident } from 'core/prf/resources/clinical-incident.resource';
import { parse as parseHandover } from 'core/prf/resources/handover.resource';

export default function parse (item) {
  let parsed = {
    id: item.id,
    first_name: item.first_name,
    last_name: item.last_name,
    gender: item.gender,
    nhs_number: item.nhs_number,
    address: item.address,
    incident_description: item.incident_description,
    handover_notes: item.handover_notes,
    allergies_status: item.allergies_status,
    allergies: item.allergies,
    regular_medication: item.regular_medication,
    infection_control_issues_status: item.infection_control_issues_status,
    infection_control_issues: item.infection_control_issues,
    nok_name: item.nok_name,
    nok_contact_no: item.nok_contact_no,
    known_to_social_care: item.known_to_social_care,
    social_worker_name: item.social_worker_name,
    social_worker_contact_no: item.social_worker_contact_no,
    safeguarding_concerns: item.safeguarding_concerns,
    safeguarding_concerns_notes: item.safeguarding_concerns_notes,
    infection_status_comments: item.infection_status_comments ? item.infection_status_comments : null,
  };

  parsed.date_of_birth = item.date_of_birth ? moment(item.date_of_birth).format('YYYY-MM-DD') : null;
  parsed.weight = item.weight ? Math.round(item.weight / 0.001) : null;
  parsed.patient_type_id = item.patient_type ? item.patient_type.id : null;
  parsed.deployment_id = item.deployment ? item.deployment.id : null;

  if (item.clinical_incident) {
    parsed.clinical_incident = parseClinicalIncident(item.clinical_incident);
  }

  if (item.handover) {
    parsed.handover = parseHandover(item.handover);
  }

  if (item.infectionStatuses) {
    parsed.infection_statuses = item.infectionStatuses.map(({ id }) => id);
  }

  return parsed;
}
