import ResourceDialog from 'common/resource/services/resourceDialog';
import CreateDrugStockCheckDialogController from './create-drug-stock-check-dialog.controller';

export default class CreateDrugStockCheckDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(CreateDrugStockCheckDialogController);
    this.setTemplateUrl('core/drugs/services/create-drug-stock-check-dialog/create-drug-stock-check-dialog.tpl.html');
  }
}
