import moment from 'moment';
import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';
import { DATE_TIME_FORMAT, DATE_FORMAT } from 'common/resource/module.constants';

export default class RecordDrugUseDialogController extends ResourceDialogController {

  init () {
    super.init();
    if (!this.prf || !this.onFormSubmit) { throw new Error('PRF or onFormSubmit was not set.'); }

    this.$q = this.$injector.get('$q');
    this.$timeout = this.$injector.get('$timeout');
    this.StockLocationResource = this.$injector.get('StockLocationResource');
    this.DrugBatchesResource = this.$injector.get('DrugBatchesResource');
    this.PRFApprovalDialog = this.$injector.get('PRFApprovalDialog');
    this.Dialog = this.$injector.get('Dialog');
    this.Session = this.$injector.get('Session');
    this.APP_ORG_SHORT = this.$injector.get('APP_ORG_SHORT');

    this.dateString = this.prf.deployment.date.format('dddd DD MMMM, YYYY [at] HH:MM');
    this.incidentNumber = this.prf.incident.incident_number;

    this.batches = {};
    this.administeredDrugData = this._getDrugData(this.prf.surveys);
    this.transactions = this._getTransactions(this.prf.stock_transactions);

    this.StockLocationResource.index().then(locations => (this.stockLocations = locations));
    this._loadBatches();
  }

  newTransaction (drugId, locationId) {
    if (!this.transactions[drugId]) this.transactions[drugId] = [];

    const transaction = { id: uuid.v4(), location: { id: locationId } };
    if (locationId) {
      transaction.location = { id: locationId };
      const drugBatches = this.batches[drugId][locationId];
      if (drugBatches && drugBatches.expiries && drugBatches.expiries.length === 1) {
        transaction.expires_on = drugBatches.expiries[0];
        if (drugBatches.expiryMap[transaction.expires_on].length === 1) {
          transaction.batch_id = drugBatches.expiryMap[transaction.expires_on][0].id;
        }
      }
    }

    this.transactions[drugId] = [...this.transactions[drugId], transaction];
  }

  removeTransaction (drugId, id) {
    this.transactions[drugId] = this.transactions[drugId].filter(item => item.id !== id);
  }

  create (transactions) {
    const users = this.prf.deployment.crew_members
      .map(i => ({ email: i.email, name: `${i.first_name} ${i.last_name}` }));
    const data = { first_user: { email: this.Session.user().email } };
    return this._approveDrugAmounts(transactions)
      .then(() => this.PRFApprovalDialog.show({ users, data }))
      .then(users => this.onFormSubmit(this._parseData(transactions, users, this.prf.id)))
      .then(() => { this.Dialog.hide(); });
  }

  _getDrugData (surveys) {
    if (!surveys || !Array.isArray(surveys)) return {};

    return surveys
      // Flatten administered drugs from surveys
      .reduce((acc, survey) => acc.concat(survey.administered_drugs), [])
      // Flatten drug data from administered drugs
      .reduce((acc, administeredDrug) => {
        if (administeredDrug.prescription.drug.exclude_from_stock) return acc;
        const drugId = administeredDrug.prescription.drug.id;

        if (acc.hasOwnProperty(drugId)) {
          acc[drugId].totalDose += administeredDrug.dose;
          return acc;
        }

        acc[drugId] = {
          id: drugId,
          name: administeredDrug.prescription.drug.name,
          totalDose: administeredDrug.dose,
          unit: administeredDrug.prescription.drug.measurement_type,
        };
        return acc;
      }, {});
  }

  _getTransactions (stockTransactions) {
    if (!stockTransactions || !Array.isArray(stockTransactions)) return {};

    return stockTransactions.reduce((acc, transaction) => ({
      ...acc,
      [transaction.drug.id]: [
        ...(acc.hasOwnProperty(transaction.drug.id) ? acc[transaction.drug.id] : []),
        {
          expires_on: transaction.batch.expires_on.split(' ')[0],
          batch_id: transaction.batch.id,
          ...transaction,
        },
      ],
    }), {});
  }

  _loadBatches () {
    Object.keys(this.administeredDrugData).forEach(drugId => {
      this._getBatchesForDrug(drugId)
        .then(batches => {
          this.batches[drugId] = { ...this._getDrugBatchData(batches) };
        })
        .catch(() => {
          const drug = this.administeredDrugData[drugId].name;
          this.Dialog.alert(`Error retrieving batches for ${drug}.`);
        });
    });
  }

  _getBatchesForDrug (drugId) {
    return this.DrugBatchesResource.index({
      drug_id: drugId,
      include: 'drug,location',
      quantity_mode: 'non-empty',
      expiry_mode: 'non-expired',
      received_on_end: this.prf.deployment.date.format(DATE_FORMAT),
    });
  }

  _getDrugBatchData (batches) {
    return batches.reduce((acc, item) => {
      const locationId = item.location.id;
      if (!acc.hasOwnProperty(locationId)) acc[locationId] = { expiryMap: {}, expiries: [] };

      const expiry = item.expires_on.split(' ')[0];
      if (!acc[locationId].expiryMap.hasOwnProperty(expiry)) {
        acc[locationId].expiryMap[expiry] = [];
        acc[locationId].expiries.push(expiry);
      }

      acc[locationId].expiryMap[expiry].push(item);
      return acc;
    }, {});
  }

  _approveDrugAmounts (transactions) {
    // Add up total trug amounts recorded & check if amount given and amount wasted adds up for each transaction
    const totalAmounts = {};
    for (const drugId of Object.keys(transactions)) {
      totalAmounts[drugId] = 0;
      for (const unit of transactions[drugId]) {
        const totalAmount = parseFloat(unit.amount_given) + parseFloat(unit.amount_wasted);
        // This is needed for older data, in case batches are empty
        if (!this.batches.hasOwnProperty(drugId)) continue;

        const batch =
          this.batches[drugId][unit.location.id].expiryMap[unit.expires_on].find(i => i.id === unit.batch_id);

        if (totalAmount < batch.dose_per_unit || totalAmount % batch.dose_per_unit !== 0) {
          this.Dialog.alert(
            `Invalid sum of amount given and amount wasted for ${batch.drug.name} ` +
              `batch ${batch.batch_number} (${batch.location.name}).`,
            'Invalid amount'
          );
          return this.$q.reject();
        }
        totalAmounts[drugId] += unit.amount_given;
      }
    }
    this.$q.reject();

    const checkedAmounts = {};
    for (const drugId of Object.keys(this.administeredDrugData)) {
      const drug = this.administeredDrugData[drugId];
      if (totalAmounts[drugId] > this.administeredDrugData[drugId].totalDose) {
        this.Dialog.alert(`${drug.name} amount recorded is higher than administered.`, 'Invalid amount');
        return this.$q.reject();
      }
      checkedAmounts[drugId] = totalAmounts[drugId] === drug.totalDose;
    }

    const invalidAmounts = Object.keys(checkedAmounts)
      .filter(key => !checkedAmounts[key])
      .map(drugId => this.administeredDrugData[drugId].name);

    const len = invalidAmounts.length;
    if (len) {
      const drugStr = len > 1
        ? invalidAmounts.slice(0, len - 1).join(', ') + ` & ${invalidAmounts[len - 1]}`
        : invalidAmounts[0];

      const msg = `Please confirm that some or all of ${drugStr} administered ${(len > 1 ? 'were' : 'was')}` +
        ` not from ${this.APP_ORG_SHORT} stock.`;
      return this.Dialog.confirm(msg);
    }

    return this.$q.resolve();
  }

  _parseData (data, users, prfId) {
    const parsed = {
      timestamp: moment().format(DATE_TIME_FORMAT),
      patient_report_form_id: prfId,
      drugs_administered: Object.values(data).reduce((acc, cur) => { return acc.concat(cur); }, []),
      first_user: users.first_user,
    };

    if (users.second_user && users.second_user.email && users.second_user.email.length &&
        users.second_user.password && users.second_user.password.length) {
      parsed.second_user = users.second_user;
    }

    return parsed;
  }
}
