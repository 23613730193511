import formatAdministeredDrugs from './administered-drug-formatter.service';
import formatAssessedInjury from './assessed-injury-formatter.service';
import formatAssessedIllness from './assessed-illness-formatter.service';
import formatAdministeredIntervention from './administered-intervention-formatter.service';
import formatLaboratoryFindings from './laboratory-findings.formatter.service';
import formatObservations from './observations-formatter.service';
import formatAdministeredInfusion from './administered-infusion-formatter.service';
import formatPrescribedDrug from './prescribed-drug-formatter.service';
import formatPrescribedInfusion from './prescribed-infusion-formatter.service';
import { toDateTimeObject } from 'common/utils/services/dateTime';

export default function format (item) {
  const dateTime = toDateTimeObject(item.time);

  let formatted = {
    id: item.id,
    time: dateTime,
    date: dateTime,
    name: item.name,
    comment: item.comment,
  };

  formatted.observations = item.observations
    ? item.observations.data.map(formatObservations)
    : [];

  formatted.laboratory_findings = item.laboratoryFindings
    ? item.laboratoryFindings.data.map(formatLaboratoryFindings)
    : [];

  formatted.administered_drugs = item.administeredDrugs
      ? item.administeredDrugs.data.map(formatAdministeredDrugs)
      : [];

  formatted.assessed_injuries = item.assessedInjuries
      ? item.assessedInjuries.data.map(formatAssessedInjury)
      : [];

  formatted.assessed_illnesses = item.assessedIllnesses
      ? item.assessedIllnesses.data.map(formatAssessedIllness)
      : [];

  formatted.administered_interventions = item.administeredInterventions
      ? item.administeredInterventions.data.map(formatAdministeredIntervention)
      : [];

  formatted.administered_infusions = item.administeredInfusions
    ? item.administeredInfusions.data.map(formatAdministeredInfusion)
    : [];

  formatted.prescribed_drugs = item.prescribedDrugs
    ? item.prescribedDrugs.data.map(formatPrescribedDrug)
    : [];

  formatted.prescribed_infusions = item.prescribedInfusions
    ? item.prescribedInfusions.data.map(formatPrescribedInfusion)
    : [];

  return formatted;
}
