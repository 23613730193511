export default function file () {
  return {
    scope: {
      file: '=',
    },
    link: function (scope, el, attrs) {
      el.bind('change', function (event) {
        var file = event.target.files[0];
        if (file) {
          scope.file = file;
        } else {
          scope.file = undefined;
        }
        scope.$apply();
      });
    },
  };
}
