import RestResource from 'common/resource/services/restResource';
import { format as formatConsumable } from './consumable.resource';

export default class DrugStockResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'consumble-stock';
  }

  format (item) {
    if (item.consumable) { item.consumable = formatConsumable(item.drug.data); }
    if (item.location) { item.location = item.location.data; }
    return item;
  }

  generateOrderForms (data) {
    const url = `${this.apiUrl}consumable-order-forms`;

    return this._http({
      url,
      method: 'POST',
      data,
      responseType: 'arraybuffer',
    }).then(response => response.data);
  }
}
