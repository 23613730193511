import uuid from 'node-uuid';

class PhotosTabController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Toast, Dialog, PRFResource) {
    this.Toast = Toast;
    this.Dialog = Dialog;
    this.PRFResource = PRFResource;
  }

  getDialogLocals () {
    return {
      Dialog: this.Dialog,
      PRFResource: this.PRFResource,
      Toast: this.Toast,
      prf: this.prf,
      onPrfUpdate: this.onPrfUpdate,
    };
  }

  showUploadDialog ($event) {
    this.Dialog.show({
      targetEvent: $event,
      template: `
        <md-dialog class="o-dialog o-dialog--small">
          <md-dialog-content>
            <div layout="column" class="u-padding-normal">
              <label class="f-label u-margin-narrow-bottom">Files</label>
              <input type="file" file="$ctrl.file" class="u-margin-narrow-bottom" accept="image/*">
              <md-progress-linear class="md-warn" md-mode="buffer" ng-if="$ctrl.isUploading"
                    value="{{ $ctrl.fileUploadPercentage }}">
              </md-progress-linear>
            </div>
          </md-dialog-content>
          <md-dialog-actions layout="row">
            <span flex></span>
            <md-button class="md-raised" ng-click="$ctrl.close()">Cancel</md-button>
            <md-button class="md-primary md-raised" ng-click="$ctrl.upload()">Upload</md-button>
          </md-dialog-actions>
        </md-dialog>
      `,
      locals: this.getDialogLocals(),
      controller: function () {
        'ngInject';

        const ID = uuid.v4();

        this.close = () => {
          this.Dialog.hide();
        };

        this.upload = () => {
          if (!this.file) return;

          this.isUploading = true;
          this.PRFResource.uploadImage(this.prf.id, this.file, ID).then((data) => {
            this.isUploading = false;
            this.prf.images = [...this.prf.images, data];
            if (typeof this.onPrfUpdate === 'function') {
              this.onPrfUpdate({ prf: this.prf });
            }
            this.Toast.showSimple('Image uploaded.');
            this.close();
          }, (err) => {
            console.log(err);
            this.Toast.showSimple('Error uploading image.');
          }, (value) => {
            this.fileUploadPercentage = (value.loaded / value.total) * 100;
          });
        };
      },
    });
  }

  showImage ($event, img) {
    this.Dialog.show({
      targetEvent: $event,
      escapeToClose: true,
      fullscreen: true,
      template: `
        <md-dialog aria-label="Image dialog" ng-style="$ctrl.styles">
          <md-dialog-content ng-style="$ctrl.styles">
              <img src="${img.url}" alt="${img.id}" ng-style="$ctrl.imageStyles">
          </md-dialog-content>
          <md-dialog-actions>
            <md-button ng-click="$ctrl.delete()" class="md-raised md-warn">Delete</md-button>
            <span flex></span>
            <md-button ng-click="$ctrl.close()" class="md-raised">Cancel</md-button>
          </md-dialog-actions>
        </md-dialog>`,
      locals: this.getDialogLocals(),
      controller: function ($element) {
        'ngInject';

        this.styles = { width: '100%', height: '100%' };
        this.imageStyles = { ...this.styles, display: 'block', 'object-fit': 'contain' };

        this.close = () => {
          this.Dialog.hide();
        };

        this.delete = () => {
          this.Dialog
            .confirm('Are you sure you want to delete this image?')
            .then(() => this.PRFResource.deleteImage(this.prf.id, img.id))
            .then(
              () => {
                this.prf.images = this.prf.images.filter(i => i.id !== img.id);
                if (typeof this.onPrfUpdate === 'function') {
                  this.onPrfUpdate({ prf: this.prf });
                }
                this.Toast.showSimple('Image deleted.');
                this.close();
              },
              () => {
                this.Toast.showSimple('Error deleting image.');
              }
            );
        };
      },
    });
  }
}

export default {
  controller: PhotosTabController,
  templateUrl: 'pages/requestLog/components/photos-tab/photos-tab.tpl.html',
  bindings: {
    isEditing: '<',
    prf: '<',
    onPrfUpdate: '&',
  },
};
