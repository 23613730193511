import { copy } from 'angular';
import moment from 'moment';
import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

const generateIncident = function (date) {
  return {
    date: date,
    status: 'pending',
    times: {
      call: { date: date, time: date },
    },
  };
};

const formatIncident = function (item) {
  return {
    ...item,
    date: moment(item.date).toDate(),
    times: Object.keys(item.times).reduce((acc, cur) => {
      const datetime = moment(item.times[cur].time).toDate();
      return { ...acc, [cur]: { ...item.times[cur], time: datetime, date: datetime } };
    }, {}),
  };
};

export default class IncidentDialogController extends ResourceDialogController {
  init () {
    super.init();
    this._timeout = this.$injector.get('$timeout');
    this._scope = this.$injector.get('$rootScope').$new();
    this._resource = this.$injector.get('IncidentResource');
    this._DeploymentResource = this.$injector.get('DeploymentResource');
    this.APP_ORG_SHORT = this.$injector.get('APP_ORG_SHORT');
    this._INCIDENT_ID = uuid.v4();

    this.timeLabelMap = {
      accepted: 'Task Accepted',
      declined: 'Task Declined',
      call: 'Time to ' + this.APP_ORG_SHORT,
    };

    this.today = moment().startOf('minute').toDate();

    this.item = this.item
      ? formatIncident(this.item)
      : generateIncident(this.today);

    this._scope.$watchGroup(
      [
        () => this.item.referring_hospital ? this.item.referring_hospital : null,
        () => this.item.times && this.item.times.call ? this.item.times.call.time : null,
        () => this.item.date ? this.item.date : null,
      ],
      this._updateIncidentValues.bind(this)
    );

    this.has_ambulance_reference_number = false;
    this._scope.$watch(() => this.item.ambulance_service, () => {
      if (!this.item.ambulance_service) { return; }
      this.has_ambulance_reference_number = this.item.ambulance_service.has_reference_number;

      if (!this.has_ambulance_reference_number) {
        delete this.item.ambulance_reference_number;
      }
    });
  }

  submit (formData) {
    if (!this.form) {
      throw new Error('Form not found.');
    }

    if (this.form.$invalid) {
      this.Toast.showSimple(this.validationErrorMessage);
      return;
    }

    if (this.editing) {
      this.update(formData);
    } else {
      this.create(formData);
    }
  };

  create (formData) {
    const data = this._formatIncidentForRequest(formData, this.timeLabelMap);
    data.id = this._INCIDENT_ID;
    this._resource.create(data).then(() => {
      return this._DeploymentResource.create({
        id: uuid.v4(),
        incident: { id: this._INCIDENT_ID },
        status: 'referral',
      });
    }).then(() => {
      this.Dialog.hide(data);
    }).catch(console.log.bind(console));
  };

  update (formData) {
    const data = this._formatIncidentForRequest(formData, this.timeLabelMap);
    this._resource.update(data.id, data, {
      include: [
        'serviceArea', 'refusalReason', 'referringHospital', 'base', 'referrerGrade', 'referrerWard',
        'referrerSpecialty', 'ambulanceService', 'deploymentReason',
        'deployments.vehicleCallsign', 'deployments.patientReportForms',
        'deployments.deploymentStatuses.deploymentStatus', 'deployments.deploymentStatuses.destination',
      ],
    }).then(incident => {
      this.Dialog.hide(incident);
    }).catch(console.log.bind(console));
  }

  delete (id) {
    this.Dialog.promptDelete().then(() => {
      this._resource.destroy(id)
        .then(() => {
          this.Dialog.cancel(id);
        })
        .catch(err => {
          let msg = 'Error deleting referral.';
          if (err.status && err.status === 409) {
            msg = err.data.message;
          }
          this.Toast.showSimple(msg);
        });
    });
  }

  _updateIncidentValues () {
    let timeToOrg = 'HHmm';
    let hospitalString = 'HOS';
    if (this.item.times && this.item.times.call) {
      timeToOrg = moment(this.item.times.call.time).format('HHmm');
    }
    if (this.item.referring_hospital) {
      hospitalString = this.item.referring_hospital.abbreviation.toUpperCase();
      this.item.location = { lat: this.item.referring_hospital.lat, lng: this.item.referring_hospital.lng };
    }
    this.item = {
      ...this.item,
      incident_number: moment(this.item.date).format('YYYYMMDD') + '-' + timeToOrg + '-' + hospitalString,
    };
  }

  _formatIncidentForRequest (incident, timeLabelMap) {
    const item = copy(incident);

    item.date = moment(item.date);

    item.times = Object.keys(item.times).reduce((acc, cur) => {
      if (item.times[cur].time) {
        const d = moment(item.times[cur].date);
        acc[cur] = { time: moment(item.times[cur].time).year(d.year()).month(d.month()).date(d.date()) };
      } else {
        acc[cur] = { time: null };
      }
      acc[cur].label = timeLabelMap[cur];
      return acc;
    }, {});

    return item;
  };
}
