import { format as formatBodyPart } from 'core/categories/resources/body-part.resource';

export default function format (item) {
  return {
    id: item.id,
    notes: item.notes,
    status: item.status,
    body_part: formatBodyPart(item.bodyPart.data),
    injury: item.injury.data,
    injury_type: item.injuryType ? item.injuryType.data : null,
  };
}
