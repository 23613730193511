import RestResource from 'common/resource/services/restResource';

export default class KpiResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'kpi';
  }

  get (from, to) {
    const params = { date_from: from, date_to: to };
    return this._http.post(`${this.apiUrl}${this.resourcePath}`, params).then(res => res.data.data);
  }
}
