class DevicePageController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (DeviceResource, DeviceDialog, VehicleCallsignResource) {
    this.DeviceResource = DeviceResource;
    this.DeviceDialog = DeviceDialog;
    this.VehicleCallsignResource = VehicleCallsignResource;
  }

  $onInit () {
    this.listFields = {
      name: 'Name',
      activeString: 'Active',
      vehicleCallsignString: 'Vehicle',
      device_id: 'Device ID',
    };
    this.relationData = { vehicleCallsigns: [] };
    this.DeviceResource.index({ include: 'vehicleCallsign' }).then(items => {
      this.items = items.map(this._formatItem);
    });
    this.VehicleCallsignResource.index().then(items => { this.relationData.vehicleCallsigns = items; });
  }

  showCreateDialog ($event, item) {
    const isEditing = typeof item !== 'undefined' && item != null;
    this.DeviceDialog
      .show({ $event, item, relationData: this.relationData })
      .then(data => {
        const item = this._formatItem(data);
        this.items = isEditing ? this.items.map(i => i.id === item.id ? item : i) : [...this.items, item];
      })
      .catch(item => {
        if (item) {
          this.items = this.items.filter(i => i.id !== item.id);
        }
      });
  }

  _formatItem (item) {
    item.activeString = item.active ? 'Yes' : 'No';
    item.vehicleCallsignString = item.vehicle_callsign ? item.vehicle_callsign.name : '–';
    return item;
  }
}

export default {
  controller: DevicePageController,
  templateUrl: 'pages/admin/devices/components/device-page/device-page.tpl.html',
};
