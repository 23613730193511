import ResourceDialog from 'common/resource/services/resourceDialog';
import ResourceDialogController from './create-consumable-stock-dialog.controller';

export default class CreateConsumableStockDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(ResourceDialogController);
    const serviceName = 'create-consumable-stock-dialog';
    this.setTemplateUrl(`core/consumables/services/${serviceName}/${serviceName}.tpl.html`);
  }
}
