import { toDateTimeString } from 'common/utils/services/dateTime';

export default function parse (item) {
  return {
    id: item.id,
    prescription_id: item.prescription.id,
    details: item.details.map(detail => {
      return {
        id: detail.id,
        time: toDateTimeString(detail.date, detail.time),
        rate: detail.rate,
      };
    }),
  };
}
