import ResourceDialog from 'common/resource/services/resourceDialog';
import DeploymentStatusUpdateDialogController from './deployment-status-update-dialog.controller';

export default class DeploymentStatusUpdateDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(DeploymentStatusUpdateDialogController);
    this.setTemplateUrl(
      'core/deployments/services/deployment-status-update-dialog/deployment-status-update-dialog.tpl.html'
    );
  }
}
