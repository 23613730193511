export default {
  bindings: {
    inverted: '@',
    title: '@',
    text: '@',
    image: '@',
    icon: '@',
    onClick: '&',
  },
  controller: function () {},
  template: function ($element, $attrs) {
    'ngInject';

    const clickEvent = $attrs.onClick ? 'ng-click="$ctrl.onClick()"' : '';

    return `<md-list-item class="md-2-line md-list-item-no-padding c-avatar-item" ${clickEvent}>
        <md-icon ng-if="$ctrl.icon" md-svg-src="{{ $ctrl.icon }}" aria-label="avatar"
            class="md-icon-48 md-avatar-icon c-avatar-item__avatar--svg">
        </md-icon>

        <img ng-if="$ctrl.image || !$ctrl.image && !$ctrl.icon" class="c-avatar-item__avatar" alt=""
            ng-src="{{ $ctrl.image ? $ctrl.image : 'assets/images/temporary/crewMemberPlaceholder.png' }}" />

        <div class="md-list-item-text">
            <h3 ng-if="!$ctrl.inverted">{{ $ctrl.title }}</h3>
            <p class="f-label">{{ $ctrl.text }}</p>
            <h3 ng-if="$ctrl.inverted">{{ $ctrl.title }}</h3>
        </div>
    </md-list-item>`;
  },
};
