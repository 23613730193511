import RestResource from 'common/resource/services/restResource';

export function format (item) {
  let formatted = {
    id: item.id,
    name: item.name,
    has_reference_number: !!item.has_reference_number,
  };

  return formatted;
}

export function parse (item) {
  let parsed = {
    id: item.id ? item.id : null,
    name: item.name,
    has_reference_number: item.has_reference_number ? 1 : 0,
  };

  return parsed;
}

export default class AmbulanceServiceResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'categories/ambulance-services';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
