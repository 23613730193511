import RestResource from 'common/resource/services/restResource';
import moment from 'moment';
import { format as formatVehicle } from 'pages/admin/vehicles/services/vehicleResource';
import { format as formatCallsignStatusUpdate } from
    'pages/admin/vehicleCallsigns/services/vehicleCallsignStatusUpdateResource';
import { format as formatDeploymentStatusUpdate } from
    'core/deployments/resources/deployment-status-update.resource';

export function format (item) {
  let formatted = {
    id: item.id,
    name: item.name,
    online: item.online,
    statusString: item.online ? 'Online' : 'Offline',
  };

  if (item.base) {
    formatted.base = item.base.data;
  }

  if (item.vehicle) {
    formatted.vehicle = formatVehicle(item.vehicle.data);
  }

  if (item.offlineReason) {
    formatted.offline_reason = item.offlineReason.data;
  }

  if (item.deploymentStatusUpdates) {
    formatted.deployment_status_updates = item.deploymentStatusUpdates.data.map(formatDeploymentStatusUpdate);

    if (item.deploymentStatusUpdates.data.length) {
      formatted.latest_status_update = formatted.deployment_status_updates[0];
    }
  }

  if (item.lastDeploymentStatusWithDestination) {
    formatted.lastDeployment = item.lastDeploymentStatusWithDestination.data;

    if (formatted.lastDeployment.destination) {
      formatted.lastDeployment.destination = formatted.lastDeployment.destination.data;
    }

    if (formatted.lastDeployment.deploymentStatus) {
      formatted.lastDeployment.deploymentStatus = formatted.lastDeployment.deploymentStatus.data;
    }
  }

  return formatted;
}

export function parse (item) {
  let parsed = {
    id: item.id,
    name: item.name,
    online: item.online ? item.online : 0,
  };

  if (item.vehicle != null) {
    parsed.vehicle_id = item.vehicle ? item.vehicle.id : null;
  }

  if (item.base) {
    parsed.base_id = item.base.id;
  }

  if (!item.online && item.offline_reason) {
    parsed.offline_reason_id = item.offline_reason.id;
  } else {
    parsed.offline_reason_id = null;
  }

  return parsed;
}

export default class CallsignResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'vehicle-callsigns';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }

  createStatus (vehicleCallsignId, formData) {
    const url = `${this.apiUrl}${this.resourcePath}/${vehicleCallsignId}/status-updates`;

    let parsed = {
      online: formData.online ? formData.online : 0,
      time: moment(formData.time).format('YYYY-MM-DD') + ' ' + moment(formData.time).format('HH:mm:ss'),
    };

    if (!formData.online && formData.offline_reason) {
      parsed.offline_reason_id = formData.offline_reason.id;
    } else {
      parsed.offline_reason_id = null;
    }

    return this._http.post(url, parsed).then(response => {
      return response.data.data ? formatCallsignStatusUpdate(response.data.data) : null;
    });
  }

  updateStatus (vehicleCallsignId, statusUpdateId, formData) {
    const url = `${this.apiUrl}${this.resourcePath}/${vehicleCallsignId}/status-updates/${statusUpdateId}`;

    let parsed = {
      online: formData.online ? formData.online : 0,
      time: moment(formData.time).format('YYYY-MM-DD') + ' ' + moment(formData.time).format('HH:mm:ss'),
    };

    if (!formData.online && formData.offline_reason) {
      parsed.offline_reason_id = formData.offline_reason.id;
    } else {
      parsed.offline_reason_id = null;
    }

    return this._http.put(url, parsed).then(response => {
      return response.data.data ? formatCallsignStatusUpdate(response.data.data) : null;
    });
  }

  deleteStatus (vehicleCallsignId, id) {
    const url = `${this.apiUrl}${this.resourcePath}/${vehicleCallsignId}/status-updates/${id}`;

    return this._http.delete(url).then(response => {
      return response.data;
    });
  }
}
