import RestResource from 'common/resource/services/restResource';

export function parse (item) {
  let parsed = {
    id: item.id || null,
    name: item.name,
    abbreviation: item.abbreviation,
    lat: item.lat,
    lng: item.lng,
  };

  if (item.specialties) {
    parsed.specialties = item.specialties.map(item => item.id);
  }

  return parsed;
}

export function format (item) {
  return {
    id: item.id,
    name: item.name,
    abbreviation: item.abbreviation,
    lat: item.lat,
    lng: item.lng,
    specialties: item.specialties ? item.specialties.data : [],
  };
}

export default class HospitalResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'hospitals';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
