import angular from 'angular';
import DestroyBatchDialog from './services/destroy-batch-dialog/destroy-batch-dialog.service';
import DrugOrderFormDialog from './services/drug-order-form-dialog/drug-order-form-dialog.service';
import CreateDrugStockCheckDialog
  from './services/create-drug-stock-check-dialog/create-drug-stock-check-dialog.service';
import DrugStockCheckDialog from './services/drug-stock-check-dialog/drug-stock-check-dialog.service';
import ManualAdjustmentDialog from './services/manual-adjustment-dialog/manual-adjustment-dialog.service';
import RecordDeliveryDialog from './services/record-delivery-dialog/record-delivery-dialog.service';
import TransactionApprovalDialog from './services/transaction-approval-dialog/transaction-approval-dialog.service';
import TransactionApprovalService from './services/transaction-approval-dialog/transaction-approval.service';
import TransferBatchDialog from './services/transfer-batch-dialog/transfer-batch-dialog.service';

const module = angular
  .module('gnaas.core.drugs', [])
  .service('CreateDrugStockCheckDialog', CreateDrugStockCheckDialog)
  .service('DestroyBatchDialog', DestroyBatchDialog)
  .service('DrugOrderFormDialog', DrugOrderFormDialog)
  .service('DrugStockCheckDialog', DrugStockCheckDialog)
  .service('ManualAdjustmentDialog', ManualAdjustmentDialog)
  .service('RecordDeliveryDialog', RecordDeliveryDialog)
  .service('TransactionApprovalDialog', TransactionApprovalDialog)
  .service('TransactionApprovalService', TransactionApprovalService)
  .service('TransferBatchDialog', TransferBatchDialog);

export default module.name;
