import angular from 'angular';
import DeploymentCrew from './components/deployment-crew/deployment-crew.component';
import DeploymentDialog from './services/deployment-dialog/deployment-dialog.service';
import DeploymentListDialog from './services/deployment-list-dialog/deployment-list-dialog.service';
import DeploymentListItem from './components/deployment-list-item/deployment-list-item';
import DeploymentResource from './resources/deployment.resource';
import DeploymentStatusUpdateDialog from
    './services/deployment-status-update-dialog/deployment-status-update-dialog.service';
import DeploymentStatusUpdateResource from './resources/deployment-status-update.resource';

const module = angular
  .module('gnaas.core.deployments', [])
  .service('DeploymentDialog', DeploymentDialog)
  .service('DeploymentListDialog', DeploymentListDialog)
  .service('DeploymentResource', DeploymentResource)
  .service('DeploymentStatusUpdateDialog', DeploymentStatusUpdateDialog)
  .service('DeploymentStatusUpdateResource', DeploymentStatusUpdateResource)
  .component('deploymentCrew', DeploymentCrew)
  .component('deploymentListItem', DeploymentListItem);

export default module.name;
