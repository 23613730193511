import ResourceDialog from 'common/resource/services/resourceDialog';
import PatientDetailsDialogController from './patient-details-dialog.controller';

export default class PatientDetailDialog extends ResourceDialog {
  /**
   * @ngInject
   */
  init () {
    super.init();
    this.setController(PatientDetailsDialogController);
    this.setTemplateUrl('core/prf/services/patient-details-dialog/patient-details-dialog.tpl.html');
  }
}
