import RestResource from 'common/resource/services/restResource';
import { format as formatDrug } from 'pages/admin/drugs/resources/drug.resource';
import { format as formatIntervention } from 'core/categories/resources/intervention.resource';

export function format (item) {
  const i = {
    id: item.id,
    reportable_id: item.reportable_id,
    reportable_type: item.reportable_type,
  };

  if (item.reportable) {
    switch (item.reportable_type) {
      case 'drug':
        i.reportable = formatDrug(item.reportable.data);
        break;
      case 'intervention':
        i.reportable = formatIntervention(item.reportable.data);
        break;
      default:
        i.reportable = null;
        break;
    }
  }

  return i;
}

export function parse (item) {
  return {
    id: item.id,
    reportable_id: item.reportable_id,
    reportable_type: item.reportable_type,
  };
}

export default class ReportablesResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'reportables';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
