import Map from 'common/map/services/map';
import L from 'leaflet';
import MapMarkerIcon from 'common/map/services/mapMarkerIcon';

function isCorrectLength (location) {
  return location ? location.length > 3 : false;
}

function isValidObject (location) {
  return (typeof location === 'object') && location.lat && location.lng;
}

export class MiniMapController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($timeout, $scope, Location, BING_MAPS_KEY) {
    this._timeout = $timeout;
    this._scope = $scope;
    this._Location = Location;
    this._BING_MAPS_KEY = BING_MAPS_KEY;
  }

  $onInit () {
    this._scope.$watch(() => {
      return this.location;
    }, (newVal, oldVal) => {
      if (!this.map) return;

      if (isValidObject(newVal)) {
        this._addMapMarker(newVal);
      } else if (typeof newVal === 'string') {
        if (isCorrectLength(newVal)) {
          this._getCoordinatesFromLocation(newVal).then(this._addMapMarker.bind(this));
        } else {
          this.map.removeMarker(this.currentMapMarker);
        }
      }
    }, true);
  }

  $postLink () {
    this._timeout(() => {
      this.map = new Map(this.mapId, { bingMapsKey: this._BING_MAPS_KEY });
      if (isValidObject(this.location)) {
        this._addMapMarker(this.location);
      }
    }, 250);
  }

  $onDestroy () {
    if (this.map) {
      this.map.destroy();
      this.map = null;
    }
  }

  _addMapMarker (coords) {
    this.map.removeMarker(this.currentMapMarker);
    this.currentMapMarker = this.map.addMarker(
      [coords.lat, coords.lng],
      { icon: MapMarkerIcon.predefined('incident') }
    );
    this.map.panTo(new L.LatLng(coords.lat, coords.lng));
  }

  _getCoordinatesFromLocation (location) {
    return this._Location
      .resolveCoordinates(location)
      .catch(() => {
        this.map.removeMarker(this.currentMapMarker);
        this.currentMapMarker = null;
      });
  };
}

export default {
  bindings: {
    location: '<',
    mapId: '@',
  },
  controller: MiniMapController,
  template: `
    <div ng-attr-id="{{ $ctrl.mapId }}" class="c-mini-map"></div>
  `,
};
