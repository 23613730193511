import angular from 'angular';
import authModule from './auth/module';
import bodyPartSelectorModule from './body-part-selector/module';
import componentsModule from './components/module';
import formModule from './form/module';
import listsModule from './lists/module';
import mapModule from './map/module';
import navigationModule from './navigation/module';
import resourceModule from './resource/module';
import servicesModule from './services/module';
import utilsModule from './utils/module';

const module = angular.module('gnaas.common', [
  authModule,
  bodyPartSelectorModule,
  componentsModule,
  formModule,
  listsModule,
  mapModule,
  navigationModule,
  resourceModule,
  servicesModule,
  utilsModule,
]);

export default module.name;
