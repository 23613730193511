import moment from 'moment';
import RestResource from 'common/resource/services/restResource';

export function format (item) {
  let dateTime = item.time.date ? item.time.date : item.time;
  let formatted = {
    id: item.id,
    online: !!item.online,
    statusString: item.online ? 'Online' : 'Offline',
    time: moment(dateTime, 'YYYY-MM-DD HH:mm:ss'),
  };

  if (item.offlineReason) {
    formatted.offlineReason = item.offlineReason.data;
  }

  if (item.vehicleCallsign) {
    formatted.vehicleCallsign = item.vehicleCallsign.data;
  }

  return formatted;
}

export default class CallsignStatusUpdateResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'vehicle-callsign-status-updates';
  }

  format (item) {
    return format(item);
  }
}
