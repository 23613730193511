import RestResource from 'common/resource/services/restResource';

export const format = (item) => ({
  id: item.id,
  name: item.name,
});

export const parse = (item) => ({
  id: item.id,
  name: item.name,
});

export default class InfectionStatusResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'categories/infection-statuses';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
};
