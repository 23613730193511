export default function FormCheckbox () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      disabled: '=feDisabled',
      required: '=feRequired',
      label: '@feLabel',
      model: '=feModel',
    },
    controller: function () {},
    controllerAs: '$ctrl',
    bindToController: true,
    template: `
      <div class="form-checkbox" layout="column" layout-align="center"
              ng-class="{'s-checked': $ctrl.model}">
          <md-checkbox ng-model="$ctrl.model" ng-disabled="$ctrl.disabled" ng-required="$ctrl.required">
              <span>{{ $ctrl.label }}</span>
          </md-checkbox>
      </div>
    `,
  };
}
