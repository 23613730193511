import uuid from 'node-uuid';
import moment from 'moment';

export default class CreateStockCheckController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $q, $state, $stateParams, Dialog, TextareaDialog, DrugStockResource, DrugStockCheckResource, Session,
    TransactionApprovalDialog
  ) {
    this._q = $q;
    this._state = $state;
    this._stateParams = $stateParams;
    this._Dialog = Dialog;
    this._TextareaDialog = TextareaDialog;
    this._DrugStockResource = DrugStockResource;
    this._DrugStockCheckResource = DrugStockCheckResource;
    this._Session = Session;
    this._TransactionApprovalDialog = TransactionApprovalDialog;
  }

  $onInit () {
    this.required = this.required || false;

    this.item = {
      id: uuid.v4(),
      date: moment(),
      stock_location_id: this._stateParams.location_id,
      controlled: this._stateParams.drug_type.toLowerCase() === 'controlled',
    };

    this._DrugStockResource.index(this._stateParams).then(items => {
      this.item.drug_stock_check_items = items.map(item => {
        return {
          id: uuid.v4(),
          drug: item.drug,
          expected_amount: item.quantity,
          actual_amount: null,
          resolved: null,
          initial_comments: null,
        };
      });
    });
  }

  showCommentsDialog ($event, item) {
    const required = true;
    this._TextareaDialog
      .show({ $event, value: item.initial_comments, label: 'Comments', title: 'Add Comments', required })
      .then(value => { item.initial_comments = value; });
  }

  onInputChange (item) {
    item.resolved = item.expected_amount === item.actual_amount ? 1 : 0;
  }

  cancel () {
    this._state.go('admin.drugs.stockChecks.list');
  }

  submit (data) {
    if (this.form.$invalid) return;

    const discrepancies = data.drug_stock_check_items.filter(item => item.expected_amount !== item.actual_amount);

    this._validateDiscrepancyComments(discrepancies)
      .then(() => this._submitStockCheck(this.item.controlled))
      .then(users => this._DrugStockCheckResource.create(Object.assign({}, data, users)))
      .then(() => this._checkForDiscrepancies(discrepancies))
      .then(() => this._state.go('admin.drugs.stockChecks.show', { id: this.item.id }));
  }

  _validateDiscrepancyComments (discrepancies) {
    const missingComments = discrepancies.filter(item => !item.initial_comments || !item.initial_comments.length);
    if (missingComments.length) {
      const drugs = this._getDrugNamesString(missingComments);
      this._Dialog.alert(`Please add comments to the following drugs: ${drugs}.`, 'Discrepancies found');
      return this._q.reject();
    }
    return this._q.resolve();
  }

  _submitStockCheck (controlled) {
    if (controlled) {
      const dialogParams = { dataOnly: true, userEmails: { first: this._Session.user().email } };
      return this._TransactionApprovalDialog.show(dialogParams);
    } else {
      return this._q.resolve({});
    }
  }

  _checkForDiscrepancies (discrepancies) {
    if (discrepancies.length) {
      const drugs = this._getDrugNamesString(discrepancies);
      return this._Dialog.alert(`Please adjust stock for the following drugs: ${drugs}.`, 'Adjust stock');
    } else {
      return this._q.resolve();
    }
  }

  _getDrugNamesString (stockCheckItems) {
    return stockCheckItems.map(item => item.drug.name).join(', ');
  }
}
