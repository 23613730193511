export default function ($stateProvider, $urlRouterProvider) {
  'ngInject';

  // $urlRouterProvider.when('/admin/drugs/stock-checks', );

  $stateProvider
    .state('admin.drugs', {
      abstract: true,
      url: '/drugs',
      template: '<div ui-view layout="column" flex></div>',
    })
    .state('admin.drugs.drugList', {
      url: '/drug-list',
      controllerAs: '$ctrl',
      controller: 'DrugListController',
      templateUrl: 'common/resource/templates/resource.tpl.html',
      data: {
        title: 'Drug List',
      },
    })
    .state('admin.drugs.nonFormularyDrugList', {
      url: '/non-formulary-drug-list',
      controllerAs: '$ctrl',
      controller: 'NonFormularyDrugListController',
      templateUrl: 'pages/admin/drugs/templates/non-formulary.tpl.html',
      data: {
        title: 'Non-formulary Drug List',
      },
    })
    .state('admin.drugs.infusionList', {
      url: '/infusion-list',
      controllerAs: '$ctrl',
      controller: 'InfusionListController',
      templateUrl: 'common/resource/templates/resource.tpl.html',
      data: {
        title: 'Infusion List',
      },
    })
    .state('admin.drugs.transactionHistory', {
      url: '/transaction-history',
      controllerAs: '$ctrl',
      controller: 'TransactionHistoryController',
      templateUrl: 'pages/admin/drugs/templates/transaction-history.tpl.html',
      data: {
        title: 'Transaction History',
      },
    })
    .state('admin.drugs.stockChecks', {
      abstract: true,
      url: '/stock-checks',
      template: '<div ui-view layout="column" flex></div>',
    })
      .state('admin.drugs.stockChecks.list', {
        url: '/',
        controllerAs: '$ctrl',
        controller: 'StockCheckListController',
        templateUrl: 'pages/admin/drugs/templates/stock-check-list.tpl.html',
        data: {
          title: 'Stock Checks',
        },
      })
      .state('admin.drugs.stockChecks.create', {
        url: '/create',
        controllerAs: '$ctrl',
        controller: 'CreateStockCheckController',
        templateUrl: 'pages/admin/drugs/templates/create-stock-check.tpl.html',
        params: {
          location_id: null,
          drug_type: null,
        },
        data: {
          title: 'Create Stock Check',
        },
      })
      .state('admin.drugs.stockChecks.show', {
        url: '/{id}',
        controllerAs: '$ctrl',
        controller: 'StockCheckController',
        templateUrl: 'pages/admin/drugs/templates/stock-check.tpl.html',
        data: {
          title: 'Stock Check',
        },
      })
    .state('admin.drugs.batchManagement', {
      url: '/batch-management',
      abstract: true,
      controllerAs: '$ctrl',
      controller: 'BatchManagementController',
      templateUrl: 'pages/admin/drugs/templates/batch-management.tpl.html',
    })
      .state('admin.drugs.batchManagement.allDrugs', {
        url: '/all-drugs',
        controllerAs: '$ctrl',
        controller: 'AllDrugsController',
        templateUrl: 'pages/admin/drugs/templates/all-drugs.tpl.html',
        data: {
          title: 'All Drug Stock',
        },
      })
      .state('admin.drugs.batchManagement.drugBreakdown', {
        url: '/drug-breakdown',
        controllerAs: '$ctrl',
        controller: 'DrugBreakdownController',
        templateUrl: 'pages/admin/drugs/templates/drugs-breakdown.tpl.html',
        data: {
          title: 'Drug Stock Breakdown',
        },
      });
};
