import RestResource from 'common/resource/services/restResource';
import { format as formatInjury } from 'core/categories/resources/injury.resource';
import { format as formatIllness } from 'core/categories/resources/illness.resource';
import { format as formatIntervention } from 'core/categories/resources/intervention.resource';

export function format (item) {
  let formatted = {
    id: item.id,
    name: item.name,
    marker: {
      x: item.marker_x,
      y: item.marker_y,
    },
  };

  if (item.injuries) {
    formatted.injuries = item.injuries.data.map(item => formatInjury(item));
  }

  if (item.illnesses) {
    formatted.illnesses = item.illnesses.data.map(item => formatIllness(item));
  }

  if (item.interventions) {
    formatted.interventions = item.interventions.data.map(item => formatIntervention(item));
  }

  return formatted;
}

export function parse (item) {
  let parsed = {
    id: item.id ? item.id : null,
    name: item.name,
    marker_x: item.marker.x,
    marker_y: item.marker.y,
  };

  if (item.injuries) {
    parsed.injuries = item.injuries.map(item => item.id);
  }

  if (item.illnesses) {
    parsed.illnesses = item.illnesses.map(item => item.id);
  }

  if (item.interventions) {
    parsed.interventions = item.interventions.map(item => item.id);
  }

  return parsed;
}

export default class BodyPartResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'categories/bodyparts';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
