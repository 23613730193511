import { copy } from 'angular';

export class ResourceItemListController {
  /* @ngInject */
  constructor ($mdSidenav, Toast, Utils, Dialog, Session) {
    this._mdSidenav = $mdSidenav;
    this._Toast = Toast;
    this._Utils = Utils;
    this._Dialog = Dialog;
    this._Session = Session;
  }

  $onInit () {
    this.items = this.items || [];
    this.fields = this.fields || {};
    this.hasProfiles = this.hasProfiles || false;
  }

  listButtonAction (evt, item) {
    if (this.hasProfiles) {
      this.showSidenav(item);
    } else {
      this.showCreateDialog(evt, item);
    }
  }

  showSidenav (item) {
    if (!this.hasProfiles) {
      return;
    }
    this.selectedItem = item;
    this._mdSidenav('resource-item').open();
  }

  showCreateDialog (evt, data) {
    if (this.hasProfiles) {
      this._mdSidenav('resource-item').close();
    }

    let editing = !!data;

    let dialogParams = {
      controller: this.createDialogController,
      locals: {
        editing: editing,
        item: copy(data),
        relationData: this.relationData,
      },
      targetEvent: evt,
      templateUrl: this.createDialogTemplateUrl,
    };

    this._Dialog.show(dialogParams).then((item) => {
      if (editing) {
        this._Utils.replaceInArrayById(this.items, item.id, item);
        this._Toast.showSimple(`${this.singularName} updated.`);
      } else {
        this.items.push(item);
        this._Toast.showSimple(`New ${this.singularName} created.`);
      }
    }).catch((itemId) => {
      if (itemId) {
        this._Utils.removeFromArrayById(this.items, itemId);
        this._Toast.showSimple(`${this.singularName} deleted.`);
      }
    });
  }

  isAdmin () {
    const user = this._Session.user();
    return user.group.slug === 'system-administrators';
  };
};

export default {
  bindings: {
    items: '<',
    singularName: '@',
    pluralName: '@',
    createDialogController: '<',
    createDialogTemplateUrl: '@',
    relationData: '<',
    listFields: '<',
    hasProfiles: '<',
    profileSidenavId: '@',
    profileFields: '<',
  },
  controller: ResourceItemListController,
  templateUrl: 'common/resource/templates/resourceItemList.tpl.html',
};
