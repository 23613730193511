import moment from 'moment';
import { DATE_FORMAT } from 'common/resource/module.constants';

function getDateString (date) {
  // Might be worth adding a regex here
  if (typeof date === 'string') {
    return date;
  }

  if (moment.isMoment(date)) {
    return date.format(DATE_FORMAT);
  }

  if (date instanceof Date) {
    return moment(date).format(DATE_FORMAT);
  }

  return null;
}

export default class ShiftService {
  /**
   * @constructor
   * @ngInject
   */
  constructor (ShiftResource) {
    this.ShiftResource = ShiftResource;
  }

  /**
   * Get shifts for a specific date.
   * If date is not set - use today's date.
   *
   * To override default includes, pass in a different string, or an empty string to remove them.
   *
   * @param {moment.Moment|Date|string|null} date Date to retrieve shift data for
   * @param include Shift includes
   * @returns Promise
   */
  getShiftsForDate (date = null, include = null) {
    date = getDateString(date);
    if (date === null) date = moment().format(DATE_FORMAT);
    if (include === null) {
      include = 'properties.base,properties.vehicleCallsign,users.profile.image,users.profile.profession';
    }
    return this.ShiftResource.index({ date, include });
  }

  /**
   * Retrieve shifts from yesterday and add to dashboard page if shift end time is the next day.
   *
   * @param {moment.Moment|Date|string|null} date Date to retrieve shift data for
   * @param include Shift includes
   * @returns Promise
   */
  getOvernightShiftsForDate (date = null, include = null) {
    date = date === null ? moment() : moment(date);
    const dayBefore = date.clone().subtract(1, 'day');
    return this.getShiftsForDate(dayBefore, include).then(data => {
      return data.filter(shift => moment(shift.end_time).isSame(date, 'date'));
    });
  }
}
