import { copy } from 'angular';

/**
 * Resolves object.
 *
 * @type {Object}
 */
let resolve = {
  category: function ($stateParams, CATEGORY_MAP) {
    'ngInject';
    return CATEGORY_MAP[$stateParams.categoryName];
  },
};

/**
 * Category controller.
 *
 * @param  {Object} $injector Injector object
 * @param  {Object} category  Category object
 * @return {void}
 */
function categoryController ($injector, category) {
  'ngInject';

  this.category = copy(category);

  let injectedServices = {};

  try {
    let service = $injector.get(category.resource);
    this.category.resource = service;
    injectedServices[category.resource] = service;
  } catch (err) { console.log(err); }

  if (category.relationData) {
    try {
      Object.keys(category.relationData).forEach((key) => {
        if (injectedServices[category.relationData[key].resource]) {
          this.category.relationData[key].resource = injectedServices[category.relationData[key].resource];
        } else {
          let service = $injector.get(category.relationData[key].resource);
          this.category.relationData[key].resource = service;
          injectedServices[category.relationData[key].resource] = service;
        }
      });
    } catch (err) { console.log(err); }
  }

  if (category.controller) {
    this.category.controller = category.controller;
  }

  if (category.form) {
    this.category.form = category.form;
  }
};

/**
 * Prevents category list component from being loaded if no category resolved.
 *
 * @param  {Object} $state   State object
 * @param  {Object} category Category object
 * @return {void}
 */
function onEnter ($state, category) {
  'ngInject';

  if (!category) {
    // NOTE Instead of dashboard, go back to previous state or prevent state change
    $state.go($state.current.name || 'dashboard');
  }
};

export default function ModuleConfig ($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.category', {
    url: '/categories/:categoryName',
    template: '<category-items category="$ctrl.category" layout="column" flex></category-items>',
    resolve: resolve,
    onEnter: onEnter,
    controller: categoryController,
    controllerAs: '$ctrl',
    data: {
      title: 'Categories',
    },
  });
}
