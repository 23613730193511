import { copy } from 'angular';
import RejectDrugController from '../reject-dialog/reject-dialog.controller';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class ReviewDrugController extends ResourceDialogController {
  init () {
    super.init();
    this.DrugResource = this.$injector.get('DrugResource');
    this._Dialog = this.$injector.get('Dialog');
    this._Utils = this.$injector.get('Utils');
    this._Toast = this.$injector.get('Toast');

    this.rejectDialogController = RejectDrugController;
    this.rejectDialogTemplateUrl = 'pages/admin/drugs/services/reject-dialog/reject-dialog.tpl.html';
  }

  approve (id) {
    this.DrugResource
      .approve(id)
      .then(drug => { this.Dialog.hide(drug); })
      .catch(err => {
        if (err.status) {
          this.Toast.showSimple('Error: could not approve drug.');
        }
      });
  }

  reject (evt, data) {
    let dialogParams = {
      controller: this.rejectDialogController,
      locals: {
        item: copy(data),
        relationData: this.relationData,
      },
      targetEvent: evt,
      templateUrl: this.rejectDialogTemplateUrl,
    };

    this._Dialog.show(dialogParams).then(() => {
      this._Utils.removeFromArrayById(this.items, data.id);
      this._Toast.showSimple('Non-formulary Drug rejected.');
    }).catch((err) => {
      if (err.status) {
        this._Toast.showSimple('Error: could not reject drug.');
      }
    });
  }
}
