import ResourceDialog from 'common/resource/services/resourceDialog';
import HandoverDialogController from './handover-dialog.controller';

export default class HandoverDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(HandoverDialogController);
    this.setTemplateUrl('core/prf/services/handover-dialog/handover-dialog.tpl.html');
  }
}
