import RestResource from 'common/resource/services/restResource';
import { format as formatConsumable } from 'core/consumables/resources/consumable.resource';

export function format (item) {
  const i = {
    id: item.id,
    name: item.name,
  };

  if (item.consumables) {
    i.consumables = item.consumables.data.map(formatConsumable);
  }

  return i;
}

export function parse (item) {
  return {
    id: item.id,
    name: item.name,
  };
}

export default class ConsumableTypeResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'categories/consumable-types';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
};
