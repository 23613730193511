function matchesIncidentNumber (incidents, value) {
  if (!incidents || !incidents.length) {
    return false;
  }
  return incidents.some(function (incident) {
    return incident.incident_number.toLowerCase().indexOf(value.toLowerCase()) > -1;
  });
}

function matchesDate (date, value) {
  return date.format('DD-MM-YYYY').indexOf(value) > -1;
}

export default function filterRequests () {
  return function (items, value, status) {
    if (typeof value === 'undefined') {
      return items;
    }

    return items.filter(function (item) {
      return matchesDate(item, value) || matchesIncidentNumber(item.incidents[status], value);
    });
  };
};
