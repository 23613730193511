import angular from 'angular';
import avatarItem from './avatar-item/avatar-item.component';
import DropDown from './drop-down/drop-down';
import SearchGrid from './search-grid/search-grid';
import InformationBar from './information-bar/information-bar';
import AddButton from './buttons/add-button.component';
import RemoveButton from './buttons/remove-button.component';
import TabGroup from './tab-group/tab-group';
import LoadingIndicator from './loading-indicator/loading-indicator';

const module = angular
  .module('gnaas.common.components', [])
  .component('avatarItem', avatarItem)
  .component('dropDown', DropDown)
  .component('searchGrid', SearchGrid)
  .component('informationBar', InformationBar)
  .component('addButton', AddButton)
  .component('removeButton', RemoveButton)
  .component('tabGroup', TabGroup)
  .component('loadingIndicator', LoadingIndicator);

export default module.name;
