export const CALENDAR_EXPIRING_DRUGS_SIDENAV = 'sidenav-calendar-expiring-items';

function reduceItems (items) {
  return Array.isArray(items) ? [].reduce.bind(items) : [].reduce.bind(Object.values(items));
}

class CalendarExpiringItemsController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($mdSidenav) {
    this._mdSidenav = $mdSidenav;
  }

  $onInit () {
    this.visible = {};
  }

  $onChanges (changes) {
    if (changes.items && changes.items.currentValue) {
      this.visible = this.getVisibleItems(this.stockLocation, changes.items.currentValue);
    }
  }

  close () {
    this._mdSidenav(CALENDAR_EXPIRING_DRUGS_SIDENAV).close();
  }

  onStockLocationChange ({ value }) {
    this.visible = this.getVisibleItems(value, this.items);
  }

  getVisibleItems (stockLocation, items) {
    return reduceItems(items)((acc, cur) => {
      acc[cur.key] = stockLocation
        ? cur.batches.filter(i =>
          i.location ? i.location.id === stockLocation.id : i.stockLocation.id === stockLocation.id
        )
        : [...cur.batches];
      return acc;
    }, {});
  }
}

export default {
  bindings: {
    items: '<',
    stockLocations: '<',
  },
  controller: CalendarExpiringItemsController,
  templateUrl: 'pages/calendar/templates/calendar-expiring-items.tpl.html',
};
