import moment from 'moment';

export default {
  bindings: {
    item: '<',
  },
  controller: function () {
    this.deploymentTime = null;
    if (this.item.deployment_statuses) {
      const mobileStatuses = this.item.deployment_statuses.filter(status => status.deployment_status.is_mobile);
      if (mobileStatuses.length) {
        this.deploymentTime = moment(mobileStatuses[0].time).format('HH:mm');
      }
    }

    this.patientsTreated = 0;
    if (this.item.patient_report_forms) {
      this.patientsTreated = this.item.patient_report_forms.length;
    }
  },
  templateUrl: 'core/deployments/components/deployment-list-item/deployment-list-item.tpl.html',
};
