import RestResource from 'common/resource/services/restResource';
import format from 'core/prf/services/survey-formatter.service';
import parse from 'core/prf/services/survey-parser.service';

export default class SurveyResource extends RestResource {
  init () {
    this.resourcePath = 'surveys';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
