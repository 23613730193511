import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class CreateCallsignController extends ResourceDialogController {
  init () {
    super.init();
    this._resource = this.$injector.get('VehicleCallsignResource');
    this._ID = uuid.v4();
  }

  create (data) {
    data.id = this._ID;
    this._resource.create(data).then(() => {
      data.statusString = data.online ? 'Online' : 'Offline';
      data.baseName = data.base.name;
      this.Dialog.hide(data);
    }).catch(console.log.bind(console));
  };

  update (data) {
    this._resource.update(data.id, data, {
      include: 'vehicle,base,offlineReason',
    }).then(item => {
      item.baseName = item.base.name;
      this.Dialog.hide(item);
    }).catch(console.log.bind(console));
  };

  delete (id) {
    this.Dialog.confirm().then(() => {
      this._resource.destroy(id).then(() => {
        this.Dialog.cancel(id);
      });
    });
  };
}
