import uuid from 'node-uuid';

export default class CalendarEventTypeController {
  constructor ($mdColorPalette, Dialog) {
    'ngInject';
    this._colorPalette = $mdColorPalette;
    this._Dialog = Dialog;

    this.ID = uuid.v4();

    this.includePath = 'pages/admin/categories/templates/forms/calendar-event-type.tpl.html';

    this.disabledColors = ['yellow', 'lime', 'amber'];
    this.colors = Object.keys($mdColorPalette).filter((color) => {
      if (this.disabledColors.indexOf(color) === -1) {
        return color;
      }
    }).map((color) => {
      let value = $mdColorPalette[color][500].value;
      let nameParts = color.split('-').map((part) => {
        return part.charAt(0).toUpperCase() + part.slice(1);
      });

      return {
        name: nameParts.join(' '),
        value: `rgb(${value[0]},${value[1]},${value[2]})`,
      };
    });
  }

  submit (categoryData) {
    if (this.form.$invalid) {
      return;
    }

    if (this.editing) {
      this._update(categoryData);
    } else {
      this._create(categoryData);
    }
  };

  cancel () {
    this._Dialog.cancel();
  };

  delete (id) {
    this._Dialog.confirm().then(() => {
      this.resource.destroy(id).then(() => {
        this._Dialog.cancel(id);
      });
    });
  };

  _create (categoryData) {
    categoryData.id = this.ID;

    this.resource.create(categoryData).then(() => {
      this._Dialog.hide(categoryData);
    }).catch(console.log.bind(console));
  };

  _update (categoryData) {
    this.resource.update(categoryData.id, categoryData).then((category) => {
      this._Dialog.hide(category);
    }).catch(console.log.bind(console));
  };
}
