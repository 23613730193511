/* global fetch, Promise */
import L from 'leaflet';

function toQuadKey (x, y, z) {
  let index = '';
  for (let i = z; i > 0; i--) {
    let b = 0;
    let mask = 1 << (i - 1);
    if ((x & mask) !== 0) b++;
    if ((y & mask) !== 0) b += 2;
    index += b.toString();
  }
  return index;
}

const VALID_IMAGERY_SETS = [
  'Road',
  'OrdnanceSurvey',
];

const BingLayer = L.TileLayer.extend({
  options: {
    bingMapsKey: null,
    imagerySet: 'Road',
  },

  statics: {
    METADATA_URL: 'https://dev.virtualearth.net/REST/v1/Imagery/Metadata/{imagerySet}' +
      '?key={bingMapsKey}&include=ImageryProviders&uriScheme=https&output=json',
  },

  getAttribution: function () {
    return '&copy; <a href="http://bing.com/maps">Bing Maps</a>';
  },

  initialize: function (options) {
    if (!options || !options.bingMapsKey) {
      throw new Error('bingMapsKey must be provided');
    }
    options = L.setOptions(this, options);

    if (VALID_IMAGERY_SETS.indexOf(options.imagerySet) < 0) {
      throw new Error('Invalid imagerySet');
    }

    const metaDataUrl = L.Util.template(BingLayer.METADATA_URL, {
      bingMapsKey: this.options.bingMapsKey,
      imagerySet: this.options.imagerySet,
    });

    fetch(metaDataUrl)
      .then(response => response.json())
      .then(this._metaDataOnLoad.bind(this))
      .catch(console.error.bind(console));

    // https://github.com/Leaflet/Leaflet/issues/137
    if (!L.Browser.android) {
      this.on('tileunload', this._onTileRemove);
    }
  },

  createTile: function (coords, done) {
    const tile = document.createElement('img');
    L.DomEvent.on(tile, 'load', L.bind(this._tileOnLoad, this, done, tile));
    L.DomEvent.on(tile, 'error', L.bind(this._tileOnError, this, done, tile));
    if (this.options.crossOrigin) {
      tile.crossOrigin = '';
    }
    tile.alt = '';
    if (this._url) {
      tile.src = this.getTileUrl(coords);
    }
    return tile;
  },

  getTileUrl: function (coords) {
    return L.Util.template(this._url, {
      quadkey: toQuadKey(coords.x, coords.y, coords.z),
      subdomain: this._getSubdomain(coords),
      culture: 'en_GB',
    });
  },

  _metaDataOnLoad: function (metaData) {
    if (metaData.statusCode !== 200) {
      throw new Error('Bing Imagery Metadata error: \n' + JSON.stringify(metaData, null, '  '));
    }
    const resource = metaData.resourceSets[0].resources[0];
    this.options.subdomains = resource.imageUrlSubdomains;
    this.setUrl(resource.imageUrl);
    return Promise.resolve();
  },
});

export default function (options) {
  return new BingLayer(options);
};
