import moment from 'moment';
import { DATE_TIME_FORMAT } from 'common/resource/module.constants';

const getCellClass = (grid, row) => {
  return row.entity.isExpired ? 'ui-grid-cell-bg-red' : '';
};

const DEFAULT_COLUMNS = {
  consumable_name: { name: 'Name' },
  expires_on: { name: 'Batch Expiry', type: 'date', cellFilter: 'date:"dd-MM-yyyy"' },
  consumable_type: { name: 'Type' },
  location: { name: 'Location' },
};
Object.values(DEFAULT_COLUMNS).forEach(col => {
  Object.assign(col, { width: '*', cellClass: getCellClass });
});

const DEFAULT_GRID_OPTS = { enablePagination: true, useExternalPagination: true };

export default class ConsumableStockController {
  /**
   * @ngInject
   * @constructor
   */
  constructor (
    ConsumableTypeResource,
    ConsumableBatchResource,
    ConsumableOrderFormDialog,
    CreateConsumableStockDialog,
    Dialog,
    StockLocationResource
  ) {
    this.ConsumableTypeResource = ConsumableTypeResource;
    this.ConsumableBatchResource = ConsumableBatchResource;
    this.ConsumableOrderFormDialog = ConsumableOrderFormDialog;
    this.CreateConsumableStockDialog = CreateConsumableStockDialog;
    this.Dialog = Dialog;
    this.StockLocationResource = StockLocationResource;
  }

  $onInit () {
    this.actions = [
      { name: 'Record Delivery', action: this._showCreateDialog.bind(this) },
      { name: 'Print Order', action: this._showOrderFormDialog.bind(this) },
    ];

    this.searchGridActionCol = {
      options: {
        width: '86',
      },
      actions: [{
        name: 'Remove',
        callback: this.removeBatch.bind(this),
      }],
    };

    this.pagination = { pageNumber: 0, pageSize: 0 };
    this.columns = Object.assign({}, DEFAULT_COLUMNS);
    this.options = Object.assign({}, DEFAULT_GRID_OPTS, {
      resolvePage: (pageNumber, pageAmount) => {
        this.updateData(pageNumber, pageAmount);
      },
    });

    this.filters = {};
    this.ConsumableTypeResource.index({ include: 'consumables' }).then(items => {
      this.filters.consumableTypes = items;
      this.filters.consumables = items.reduce((acc, cur) => acc.concat(cur.consumables), []);
    });
    this.StockLocationResource.index().then(items => (this.filters.stockLocations = items));

    this.criteria = {
      consumableId: null,
      consumableTypeId: null,
      stockLocationId: null,
      includeExpiredBatches: true,
    };

    this.updateData();
  }

  removeBatch (batch) {
    this.Dialog
      .confirm('Are you sure you want to delete this batch?')
      .then(() => {
        return this.ConsumableBatchResource.destroy(batch.id);
      })
      .then(this.updateData.bind(this))
      .catch(console.log.bind(console));
  }

  updateData (pageNumber, pageSize) {
    if (!pageNumber) pageNumber = 1;
    if (!pageSize) pageSize = 25;

    this.pagination.pageNumber = pageNumber;
    this.pagination.pageSize = pageSize;

    const params = {
      expiry_mode: this.criteria.includeExpiredBatches ? 'all' : 'non-expired',
      page: pageNumber,
      page_size: pageSize,
      include: 'consumable.type,stockLocation',
    };

    if (this.criteria.consumableId) params.consumable_id = this.criteria.consumableId;
    if (this.criteria.consumableTypeId) params.consumable_type_id = this.criteria.consumableTypeId;
    if (this.criteria.stockLocationId) params.stock_location_id = this.criteria.stockLocationId;

    this.ConsumableBatchResource
      .index(params)
      .then(data => {
        this.options.pageNumber = pageNumber;
        this.options.totalItems = data.pagination.total;
        this.batches = this._transformData(data.data);
      })
      .catch(console.log.bind(console));
  }

  onConsumableTypeChange ({ name, value }) {
    const type = this.filters.consumableTypes.find(item => item.id === value);
    this.filters.consumables = type
      ? type.consumables
      : this.filters.consumableTypes.reduce((acc, cur) => acc.concat(cur.consumables), []);
  }

  _transformData (data) {
    const now = moment();
    return data.map(item => {
      const expiresOn = moment(item.expires_on, DATE_TIME_FORMAT);
      item.isExpired = expiresOn.isSameOrBefore(now);
      item.expires_on = expiresOn.toDate();
      item.location = item.stockLocation.name;
      item.consumable_name = item.consumable.name;
      item.consumable_type = item.consumable.type.name;
      return item;
    });
  }

  _showCreateDialog ($event) {
    this.CreateConsumableStockDialog.show($event).then(() => {
      this.updateData(this.pagination.pageNumber, this.pagination.pageSize);
    });
  }

  _showOrderFormDialog ($event) {
    this.ConsumableOrderFormDialog.show({ $event });
  }
}
