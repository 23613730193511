import { copy, element } from 'angular';
import moment from 'moment';
import 'moment-range';
import CreateCalendarEventController from 'pages/calendar/controllers/createCalendarEventController';
import { CALENDAR_DAY_SIDENAV } from './calendarDay';
import { CALENDAR_EXPIRING_DRUGS_SIDENAV } from './calendar-expiring-items';
import { DATE_FORMAT } from 'common/resource/module.constants';

export class CalendarController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
      $mdSidenav, $timeout, Dialog, Toast, Utils, CalendarEventResource, CalendarAgendaResource,
      CalendarEventTypeResource, VehicleResource, CrewMemberResource, BaseResource, DrugBatchesResource,
      StockLocationResource, ConsumableBatchResource, DestroyBatchDialog
  ) {
    this._mdSidenav = $mdSidenav;
    this._timeout = $timeout;
    this._Dialog = Dialog;
    this._Toast = Toast;
    this._Utils = Utils;
    this._CalendarEventResource = CalendarEventResource;
    this._CalendarAgendaResource = CalendarAgendaResource;
    this._CalendarEventTypeResource = CalendarEventTypeResource;
    this._VehicleResource = VehicleResource;
    this._CrewMemberResource = CrewMemberResource;
    this._BaseResource = BaseResource;
    this._DrugBatchesResource = DrugBatchesResource;
    this._StockLocationResource = StockLocationResource;
    this._ConsumableBatchResource = ConsumableBatchResource;
    this._DestroyBatchDialog = DestroyBatchDialog;
  }

  $onInit () {
    this.today = moment();
    this.date = moment();
    this.weeks = [];
    this.calendarDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    this.stockLocations = [];
    this.relationData = {
      eventTypes: [],
      vehicles: [],
      crewMembers: [],
      bases: [],
    };
    this.expiries = {
      drugs: {
        key: 'drug',
        labels: { single: 'Drug', multiple: 'Drugs' },
        batches: [],
        removeBatch: this.onRemoveDrugBatchClick.bind(this),
      },
      consumables: {
        key: 'consumable',
        labels: { single: 'Consumable', multiple: 'Consumables' },
        batches: [],
        removeBatch: this.onRemoveConsumableClick.bind(this),
      },
    };

    this.currentMonth();
    this._loadRelationData();
  }

  currentMonth () {
    this.date = moment();
    this._loadMonth();
  }

  nextMonth () {
    this.date.add(1, 'M');
    this._loadMonth();
  }

  previousMonth () {
    this.date.subtract(1, 'M');
    this._loadMonth();
  }

  selectDay (day) {
    this.selectedDay = day;
    this._mdSidenav(CALENDAR_DAY_SIDENAV).open();
  }

  showCreateDialog (evt, date, data) {
    const editing = !!data;

    if (date) {
      date.hours(moment().hours());
    }

    const dialogParams = {
      controller: CreateCalendarEventController,
      locals: {
        editing: editing,
        item: copy(data),
        relationData: this.relationData,
        date: date || moment(),
      },
      targetEvent: evt,
      templateUrl: 'pages/calendar/templates/createCalendarEventDialog.tpl.html',
      onShowing: ($scope, $element) => {
        let list, listHeight;
        $scope.crewMemberListToggled = false;

        this._timeout(() => {
          list = element($element[0].querySelector('div.form-checkbox-list__items'));
          listHeight = list.prop('clientHeight');
          list.css('height', 0);
        }, 100);

        $scope.toggleCrewMemberList = function () {
          $scope.crewMemberListToggled = !$scope.crewMemberListToggled;
          if ($scope.crewMemberListToggled) {
            list.css('height', listHeight + 'px');
          } else {
            list.css('height', 0);
          }
        };
      },
      multiple: true,
    };

    this._Dialog.show(dialogParams).then((item) => {
      if (editing) {
        this._handleCalendarEvent(item, 'update');
        this._Toast.showSimple('Calendar Event updated.');
      } else {
        this._handleCalendarEvent(item, 'create');
        this._Toast.showSimple('New Calendar Event created.');
      }
    }).catch((item) => {
      if (item) {
        this._handleCalendarEvent(item, 'delete');
        this._Toast.showSimple('Calendar Event deleted.');
      }
    });
  }

  showExpiringDrugs () {
    this._mdSidenav(CALENDAR_EXPIRING_DRUGS_SIDENAV).open();
  }

  onRemoveDrugBatchClick ({ $event, batch }) {
    this._DestroyBatchDialog
      .show({ $event, item: batch, locals: { fromCalendar: true } })
      .then(() => {
        const drugBatches = this.expiries.drugs.batches.filter(i => i.id !== batch.id);
        this.expiries = this._getUpdatedExpiries(drugBatches, null);
      });
  }

  onRemoveConsumableClick ({ $event, batch }) {
    return this._Dialog
      .confirm('Are you sure you want to remove this batch?')
      .then(() => this._ConsumableBatchResource.destroy(batch.id))
      .then(() => {
        const consumableBatches = this.expiries.consumables.batches.filter(i => i.id !== batch.id);
        this.expiries = this._getUpdatedExpiries(null, consumableBatches);
      });
  }

  _getUpdatedExpiries (drugBatches = null, consumableBatches = null) {
    return {
      ...this.expiries,
      drugs: {
        ...this.expiries.drugs,
        batches: drugBatches || [...this.expiries.drugs.batches],
      },
      consumables: {
        ...this.expiries.consumables,
        batches: consumableBatches || [...this.expiries.consumables.batches],
      },
    };
  }

  _loadMonth () {
    const range = this._getDateRange(this.date);
    this.days = this._getDays(this.today, this.date, range);
    this.weeks = this._getWeeks(this.days.length);

    const startDate = range.start.format(DATE_FORMAT);
    const endDate = range.end.format(DATE_FORMAT);
    const expiryStart = moment(this.date).startOf('month').format(DATE_FORMAT);
    const expiryEnd = moment(this.date).endOf('month').format(DATE_FORMAT);

    let requestsFinished = 0;
    const requestsRequired = 4;
    this.dataLoading = true;

    this._CalendarEventResource
      .index({ start_date: startDate, end_date: endDate, include: 'type,vehicle,users,base' })
      .then(events => {
        events.forEach(event => { this._handleCalendarEvent(event, 'create'); });
      })
      .finally(() => (this._handleLoader('dataLoading', ++requestsFinished, requestsRequired)));

    this._CalendarAgendaResource
      .index({ start_date: startDate, end_date: endDate })
      .then(agendas => {
        agendas.forEach(this._handleAgenda.bind(this));
      })
      .finally(() => (this._handleLoader('dataLoading', ++requestsFinished, requestsRequired)));

    this._DrugBatchesResource.index({
      include: 'drug.equipmentLocationCompartmentItems.compartment.location,location',
      expiry_start: expiryStart,
      expiry_end: expiryEnd,
      quantity_mode: 'non-empty',
    }).then(expiringDrugs => {
      this.expiries = this._getUpdatedExpiries(expiringDrugs, null);
    })
    .finally(() => (this._handleLoader('dataLoading', ++requestsFinished, requestsRequired)));

    this._ConsumableBatchResource.index({
      include: 'consumable.equipmentLocationCompartmentItems.compartment.location,stockLocation',
      expiry_start: expiryStart,
      expiry_end: expiryEnd,
      quantity_mode: 'non-empty',
    }).then(expiringConsumables => {
      this.expiries = this._getUpdatedExpiries(null, expiringConsumables);
    })
    .finally(() => (this._handleLoader('dataLoading', ++requestsFinished, requestsRequired)));
  }

  _loadRelationData () {
    let requestsFinished = 0;
    const requestsRequired = 5;
    this.relationDataLoading = true;

    this._CalendarEventTypeResource
      .index()
      .then(items => { this.relationData.eventTypes = items; })
      .catch(console.log.bind(console))
      .finally(() => (this._handleLoader('relationDataLoading', ++requestsFinished, requestsRequired)));

    this._VehicleResource
      .index()
      .then(items => { this.relationData.vehicles = items; })
      .catch(console.log.bind(console))
      .finally(() => (this._handleLoader('relationDataLoading', ++requestsFinished, requestsRequired)));

    this._CrewMemberResource
      .index()
      .then(items => { this.relationData.crewMembers = items; })
      .catch(console.log.bind(console))
      .finally(() => (this._handleLoader('relationDataLoading', ++requestsFinished, requestsRequired)));

    this._BaseResource
      .index()
      .then(items => { this.relationData.bases = items; })
      .catch(console.log.bind(console))
      .finally(() => (this._handleLoader('relationDataLoading', ++requestsFinished, requestsRequired)));

    this._StockLocationResource
      .index()
      .then(items => { this.stockLocations = items; })
      .catch(console.log.bind(console))
      .finally(() => (this._handleLoader('relationDataLoading', ++requestsFinished, requestsRequired)));
  }

  _getDateRange (date) {
    return moment.range(
      moment(date).startOf('month').startOf('week'),
      moment(date).endOf('month').endOf('week')
    );
  }

  _getDays (today, date, range) {
    let days = [];

    range.by('days', (day) => {
      if (day.month() !== date.month()) {
        day.isDisabled = true;
      }
      if (day.date() === today.date() && day.month() === today.month()) {
        day.isToday = true;
      }
      day.events = [];
      day.agenda = null;
      days.push(day);
    });

    return days;
  }

  _getWeeks (dayCount) {
    let weekCount = Math.ceil(dayCount / 7);
    let weeks = [];
    while (weekCount) {
      weeks.unshift(--weekCount);
    }
    return weeks;
  }

  _handleCalendarEvent (event, action = 'create') {
    if (['create', 'update', 'delete'].indexOf(action) === -1) {
      throw new Error('Action must match "create", "update" or "delete".');
    }

    event.isMultiDayEvent = Math.abs(event.start_date.diff(event.end_date, 'days')) > 0;

    if (action !== 'delete') {
      event.isStartDate = function (date) {
        return Math.abs(event.start_date.diff(date, 'days')) === 0;
      };

      event.isEndDate = function (date) {
        return Math.abs(event.end_date.diff(date, 'days')) === 0;
      };

      event.isMultiDayEventAndStartDay = function (day) {
        return event.isMultiDayEvent && event.isStartDate(day);
      };
    }

    if (event.isMultiDayEvent) {
      for (let i = 0, dl = this.days.length; i < dl; i++) {
        let day = this.days[i];
        if (day.isBetween(event.start_date, event.end_date, 'day', '[]')) {
          this._Utils.removeFromArrayById(day.events, event.id);
          if (action !== 'delete') {
            day.events.push(event);
          }
        }
      }
    } else {
      this.days.some((day) => {
        if (event.start_date.diff(day, 'days') === 0) {
          this._Utils.removeFromArrayById(day.events, event.id);
          if (action !== 'delete') {
            day.events.push(event);
          }
          return true;
        }
        return false;
      });
    }
  }

  _handleAgenda (agenda) {
    this.days.some((day) => {
      if (agenda.date.diff(day, 'days') === 0) {
        day.agenda = agenda;
        return true;
      }
      return false;
    });
  }

  _handleLoader (loaderName, requestsRequired, requestsFinished) {
    if (requestsRequired === requestsFinished) {
      this[loaderName] = false;
    }
  }
}

export default {
  controller: CalendarController,
  templateUrl: 'pages/calendar/templates/calendar.tpl.html',
};
