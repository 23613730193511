import CreateVehicleController from './createVehicleController';

export default function VehicleResourceController (VehicleResource) {
  'ngInject';

  this.singularName = 'Vehicle';
  this.pluralName = 'Vehicles';

  this.createDialogController = CreateVehicleController;
  this.createDialogTemplateUrl = 'pages/admin/vehicles/templates/createDialog.tpl.html';

  VehicleResource.index().then(items => { this.items = items; });
  this.relationData = {
    types: {},
  };
  VehicleResource.getTypes().then(data => { this.relationData.types = data; });

  this.listFields = {
    registration_number: 'Reg. No.',
    typeString: 'Type',
  };

  this.usesSidenav = false;
}
