function InvalidGridReferenceError (message) {
  this.message = message;
  this.name = this.constructor.name;
  this.stack = (new Error()).stack;
}

InvalidGridReferenceError.prototype = Error.prototype;
InvalidGridReferenceError.prototype.constructor = InvalidGridReferenceError;

export default InvalidGridReferenceError;
