export default function FormRadioGroup () {
  return {
    replace: true,
    restrict: 'E',
    scope: {
      buttons: '=feButtons',
      disabled: '=feDisabled',
      errors: '=feErrors',
      label: '@feLabel',
      model: '=feModel',
      name: '@feName',
      required: '=feRequired',
    },
    controller: () => {},
    controllerAs: '$ctrl',
    bindToController: true,
    templateUrl: 'common/form/directives/formRadioGroup.tpl.html',
  };
}
