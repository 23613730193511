export class PasswordResetController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog, $http, API_URL, $state, $stateParams, APP_ORG) {
    this._Dialog = Dialog;
    this._http = $http;
    this._apiUrl = API_URL;
    this._state = $state;
    this._stateParams = $stateParams;
    this.APP_ORG = APP_ORG;

    this.init();
  }

  init () {
    this.resourcePath = 'password/reset';

    this.email = this._stateParams.email ? this._stateParams.email : '';
    this.token = this._stateParams.token ? this._stateParams.token : '';
  }

  submit (data) {
    const resetObject = {
      email: this.email,
      token: this.token,
      password: data.password,
      password_confirmation: data.confirmPassword,
    };

    this._http.post(`${this._apiUrl}${this.resourcePath}`, resetObject)
      .then(() => {
        this._Dialog.alert(
          'Please login using your new password.',
          'Password changed successfully'
        );
        this._state.go('login');
      })
      .catch(err => {
        if (!err.status) return;

        if (err.status === 400 && err.data.message === 'passwords.token') {
          this._Dialog.alert(
            'Password reset link not valid',
            'Password reset error'
          );
        } else {
          this._Dialog.alert(
            'Error during password reset. Please contact administrator.',
            'Password reset error'
          );
        }
      });
  }
}

export default {
  controller: PasswordResetController,
  templateUrl: 'pages/passwordReset/templates/password-reset.tpl.html',
};
