import uuid from 'node-uuid';
import moment from 'moment';
import { copy } from 'angular';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'common/resource/module.constants';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class RecordDeliveryController extends ResourceDialogController {

  init () {
    super.init();

    this.StockLocationResource = this.$injector.get('StockLocationResource');
    this.DrugResource = this.$injector.get('DrugResource');
    this.DrugStockTransactionResource = this.$injector.get('DrugStockTransactionResource');
    this.TransactionApprovalService = this.$injector.get('TransactionApprovalService');

    this.StockLocationResource.index().then(items => { this.stockLocations = items; });
    this.DrugResource.index().then(items => {
      this.drugs = items.filter(item => !item.exclude_from_stock);
    });

    this.today = new Date();
    this.item = {
      batches_received: [],
      timestamp: moment().startOf('minute').toDate(),
    };
  }

  newBatch () {
    this.item.batches_received.push({ id: uuid.v4() });
  }

  removeBatch (id) {
    this.item.batches_received = this.item.batches_received.filter(item => item.id !== id);
  }

  create (input) {
    this.TransactionApprovalService
      .attempt(this._createTransaction.bind(this), input)
      .then(data => {
        this.Dialog.hide(data);
      });
  }

  drugChanged ($event, batchId) {
    const batch = this.item.batches_received.find(item => item.id === batchId);

    if ($event.value == null) {
      batch.dose_per_unit = null;
      batch.measurement_type = null;
      return;
    }

    const drug = this.drugs.find(item => item.id === $event.value);
    batch.dose_per_unit = drug.dose_per_unit ? drug.dose_per_unit : null;
    batch.measurement_type = drug.measurement_type;
  }

  _createTransaction (input) {
    const data = this._parseData(input, this.stockLocationId);
    return this.DrugStockTransactionResource.create(data);
  }

  _parseData (data, stockLocationId) {
    const d = copy(data);

    for (const batch of d.batches_received) {
      batch.expires_on = moment(batch.expires_on).format(DATE_FORMAT);
      batch.stock_location_id = stockLocationId;
    }

    d.transaction_type = 'received';
    d.timestamp = moment(d.timestamp).format(DATE_TIME_FORMAT);

    return d;
  }
}
