export class InformationBarController {
  $onInit () {
    this.icon = this.getIcon();
  }

  getIcon () {
    if (this.type === 'warn') { return 'error'; }
    return this.type;
  }
}

export default {
  templateUrl: 'common/components/information-bar/information-bar.tpl.html',
  controller: InformationBarController,
  transclude: true,
  bindings: {
    type: '@',
  },
};
