import ResourceDialog from 'common/resource/services/resourceDialog';
import DrugDialogController from './drug-dialog.controller';

export default class DrugDialog extends ResourceDialog {
  /**
   * @ngInject
   */
  init () {
    super.init();
    this.setController(DrugDialogController);
    this.setTemplateUrl('pages/admin/drugs/services/drug-dialog/drug-dialog.tpl.html');
  }
}
