/**
 * @abstract
 */
export default class ResourceDialogController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($injector, Dialog, Toast) {
    if (this.constructor === ResourceDialogController) {
      throw new Error('Cannot construct an abstract class.');
    }
    this.$injector = $injector;
    this.Dialog = Dialog;
    this.Toast = Toast;
    this.init();
  }

  init () {
    this.validationErrorMessage = 'Form validation error, please check all fields.';
  }

  submit (formData) {
    if (!this.form) {
      throw new Error('Form not found.');
    }

    if (this.form.$invalid) {
      this.Toast.showSimple(this.validationErrorMessage);
      return;
    }

    if (this.editing) {
      this.update(formData);
    } else {
      this.create(formData);
    }
  };

  cancel () {
    this.Dialog.cancel();
  };

  delete (id) {
    this.Dialog.confirm().then(() => {
      this.Dialog.cancel(id);
    });
  };

  create (formData) {
    this.Dialog.hide(formData);
  }

  update (formData) {
    this.Dialog.hide(formData);
  }
}
