import angular from 'angular';
import uiRouter from 'angular-ui-router';
import PasswordResetComponent from './components/password-reset';

const moduleConfig = function ($stateProvider) {
  'ngInject';
  $stateProvider.state('passwordReset', {
    url: '/password-reset?token&email',
    template: '<password-reset></password-reset>',
    data: {
      title: 'Password Reset',
    },
  });
};

const module = angular
  .module('gnaas.pages.passwordReset', [
    uiRouter,
  ])
  .config(moduleConfig)
  .component('passwordReset', PasswordResetComponent);

export default module.name;
