import moment from 'moment';
import RestResource from 'common/resource/services/restResource';
import {format as formatCrewMember} from 'pages/crewMembers/services/crewMemberResource';
import {format as formatVehicle} from 'pages/admin/vehicles/services/vehicleResource';

export function parse (item) {
  let parsed = {
    id: item.id,
    title: item.title,
    description: item.description,
    start_date: item.start_date.format('YYYY-MM-DD'),
    start_time: item.start_time._d ? item.start_time.format('HH:mm') : null,
    end_date: item.end_date.format('YYYY-MM-DD'),
    end_time: item.end_time._d ? item.end_time.format('HH:mm') : null,
    all_day_event: item.all_day_event ? item.all_day_event : 0,
    complete: item.complete ? item.complete : 0,
    vehicle_id: item.vehicle ? item.vehicle.id : null,
    base_id: item.base ? item.base.id : null,
  };

  if (item.crew_members) {
    parsed.crew_members = item.crew_members.map(item => item.id);
  }

  if (item.type) {
    parsed.calendar_event_type_id = item.type.id;
  }

  return parsed;
}

export function format (item) {
  let formatted = {
    id: item.id,
    title: item.title,
    description: item.description,
    type: item.type ? item.type.data : null,
    start_date: moment(item.start_date, 'YYYY-MM-DD'),
    end_date: moment(item.end_date, 'YYYY-MM-DD'),
    start_time: item.start_time ? moment(item.start_time, 'HH:mm') : null,
    end_time: item.end_time ? moment(item.end_time, 'HH:mm') : null,
    all_day_event: !!item.all_day_event,
    complete: !!item.complete,
  };

  if (item.vehicle) {
    formatted.vehicle = formatVehicle(item.vehicle.data);
  }
  if (item.base) {
    formatted.base = item.base.data;
  }

  if (item.users) {
    formatted.crew_members = item.users.data.map(item => formatCrewMember(item));
  }

  return formatted;
}

export default class CalendarEventResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'calendar-events';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
