export default function SearchDatepicker () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      model: '=seModel',
      errors: '=seErrors',
      required: '=seRequired',
      disabled: '=seDisabled',
      name: '@seName',
      placeholder: '@sePlaceholder',
      maxDate: '=seMaxDate',
      minDate: '=seMinDate',
    },
    controller: function () {
      this.placeholder = this.placeholder || 'Enter date';
    },
    controllerAs: '$ctrl',
    bindToController: true,
    templateUrl: 'core/search/directives/search-datepicker/search-datepicker.tpl.html',
  };
}
