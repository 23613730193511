export default class PRFApprovalController {

  /**
   * @ngInject
   * @constructor
   */
  constructor (Dialog) {
    this._Dialog = Dialog;

    this.data = this.data || {};
    this.users = this.users || [];
    this.currentUserEmail = this.currentUserEmail || null;
    this.singlePersonApproval = this.singlePersonApproval || false;

    if (this.singlePersonApproval) {
      this._setupSingleApproval();
    } else {
      this._setupMultiUserApproval();
    }
  }

  _setupSingleApproval () {
    if (this.users.length === 1) {
      this.data.second_user = { email: this.users[0].email };
    }
  }

  _setupMultiUserApproval () {
    if (this.users.length !== 2) return;

    if (this.data.first_user && this.data.first_user.email) {
      this.data.second_user = { email: this._getSpareEmail(this.data.first_user.email) };
    } else if (this.data.second_user && this.data.second_user.email) {
      this.data.first_user = { email: this._getSpareEmail(this.data.second_user.email) };
    }
  }

  _getSpareEmail (email) {
    return this.users.filter(i => i.email !== email)[0].email;
  }

  submit (data) {
    if (this.form.$invalid) return;
    return this._Dialog.hide(data);
  }

  cancel () {
    this._Dialog.cancel();
  }
}
