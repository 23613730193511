import moment from 'moment';
import { DATE_FORMAT } from 'common/resource/module.constants';

function getAdministeredDrugsFromSurveys (surveys) {
  const administeredDrugs = {};
  surveys
  // Flatten all administered and not excluded from stock drugs from surveys
    .reduce((acc, cur) => acc.concat(cur.administered_drugs), [])
    // Flatten all drugs from administered drugs, sum dosages, filter out duplicates
    .forEach(item => {
      if (item.drug.exclude_from_stock) return;
      const drugId = item.drug.id;
      if (!administeredDrugs[drugId]) {
        administeredDrugs[drugId] = {
          name: item.drug.name,
          totalDose: 0,
          unit: item.drug.measurement_type,
        };
      }
      administeredDrugs[drugId].totalDose = administeredDrugs[drugId].totalDose + parseFloat(item.dose);
    });
  return administeredDrugs;
}

function getTransactionsByDrug (stockTransactions) {
  const transactions = {};
  stockTransactions.forEach(item => {
    const drugId = item.drug.id;
    if (!transactions.hasOwnProperty(drugId)) transactions[drugId] = [];
    item.batch.expires_on = moment(item.batch.expires_on).format(DATE_FORMAT);
    transactions[drugId].push(item);
  });
  return transactions;
}

class ReviewDrugUseDialogController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog, PRFApprovalDialog, APP_ORG_SHORT) {
    this.Dialog = Dialog;
    this.PRFApprovalDialog = PRFApprovalDialog;
    this.APP_ORG_SHORT = APP_ORG_SHORT;

    if (!this.prf) throw new Error('PRF not set.');
    if (!this.onApproveClick) throw new Error('Approval callback not set.');
    if (!this.onRejectClick) throw new Error('Reject callback not set.');
  }

  $onInit () {
    this.administeredDrugs = getAdministeredDrugsFromSurveys(this.prf.surveys);
    this.transactions = getTransactionsByDrug(this.prf.stock_transactions);
  }

  approve ({ $event }) {
    this.onApproveClick({ $event }).then(() => {
      this.Dialog.hide();
    });
  }

  cancel () {
    this.Dialog.cancel();
  }

  reject () {
    this.onRejectClick().then(() => {
      this.Dialog.hide();
    });
  }
}

export default ReviewDrugUseDialogController;
