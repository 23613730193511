import ResourceDialog from 'common/resource/services/resourceDialog';
import ResourceDialogController from './record-delivery-dialog.controller';

export default class RecordDeliveryDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(ResourceDialogController);
    this.setTemplateUrl('core/drugs/services/record-delivery-dialog/record-delivery-dialog.tpl.html');
  }
}
