/* global requestAnimationFrame */

function collapse (element) {
  const sectionHeight = element.scrollHeight;
  const elementTransition = element.style.transition;
  element.style.transition = '';
  requestAnimationFrame(function () {
    element.style.height = sectionHeight + 'px';
    element.style.transition = elementTransition;
    requestAnimationFrame(function () {
      element.style.height = 0 + 'px';
    });
  });
  element.setAttribute('aria-expanded', 'true');
}

function expand (element) {
  const sectionHeight = element.scrollHeight;
  element.style.height = sectionHeight + 'px';
  const eventListener = function (e) {
    element.removeEventListener('transitionend', eventListener);
    element.style.height = null;
  };
  element.addEventListener('transitionend', eventListener);
  element.setAttribute('aria-expanded', 'false');
}

class EquipmentLocationCompartmentController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($element, $timeout) {
    this.$element = $element;
    this.$timeout = $timeout;
  }

  $onInit () {
    this.contentsToggled = false;
    this.nested = this.nested || false;
  }

  $postLink () {
    this.content = this.$element[0].querySelector('.c-compartment__content');
    collapse(this.content);
  }

  toggleContents () {
    this.contentsToggled = !this.contentsToggled;
    if (this.contentsToggled) {
      expand(this.content);
    } else {
      collapse(this.content);
    }
  };
}

const componentName = 'equipment-location-compartment';
export default {
  bindings: {
    compartment: '<',
    nested: '<',
  },
  controller: EquipmentLocationCompartmentController,
  templateUrl: `core/equipment-locations/components/${componentName}/${componentName}.tpl.html`,
};
