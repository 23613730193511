import angular from 'angular';
import adminModule from './admin/module';
import auditToolModule from './audit-tool/module';
import calendarModule from './calendar/module';
import crewMembersModule from './crewMembers/module';
import dashboardModule from './dashboard/module';
import loginModule from './login/module';
import mapModule from './map/module';
import passwordResetModule from './passwordReset/module';
import changePasswordModule from './changePassword/module';
import reportsModule from './reports/module';
import requestLogModule from './requestLog/module';
import settingsModule from './settings/module';
import shiftsModule from './shifts/module';

const module = angular
  .module('gnaas.pages', [
    adminModule,
    auditToolModule,
    calendarModule,
    crewMembersModule,
    dashboardModule,
    loginModule,
    mapModule,
    passwordResetModule,
    changePasswordModule,
    reportsModule,
    requestLogModule,
    settingsModule,
    shiftsModule,
  ]);

export default module.name;
