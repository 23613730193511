export default function CompareTo () {
  return {
    replace: false,
    restrict: 'A',
    require: '?ngModel',
    scope: {
      compareTo: '=compareTo',
    },
    link: function ($scope, $element, $attrs, ngModel) {
      ngModel.$validators.compareTo = function (modelValue, viewValue) {
        return modelValue === $scope.compareTo;
      };

      $scope.$watch('compareTo', function (newVal) {
        ngModel.$validate();
      });
    },
  };
}
