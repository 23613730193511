export default function ($stateProvider) {
  'ngInject';

  $stateProvider
    .state('admin.devices', {
      url: '/devices',
      template: '<device-page layout="column" flex />',
      data: {
        title: 'Devices',
      },
    });
};
