import ResourceDialog from 'common/resource/services/resourceDialog';
import HospitalInformationDialogController from './hospital-information-dialog.controller';

export default class HospitalInformationDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(HospitalInformationDialogController);
    this.setTemplateUrl('core/prf/services/hospital-information-dialog/hospital-information-dialog.tpl.html');
  }
}
