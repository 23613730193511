export default function SearchSelect () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      name: '@seName',
      model: '=seModel',
      options: '=seOptions',
      valueKey: '@seValueKey',
      labelKey: '@seLabelKey',
      errors: '=seErrors',
      label: '@seLabel',
      placeholder: '@sePlaceholder',
      required: '=seRequired',
      disabled: '=seDisabled',
      objectAsValue: '=seObjectAsValue',
    },
    controller: function () {
      this.valueKey = this.valueKey || 'id';
      this.labelKey = this.labelKey || 'name';
      this.placeholder = this.placeholder || 'Select value';
    },
    controllerAs: '$ctrl',
    bindToController: true,
    templateUrl: 'core/search/directives/search-select/search-select.tpl.html',
  };
};
