import moment from 'moment';
import 'moment-range';

export class RequestLogController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $state, $stateParams, Dialog, Toast, IncidentDialog,
    IncidentPageService, RequestLogPageService, Session, Utils
  ) {
    this._state = $state;
    this._stateParams = $stateParams;
    this._Dialog = Dialog;
    this._Toast = Toast;
    this._IncidentDialog = IncidentDialog;
    this._IncidentPageService = IncidentPageService;
    this._RequestLogPageService = RequestLogPageService;
    this._Session = Session;
    this._Utils = Utils;
  }

  $onInit () {
    this.showLoader = false;
    this.days = [];
    this.dateString = '';
    this.selectedTab = 'accepted';
    this.hasRequests = { accepted: false, declined: false };
    this._incidentRelationData = {};
    this.date = this._stateParams.fromDate ? moment(this._stateParams.fromDate, 'YYYY-MM-DD') : moment();

    this.tabs = [
      { id: 'accepted', name: 'Accepted' },
      { id: 'declined', name: 'Declined' },
      { id: 'pending', name: 'Pending' },
    ];

    this._loadWeek();

    if (this.hasPermissionToCreateIncident()) {
      this._IncidentPageService.getRelationData().then(relationData => {
        Object.assign(this._incidentRelationData, relationData);
      });
    }

    if (this._stateParams.showDialog) {
      this._showDialog();
    }
  }

  hasPermissionToCreateIncident () {
    const user = this._Session.user();
    if (!user || !user.group) return false;
    return [
      'system-administrators',
      'medical-staff',
      'medical-staff-prescribers',
      'ops-admins',
      'pilots',
      'call-handlers',
    ].indexOf(user.group.slug) > -1;
  }

  currentWeek () {
    this.date = moment();
    this._loadWeek();
  }

  nextWeek () {
    this.date.add(1, 'w');
    this._loadWeek();
  }

  previousWeek () {
    this.date.subtract(1, 'w');
    this._loadWeek();
  }

  selectTab (tab) {
    if (this.tabs.map(item => item.id).indexOf(tab.id) === -1) {
      throw new Error('Tab name must be "accepted" or "declined".');
    }
    this.selectedTab = tab.id;
  }

  showIncidentDialog ({$event, locals}) {
    const dialogParams = {
      $event: $event,
      relationData: this._incidentRelationData,
      locals: locals,
    };

    this._IncidentDialog.show(dialogParams).then(incident => {
      this._state.go('incident', { id: incident.id });
      this._Toast.showSimple('New referral created.');
    });
  }

  _showDialog () {
    let locals = {};
    if (this._stateParams.location) {
      locals.location = this._stateParams.location;
    };
    this.showIncidentDialog({ locals: locals });
  }

  _loadWeek () {
    this.hasRequests = { accepted: false, declined: false };
    const range = this._RequestLogPageService.getWeekRange(this.date);
    this._state.transitionTo(this._state.current.name, {
      fromDate: range.start.format('YYYY-MM-DD'),
    }, { notify: false });
    this.days = this._RequestLogPageService.getDays(range);
    this.dateString = this._Utils.getDateRangeString(range.start, range.end);

    this.showLoader = true;
    this._RequestLogPageService.getIncidentsForDateRange(
      range.start.format('YYYY-MM-DD'),
      range.end.format('YYYY-MM-DD')
    ).then(items => {
      items.forEach(this._handleIncident.bind(this));
    }).finally(() => {
      this.showLoader = false;
    });
  }

  _handleIncident (incident) {
    this.days.some(day => {
      if (incident.date.diff(day, 'days') === 0) {
        incident.outcome = this._IncidentPageService.getIncidentOutcome(incident);
        day.incidents[incident.status].push(incident);
        if (!this.hasRequests[incident.status]) {
          this.hasRequests[incident.status] = true;
        }
        return true;
      }
      return false;
    });
  }
}

export default {
  controller: RequestLogController,
  templateUrl: 'pages/requestLog/components/request-log-page/request-log-page.tpl.html',
};
