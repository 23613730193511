import angular from 'angular';
import resourceItem from './components/resourceItem';
import resourceItemList from './components/resourceItemList';

const resourceModule = angular
  .module('gnaas.common.resource', [])
  .component('resourceItem', resourceItem)
  .component('resourceItemList', resourceItemList);

export default resourceModule.name;
