function isProtectedState (stateName) {
  return ['', 'login', 'passwordReset'].indexOf(stateName) === -1;
}

function stateChangeHandler ($state, Session) {
  'ngInject';

  return function (e, to) {
    if (Session.exists() && !isProtectedState(to.name) || !Session.exists() && isProtectedState(to.name)) {
      e.preventDefault();
    }

    if (Session.exists() && Session.user().group.slug === 'charity-staff' && $state.current.name !== 'calendar') {
      $state.go('calendar');
    }
  };
}

export function stateChangeHandlerWithToken ($injector, $rootScope, $state, AuthResource, IdleCheck, Session) {
  'ngInject';

  return function (e, to, toParams) {
    e.preventDefault();
    $rootScope.$on('$stateChangeStart', $injector.invoke(stateChangeHandler));

    AuthResource
      .getUser()
      .then(data => {
        Session.start(data);
        IdleCheck.start();

        if (data.should_change_password) {
          $state.go('changePassword');
          return;
        }

        if (Session.user().group.slug === 'charity-staff') {
          $state.go('calendar');
          return;
        }
        const next = isProtectedState(to.name) ? to.name : 'dashboard';
        $state.go(next, toParams);
      })
      .catch(() => {
        const next = Session.exists() && isProtectedState(to.name) ? to.name : 'login';
        const params = (next === 'login' && to.name !== next) ? { previousState: { ...to, params: toParams } } : {};
        $state.go(next, params);
      });
  };
}

export function stateChangeHanlerWithoutToken ($injector, $rootScope, $state) {
  'ngInject';

  return function (e, to, toParams) {
    $rootScope.$on('$stateChangeStart', $injector.invoke(stateChangeHandler));

    if (isProtectedState(to.name)) {
      e.preventDefault();
      $state.go('login', { previousState: { ...to, params: toParams } });
    }
  };
}
