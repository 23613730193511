export default function PasswordValidator () {
  return {
    replace: false,
    restrict: 'A',
    require: '?ngModel',
    link: function ($scope, $element, $attrs, ngModel) {
      if (!$attrs.passwordValidator) {
        return;
      }

      let rules = [
        /[A-Z]+/,  // uppercase
        /[a-z]+/,  // lowercase
        /[0-9]+/,  // numeric
        /[^a-zA-Z\d\s]+/,  // non-alphanumeri (symbol)
      ];

      ngModel.$validators.password = function (modelValue, viewValue) {
        let validRules = 0;
        rules.forEach(function (rule) {
          if (rule.test(viewValue)) {
            validRules++;
          }
        });
        return validRules > 2;
      };
    },
  };
}
