import angular from 'angular';
import uiRouter from 'angular-ui-router';
import basesModule from './bases/module';
import categoryModule from './categories/module';
import consumablesModules from './consumables/module';
import devicesModule from './devices/module';
import equipmentLocationModule from './equipment-locations/module';
import flaggedIncidentsModule from './flagged-incidents/module';
import groupModule from './groups/module';
import hospitalModule from './hospitals/module';
import drugModule from './drugs/module';
import reportsModule from './reports/module';
import vehicleModule from './vehicles/module';
import vehicleCallsignModule from './vehicleCallsigns/module';

const moduleConfig = function ($stateProvider) {
  'ngInject';
  $stateProvider.state('admin', {
    abstract: true,
    url: '/admin',
    template: '<div ui-view layout="column" flex></div>',
  });
};

const module = angular
  .module('gnaas.pages.admin', [
    uiRouter,
    basesModule,
    categoryModule,
    consumablesModules,
    devicesModule,
    equipmentLocationModule,
    flaggedIncidentsModule,
    groupModule,
    hospitalModule,
    drugModule,
    reportsModule,
    vehicleModule,
    vehicleCallsignModule,
  ])
  .config(moduleConfig);

export default module.name;
