import { copy } from 'angular';
import { SHIFT_EVENTS } from 'core/shifts/module.constants';

class ShiftSidebarController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $rootScope,
    $mdSidenav,
    ShiftDialog,
    ShiftCrewDialog,
    ShiftResource
  ) {
    this.$rootScope = $rootScope;
    this.$mdSidenav = $mdSidenav;
    this.ShiftDialog = ShiftDialog;
    this.ShiftCrewDialog = ShiftCrewDialog;
    this.ShiftResource = ShiftResource;
  }

  close () {
    this.$mdSidenav('shift-sidebar-item').close();
  };

  showShiftDialog ($event) {
    const dialogParams = {
      $event,
      item: copy(this.shift),
      editing: true,
      locals: {
        bases: this.bases,
        vehicleCallsigns: this.vehicleCallsigns,
      },
    };

    this.ShiftDialog
      .show(dialogParams)
      .then(shift => {
        this.shift = shift;
        this.$rootScope.$emit(SHIFT_EVENTS.updated, shift);
      })
      .catch(shiftId => {
        if (shiftId) {
          this.$rootScope.$emit(SHIFT_EVENTS.deleted, this.shift);
          this.close();
        }
      });
  }

  showShiftCrewDialog ($event) {
    this.ShiftCrewDialog.show({
      $event,
      locals: {
        shift: this.shift,
        crewMembers: this.crewMembers,
      },
    });
  }
}

export default {
  controller: ShiftSidebarController,
  templateUrl: 'core/shifts/components/shift-sidebar-item/shift-sidebar-item.tpl.html',
  bindings: {
    shift: '<',
    bases: '<',
    vehicleCallsigns: '<',
    crewMembers: '<',
  },
};
