import ResourceDialog from 'common/resource/services/resourceDialog';
import IllnessDialogController from './illness-dialog.controller';

export default class IllnessDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(IllnessDialogController);
    this.setTemplateUrl('core/prf/services/illness-dialog/illness-dialog.tpl.html');
  }
}
