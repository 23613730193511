import angular from 'angular';
import uiRouter from 'angular-ui-router';
import DashboardPage from './components/dashboard-page';
import DashboardPageService from './services/dashboard-page.service';

const moduleConfig = function ($stateProvider) {
  'ngInject';
  $stateProvider.state('dashboard', {
    url: '/dashboard',
    template: '<dashboard-page flex layout="column"></dashboard-page>',
    data: {
      title: 'Dashboard',
    },
  });
};

const module = angular
  .module('gnaas.pages.dashboard', [
    uiRouter,
  ])
  .config(moduleConfig)
  .component('dashboardPage', DashboardPage)
  .service('DashboardPageService', DashboardPageService);

export default module.name;
