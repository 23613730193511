import { format as formatCrewMember } from 'pages/crewMembers/services/crewMemberResource';
import formatPrescription from './prescribed-drug-formatter.service';
import { toDateTimeObject } from 'common/utils/services/dateTime';

export default function format (item) {
  const dateTime = toDateTimeObject(item.time);
  return {
    id: item.id,
    dose: parseFloat(item.dose),
    rate: parseFloat(item.rate),
    time: dateTime,
    date: dateTime,
    prescription: item.prescription ? formatPrescription(item.prescription.data) : null,
    administered_by: formatCrewMember(item.administeredBy.data),
  };
}
