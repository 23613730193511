import { copy } from 'angular';
import moment from 'moment';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';
import { DATE_FORMAT } from 'common/resource/module.constants';
import { TYPES } from 'core/deployments/services/deployment-dialog/deployment-dialog.controller';

const CREW_LIST_DIALOG_TPL = `<md-dialog class="o-dialog o-dialog--small">
  <md-dialog-content>
    <form-checkbox-list
      flex
      fe-list-title="Crew Members"
      fe-list="$ctrl.crewMembers"
      fe-selected-items="$ctrl.users"
      fe-form-submitted="$ctrl.form.$submitted"
      fe-hide-toggle-all-button="true"
      fe-hide-list-toggle="true">
    </form-checkbox-list>
  </md-dialog-content>
  <md-dialog-actions>
      <md-button class="md-raised" ng-click="$ctrl.close()">Cancel</md-button>
      <md-button class="md-raised md-primary" ng-click="$ctrl.submit()">Ok</md-button>
  </md-dialog-actions>
</md-dialog>`;

function getQuery (date) {
  return { date: moment(date).format(DATE_FORMAT), include: 'profile.image,profile.profession' };
}

export default class ShiftCrewDialogController extends ResourceDialogController {
  init () {
    this.ShiftResource = this.$injector.get('ShiftResource');
    this.closeOnSubmit = !!this.closeOnSubmit;
    this.types = TYPES;

    this.item = {
      date: this.date ? moment(this.date).toDate() : new Date(),
      users: [],
    };
    this.getShiftCrew(this.item.date);
  }

  submit (formData) {
    if (!this.form) {
      throw new Error('Form not found.');
    }

    if (this.form.$invalid) {
      this.Toast.showSimple(this.validationErrorMessage);
      return;
    }

    this.ShiftResource
      .setUsers(this.shift.id, copy(formData), getQuery(this.item.date))
      .then(data => {
        this.Toast.showSimple('Shift crew set.');
        this.item.users = data;
        this.setOriginalUserIds();
        if (this.closeOnSubmit) {
          this.Dialog.hide(this.item);
        }
      })
      .catch(() => {
        this.Toast.showSimple('Error setting shift crew.');
      });
  }

  cancel () {
    if (!this.compareOriginalUserIds()) {
      return this.Dialog
        .confirm('You have unsaved shift crew changes, are you sure you wish to continue without saving?')
        .then(() => (this.Dialog.cancel()));
    }
    this.Dialog.cancel();
  }

  showCrewList ($event) {
    this.Dialog
      .show({
        targetEvent: $event,
        controller: function () {
          'ngInject';
          this.close = () => this.Dialog.cancel();
          this.submit = () => this.Dialog.hide(this.users);
        },
        locals: {
          Dialog: this.Dialog,
          crewMembers: copy(this.crewMembers),
          users: copy(this.item.users),
        },
        multiple: true,
        template: CREW_LIST_DIALOG_TPL,
      }).then(users => {
        this.item.users = users;
      });
  }

  getShiftCrew (date) {
    this.ShiftResource
      .getUsers(this.shift.id, getQuery(date))
      .then(data => {
        this.item.users = data;
        this.setOriginalUserIds();
      })
      .catch(() => {
        this.Toast.showSimple('Error retrieving shift crew.');
        this.item.users = [];
        this.setOriginalUserIds();
      });
  }

  onDateChange (date) {
    if (!this.compareOriginalUserIds()) {
      this.item.date = new Date(this.item.date);
      return this.Dialog
        .confirm('You have unsaved shift crew changes, are you sure you wish to continue without saving?')
        .then(() => {
          this.item.date = date;
          this.getShiftCrew(date);
        });
    }
    this.getShiftCrew(date);
  }

  setOriginalUserIds () {
    this.originalUserIds = this.item.users.map(i => i.id);
  }

  compareOriginalUserIds () {
    const userIds = this.item.users.map(i => i.id);
    if (this.originalUserIds.length !== userIds.length) return false;
    return this.originalUserIds.every(id => userIds.includes(id));
  }
}
