/* global Blob, URL */
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class DrugOrderFormDialogDialogController extends ResourceDialogController {
  init () {
    super.init();
    this.StockLocationResource = this.$injector.get('StockLocationResource');
    this.DrugStockResource = this.$injector.get('DrugStockResource');

    this.selectedStockLocations = [];
    this.StockLocationResource.index().then(items => { this.stockLocations = items; });

    this.item = { stock_locations: [] };
  }

  submit (data) {
    if (this.form.$invalid || this.item.stock_locations.length === 0) return;

    this.DrugStockResource.generateDrugOrderForms(this._parseData(data)).then(pdf => {
      const file = new Blob([pdf], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.Dialog.hide();
    });
  }

  _parseData (data) {
    const d = Object.assign({}, data);
    d.stock_location_ids = d.stock_locations.map(item => item.id);
    delete d.stock_locations;
    return d;
  }
}
