class HandoverNotesTabController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (HandoverDialog, Toast) {
    this.HandoverDialog = HandoverDialog;
    this.Toast = Toast;
  }

  showHandoverNotesDialog ($event, prf) {
    this.HandoverDialog
      .show({
        $event,
        item: prf,
        locals: {
          editing: !!this.prf.handover,
        },
      })
      .then(item => {
        this.prf = { ...this.prf, ...item };
        this.Toast.showSimple('Handover updated.');

        if (typeof this.onPrfUpdate === 'function') {
          this.onPrfUpdate({ prf: this.prf });
        }
      });
  }
}

export default {
  controller: HandoverNotesTabController,
  templateUrl: 'pages/requestLog/components/handover-notes-tab/handover-notes-tab.tpl.html',
  bindings: {
    isEditing: '<',
    prf: '<',
    onPrfUpdate: '&',
  },
};
