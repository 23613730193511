import moment from 'moment';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';
import { toDateTimeString } from 'common/utils/services/dateTime';

export default class TimestampDialogController extends ResourceDialogController {
  init () {
    super.init();

    if (!this.item) {
      this.item = {
        date: new Date(),
        time: moment().startOf('minute').toDate(),
      };
    }

    this.validateAgainst = this.locals.validateAgainst || [];
    this.validationErrorMessage = this.locals.validationErrorMessage || 'Timestamp invalid.';
  }

  submit (timestamp) {
    if (!this.form) {
      throw new Error('Form not found.');
    }

    if (this.form.$invalid) {
      return;
    }

    // Validate against provided timestamps
    const timestampStr = toDateTimeString(timestamp.date, timestamp.time);
    if (~this.validateAgainst.indexOf(timestampStr)) {
      this.Dialog.alert(this.validationErrorMessage);
      return;
    }

    return this.Dialog.hide(timestamp);
  };
}
