import uuid from 'node-uuid';
import { copy } from 'angular';

const TPL = 'core/equipment-locations/services/equipment-location-dialog/compartment-dialog.tpl.html';

let DIALOGS_OPEN = 0;

export default class CompartmentDialogController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog) {
    this.Dialog = Dialog;
    this.ID = uuid.v4();
    this.item = { compartments: [], items: [], ...this.item };

    if (this.locals.parent) {
      this.item.parent = this.locals.parent;
      this.breadcrumbs = this._getParentBreadcrumbs(this.locals.parent).join(' &gt; ');
    }
  }

  get dialogsOpen () {
    return DIALOGS_OPEN;
  }

  _getParentBreadcrumbs (parent) {
    let crumbs = [];
    crumbs.push(parent.name);
    if (parent.parent) {
      crumbs = [...this._getParentBreadcrumbs(parent.parent), ...crumbs];
    }
    return crumbs;
  }

  addItem () {
    this.item.items = [...this.item.items, { id: uuid.v4(), quantity: null }];
  }

  removeItem (item) {
    this.item.items = this.item.items.filter(i => i.id !== item.id);
  }

  submit (formData) {
    if (this.form.$invalid) return;
    if (!formData.id) formData.id = this.ID;
    if (this.locals.parent && !this.item.parent_id) this.item.parent_id = this.locals.parent.id;
    this.Dialog.hide(formData);
  }

  cancel () {
    this.Dialog.cancel();
  }

  delete (id) {
    this.Dialog
      .confirm('Are you sure you wish to remove this compartment?')
      .then(() => { this.Dialog.cancel(id); });
  }

  showCompartmentDialog ($event, item = null) {
    if (DIALOGS_OPEN === 2) return;
    DIALOGS_OPEN++;

    this.constructor
      .show({Dialog: this.Dialog, relationData: this.locals.relationData, parent: this.item, $event, item})
      .then(data => {
        this.item.compartments = !item
          ? [...this.item.compartments, data]
          : this.item.compartments.map(i => i.id === data.id ? data : i);
      })
      .catch(id => {
        if (id) this.item.compartments = this.item.compartments.filter(c => c.id !== id);
      })
      .finally(() => { DIALOGS_OPEN--; });
  }

  static show ({ Dialog, relationData, $event, parent = null, item = null }) {
    if (!Dialog || !relationData) throw new Error('Dialog and relationData missing');
    const dialogParams = {
      controller: CompartmentDialogController,
      locals: {
        editing: typeof item !== 'undefined' && item != null,
        item: copy(item),
        parent,
        relationData,
      },
      targetEvent: $event,
      templateUrl: TPL,
      multiple: true,
    };
    return Dialog.show(dialogParams);
  }
}
