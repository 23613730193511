export default function ($stateProvider) {
  'ngInject';

  $stateProvider
    .state('admin.consumables', {
      abstract: true,
      url: '/consumables',
      template: '<div ui-view layout="column" flex></div>',
    })
    .state('admin.consumables.list', {
      url: '/list',
      controllerAs: '$ctrl',
      controller: 'ConsumableListController',
      templateUrl: 'common/resource/templates/resource.tpl.html',
      data: {
        title: 'Consumable List',
      },
    })
    .state('admin.consumables.stock', {
      url: '/stock',
      controllerAs: '$ctrl',
      controller: 'ConsumableStockController',
      templateUrl: 'pages/admin/consumables/templates/consumable-stock.tpl.html',
      data: {
        title: 'Consumable Stock',
      },
    });
};
