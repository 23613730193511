function reduceCompartments (acc, cur) {
  if (cur.compartments) acc = acc.concat(cur.compartments.reduce(reduceCompartments, []));
  return acc.concat(cur.items);
}

class EquipmentLocationSingleController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($state, $stateParams, EquipmentLocationDialog, EquipmentLocationResource) {
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.EquipmentLocationDialog = EquipmentLocationDialog;
    this.EquipmentLocationResource = EquipmentLocationResource;
  }

  $onInit () {
    this.EquipmentLocationResource
      .show(this.$stateParams.id, { include: 'compartments.items,compartments.compartments' })
      .then(item => { this.item = this.formatItem(item); });
  }

  showCreateDialog ($event) {
    this.EquipmentLocationDialog
      .show({ $event, item: this.item })
      .then(data => { this.item = this.formatItem(data); })
      .catch(id => { if (id) this.$state.go('admin.equipmentLocations.list'); });
  }

  formatItem (item) {
    item.compartments
      .reduce(reduceCompartments, [])
      .forEach(i => {
        i.itemTypeString = i.item_type.charAt(0).toUpperCase() + i.item_type.slice(1);
        i.quantityString = i.quantity || '-';
      });
    return item;
  }
}

const componentName = 'equipment-location-single';
export default {
  controller: EquipmentLocationSingleController,
  templateUrl: `pages/admin/equipment-locations/components/${componentName}/${componentName}.tpl.html`,
};
