import ResourceDialogController from 'common/resource/controllers/resourceDialogController';
import CompartmentDialogController from './compartment-dialog.controller';
import uuid from 'node-uuid';

export default class EquipmentLocationDialog extends ResourceDialogController {
  init () {
    super.init();
    this.ID = uuid.v4();

    if (!this.item) this.item = { compartments: [] };

    this.EquipmentLocationResource = this.$injector.get('EquipmentLocationResource');
    this.ConsumableResource = this.$injector.get('ConsumableResource');
    this.DrugResource = this.$injector.get('DrugResource');

    this.itemTypes = [
      { name: 'Consumable', value: 'consumable' },
      { name: 'Drug', value: 'drug' },
    ];
    this.itemTypeMap = { consumable: [], drug: [] };

    this.ConsumableResource.index().then(items => { this.itemTypeMap.consumable = [...items]; });
    this.DrugResource.index().then(items => { this.itemTypeMap.drug = [...items]; });
  }

  showCompartmentDialog ($event, item) {
    const relationData = { itemTypes: this.itemTypes, itemTypeMap: this.itemTypeMap };
    CompartmentDialogController
      .show({ Dialog: this.Dialog, relationData, $event, item })
      .then(data => {
        this.item.compartments = !item
          ? [...this.item.compartments, data]
          : this.item.compartments.map(i => i.id === data.id ? data : i);
      })
      .catch(id => {
        if (id) this.item.compartments = this.item.compartments.filter(c => c.id !== id);
      });
  }

  create (formData) {
    formData.id = this.ID;
    this.EquipmentLocationResource
      .create(formData)
      .then(() => {
        this.Toast.showSimple('Equipment location created.');
        this.Dialog.hide(formData);
      })
      .catch(() => {
        this.Toast.showSimple('Error while creating equipment location.');
      });
  }

  update (formData) {
    this.EquipmentLocationResource
      .update(formData.id, formData, { include: 'compartments.items,compartments.compartments' })
      .then(data => {
        this.Toast.showSimple('Equipment location updated.');
        this.Dialog.hide(data);
      })
      .catch(() => {
        this.Toast.showSimple('Error while updating equipment location.');
      });
  }

  delete (id) {
    this.Dialog.confirm().then(() => {
      this.EquipmentLocationResource
        .destroy(id)
        .then(() => {
          this.Toast.showSimple('Equipment location deleted.');
          this.Dialog.cancel(id);
        })
        .catch(() => {
          this.Toast.showSimple('Error while deleting equipment location.');
        });
    });
  };
}
