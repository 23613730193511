import angular from 'angular';
import uiRouter from 'angular-ui-router';
import HospitalResourceController from './controllers/hospitalResourceController';
import HospitalResource from './services/hospitalResource';

const moduleConfig = function ($stateProvider) {
  'ngInject';
  $stateProvider.state('admin.hospitals', {
    url: '/hospitals',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    controller: HospitalResourceController,
    controllerAs: '$ctrl',
    data: {
      title: 'Hospitals',
    },
  });
};

const module = angular
  .module('gnaas.pages.admin.hospitals', [
    uiRouter,
  ])
  .config(moduleConfig)
  .service('HospitalResource', HospitalResource);

export default module.name;
