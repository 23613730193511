function getVisiblePages (totalPageCount, currentPageNumber, pageButtonCount) {
  const pages = [...Array(totalPageCount + 1).keys()];
  pages.shift();

  if (totalPageCount <= pageButtonCount) {
    return [...pages];
  }

  // If 1 or 2 - return first 5
  if (currentPageNumber === 1 || currentPageNumber === 2) {
    return [...pages].slice(0, pageButtonCount);
  }

  // If last or one before last - return last 5
  if (currentPageNumber === totalPageCount || currentPageNumber === totalPageCount - 1) {
    return [...pages].slice(-5);
  }

  const i = pages.indexOf(currentPageNumber);
  return [...pages].slice(i - 2, i + 3);
}

class PaginationController {
  $onInit () {
    if (!this.pages) this.pages = [];
  }

  isActivePage (page) {
    return page === this.currentPage;
  }

  $onChanges (changes) {
    if (changes.currentPage && changes.currentPage.currentValue != null) {
      this.currentPage = parseInt(changes.currentPage.currentValue, 10);
    }

    if (changes.totalPages && changes.totalPages.currentValue != null) {
      this.totalPages = parseInt(changes.totalPages.currentValue, 10);
    }

    if (changes.totalPages || changes.currentPage) {
      this.pages = getVisiblePages(this.totalPages, this.currentPage, 5);
    }
  }
}

const PaginationComponent = {
  bindings: {
    onNextClick: '&bOnNextClick',
    onPreviousClick: '&bOnPreviousClick',
    onPageClick: '&bOnPageClick',
    currentPage: '@bCurrentPage',
    totalPages: '@bTotalPages',
    pageButtonCount: '@bPageButtonCount',
  },
  controller: PaginationController,
  templateUrl: 'common/lists/components/pagination/pagination.tpl.html',
};

export default PaginationComponent;
