export default function ModuleConfig ($stateProvider) {
  'ngInject';

  $stateProvider.state('reports', {
    url: '/reports',
    template: '<reports-page flex layout="column"></reports-page>',
    data: {
      title: 'Reports',
    },
  });
}
