import RestResource from 'common/resource/services/restResource';
import { formatCompartmentItem } from 'core/equipment-locations/resources/equipment-location.resource';

export function format (item) {
  const formatted = {
    id: item.id,
    name: item.name,
    measurement_type: item.measurement_type,
    concentration: item.concentration,
    dose_per_unit: parseFloat(item.dose_per_unit),
    is_controlled_drug: !!item.is_controlled_drug,
    exclude_from_stock: !!item.exclude_from_stock,
    form: item.form,
    unit_volume: item.unit_volume,
    comments: item.comments,
    is_non_formulary: !!item.is_non_formulary,
    min_rate: item.min_rate != null ? parseFloat(item.min_rate) : null,
    max_rate: item.max_rate != null ? parseFloat(item.max_rate) : null,
  };

  if (item.routes) formatted.routes = item.routes.data;

  if (item.prf) formatted.prf = item.prf.data;

  if (item.stockLocationProperties) {
    formatted.stock_location_properties = item.stockLocationProperties.data.map(properties => {
      properties.supplier = properties.supplier.data;
      properties.stock_location = properties.stockLocation.data;
      delete properties.stockLocation;
      return properties;
    });
  }

  if (item.equipmentLocationCompartmentItems) {
    formatted.equipment_location_compartment_items =
      item.equipmentLocationCompartmentItems.data.map(formatCompartmentItem);
  }

  if (item.productCodes) {
    formatted.product_codes = item.productCodes.data.map(pc => {
      if (pc.supplier) pc.supplier = pc.supplier.data;
      return pc;
    });
  }

  return formatted;
}

export function parse (item) {
  const parsed = {
    id: item.id,
    name: item.name,
    measurement_type: item.measurement_type,
    concentration: item.concentration || null,
    dose_per_unit: item.dose_per_unit,
    is_controlled_drug: item.is_controlled_drug ? 1 : 0,
    exclude_from_stock: item.exclude_from_stock ? 1 : 0,
    form: item.form || null,
    unit_volume: item.unit_volume || null,
    comments: item.comments || null,
    routes: item.routes ? item.routes.map(i => i.id) : [],
    is_non_formulary: item.is_non_formulary ? 1 : 0,
    min_rate: item.min_rate != null ? item.min_rate : null,
    max_rate: item.max_rate,
  };

  if (item.is_non_formulary) {
    parsed.patient_report_form_id = item.patient_report_form.id;
  }

  if (item.stock_location_properties) {
    parsed.stock_location_properties = item.stock_location_properties.map(prop => {
      const copy = Object.assign({}, prop);
      copy.stock_location_id = copy.stock_location.id;
      copy.supplier_id = copy.supplier.id;
      delete copy.stock_location;
      delete copy.supplier;
      return copy;
    });
  }

  if (item.product_codes) {
    parsed.product_codes = item.product_codes.map(pc => ({
      id: pc.id,
      product_code: pc.product_code,
      supplier_id: pc.supplier.id,
    }));
  }

  return parsed;
}

export default class DrugResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'drugs';
  }

  getMeasurementTypes () {
    const url = this.apiUrl + '/categories/drug-measurement-types';
    return this._http.get(url).then(response => response.data.data);
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }

  approve (id) {
    let url = `${this.apiUrl}${this.resourcePath}/${id}/approve`;
    return this._http.post(url).then(response => {
      return response.data.data ? this._formatData(response.data.data) : null;
    });
  }

  reject (id, data) {
    let url = `${this.apiUrl}${this.resourcePath}/${id}/reject`;
    return this._http.post(url, { drug_id: data.id }).then(response => {
      return response.data.data ? this._formatData(response.data.data) : null;
    });
  }
}
