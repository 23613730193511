import RestResource from 'common/resource/services/restResource';

export function format (item) {
  return {
    id: item.id,
    clinical_notes: item.clinical_notes,
    care_handed_over_to_doctor: item.care_handed_over_to_doctor,
    care_handed_over_to_nurse: item.care_handed_over_to_nurse,
    controlled_drugs_disposed_at_receiving_hospital: !!item.controlled_drugs_disposed_at_receiving_hospital,
    infusions_continued_by_receiving_team: !!item.infusions_continued_by_receiving_team,
  };
}

export function parse (item) {
  return {
    id: item.id,
    clinical_notes: item.clinical_notes || null,
    care_handed_over_to_doctor: item.care_handed_over_to_doctor || null,
    care_handed_over_to_nurse: item.care_handed_over_to_nurse || null,
    controlled_drugs_disposed_at_receiving_hospital: item.controlled_drugs_disposed_at_receiving_hospital ? 1 : 0,
    infusions_continued_by_receiving_team: item.infusions_continued_by_receiving_team ? 1 : 0,
  };
}

export default class HandoverResource extends RestResource {
  init () {
    this.resourcePath = 'handovers';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
