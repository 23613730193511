export default function ($stateProvider) {
  'ngInject';

  $stateProvider
    .state('admin.equipmentLocations', {
      abstract: true,
      url: '/equipment-locations',
      template: '<div ui-view layout="column" flex></div>',
    })
    .state('admin.equipmentLocations.list', {
      url: '/',
      controllerAs: '$ctrl',
      template: '<equipment-location-list-page layout="column" flex/>',
      data: {
        title: 'Equipment Locations',
      },
    })
    .state('admin.equipmentLocations.show', {
      url: '/{id}',
      controllerAs: '$ctrl',
      template: '<equipment-location-single-page layout="column" flex/>',
      data: {
        title: 'Equipment Location',
      },
    });
};
