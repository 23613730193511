import moment from 'moment';
import RestResource from 'common/resource/services/restResource';
import { format as formatDeployment } from 'core/deployments/resources/deployment.resource';
import { format as formatFlaggedIncident } from 'core/incidents/resources/flagged-incident.resource';
import { format as formatBase } from 'pages/admin/bases/services/baseResource';
import { format as formatHospital } from 'pages/admin/hospitals/services/hospitalResource';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'common/resource/module.constants';

export function parse (item) {
  let parsed = {
    id: item.id,
    incident_number: item.incident_number,
    date: moment(item.date).format(DATE_FORMAT),
    status: item.status,
    comment: item.comment || null,
    referrer_name: item.referrer_name,
    referrer_contact_no: item.referrer_contact_no,
    patients_named_consultant: item.patients_named_consultant || null,
    ambulance_reference_number: item.ambulance_reference_number || null,
  };

  parsed.times = {};
  if (item.times.call.time) {
    parsed.times.call = moment(item.times.call.time).format(DATE_TIME_FORMAT);
  }
  if (item.times.accepted.time) {
    parsed.times.accepted = moment(item.times.accepted.time).format(DATE_TIME_FORMAT);
  }
  if (item.times.refused.time) {
    parsed.times.refused = moment(item.times.refused.time).format(DATE_TIME_FORMAT);
  }

  if (item.deployment_reason) {
    parsed.deployment_reason_id = item.deployment_reason.id;
  }

  if (item.service_area) {
    parsed.service_area_id = item.service_area.id;
  }

  if (item.ambulance_service) {
    parsed.ambulance_service_id = item.ambulance_service.id;
  }

  if (item.refusal_reason) {
    parsed.refusal_reason_id = item.refusal_reason.id;
  }

  if (item.base) {
    parsed.base_id = item.base.id;
  }

  if (item.referring_hospital) {
    parsed.referring_hospital_id = item.referring_hospital.id;
  }

  if (item.referrer_grade) {
    parsed.referrer_grade_id = item.referrer_grade.id;
  }

  if (item.referrer_ward) {
    parsed.referrer_ward_id = item.referrer_ward.id;
  }

  if (item.referrer_specialty) {
    parsed.referrer_specialty_id = item.referrer_specialty.id;
  }

  return parsed;
}

export function format (item) {
  let formatted = {
    id: item.id,
    incident_number: item.incident_number,
    date: item.hasOwnProperty('date') ? moment(item.date, DATE_FORMAT) : null,
    status: item.hasOwnProperty('status') ? item.status : null,
    comment: item.hasOwnProperty('comment') ? item.comment : null,
    referrer_name: item.referrer_name,
    referrer_contact_no: item.referrer_contact_no,
    patients_named_consultant: item.patients_named_consultant,
    ambulance_reference_number: item.ambulance_reference_number,
  };

  if (item.location) {
    formatted.location = {
      lat: item.location.lat,
      lng: item.location.lng,
    };
  }

  if (item.times) {
    formatted.times = {};
    if (item.times.call) { formatted.times.call = item.times.call; }
    if (item.times.accepted) { formatted.times.accepted = item.times.accepted; }
    if (item.times.refused) { formatted.times.refused = item.times.refused; }
    Object.keys(formatted.times).forEach(function (key) {
      formatted.times[key].time = moment(formatted.times[key].time, DATE_TIME_FORMAT).startOf('minute');
    });
  }

  if (item.deploymentReason) {
    formatted.deployment_reason = item.deploymentReason.data;
  }

  if (item.ambulanceService) {
    formatted.ambulance_service = item.ambulanceService.data;
  }

  if (item.refusalReason) {
    formatted.refusal_reason = item.refusalReason.data;
  }

  if (item.serviceArea) {
    formatted.service_area = item.serviceArea.data;
  }

  if (item.base) {
    formatted.base = formatBase(item.base.data);
  }

  if (item.referringHospital) {
    formatted.referring_hospital = formatHospital(item.referringHospital.data);
  }

  if (item.referrerGrade) {
    formatted.referrer_grade = item.referrerGrade.data;
  }

  if (item.referrerWard) {
    formatted.referrer_ward = item.referrerWard.data;
  }

  if (item.referrerSpecialty) {
    formatted.referrer_specialty = item.referrerSpecialty.data;
  }

  if (item.deployments) {
    formatted.deployments = item.deployments.data.map(formatDeployment);
  }

  if (item.flaggedIncidents) {
    formatted.flagged_incidents = item.flaggedIncidents.data.map(formatFlaggedIncident);
  }

  return formatted;
}

export default class IncidentResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'incidents';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
