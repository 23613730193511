import { element } from 'angular';

const defaultParams = {
  bindToController: true,
  controller: function () {},
  controllerAs: '$ctrl',
  clickOutsideToClose: false,
  escapeToClose: false,
  fullscreen: false,
  parent: element(document.body),
  multiple: false,
};

export default class Dialog {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($mdDialog) {
    this._mdDialog = $mdDialog;
  }

  /**
   * Show a dialog with given parameters.
   *
   * @param params
   * @returns {*}
   */
  show (params) {
    if (!params) {
      params = {};
    }
    const dialogParams = { ...defaultParams, ...params };
    return this._mdDialog.show(dialogParams);
  }

  /**
   * Show a confirmation dialog.
   *
   * @param text
   * @returns {*}
   */
  confirm (text) {
    text = text || 'Are you sure?';
    const confirmOpts = this._mdDialog.confirm()
      .title('Are you sure?')
      .textContent(text)
      .ariaLabel('Confirmation')
      .ok('Yes')
      .cancel('No');

    const opts = { ...confirmOpts._options, multiple: true };
    return this._mdDialog.show(opts);
  }

  /**
   * Show an alert dialog.
   *
   * @param text
   * @param title
   * @returns {*}
   */
  alert (text, title) {
    text = text || "Something's not right!";
    title = title || 'Alert';
    const alertOpts = this._mdDialog.alert()
      .title(title)
      .textContent(text)
      .ariaLabel('Alert')
      .ok('Ok');

    const opts = { ...alertOpts._options, multiple: true };
    return this._mdDialog.show(opts);
  }

  /**
   * Show a delete prompt dialog.
   *
   * @returns {*}
   */
  promptDelete () {
    const promptOpts = this._mdDialog.prompt()
      .title('Are you sure?')
      .textContent("Please type 'delete' in the text box below to confirm.")
      .placeholder('delete')
      .ariaLabel('delete')
      .required(true)
      .ok('Yes')
      .cancel('No');

    const promptController = function promptController ($mdDialog, $mdConstant, $element, $timeout) {
      'ngInject';

      let ngModel;

      this.$onInit = () => {
        this.abort = () => ($mdDialog.cancel());
        this.keypress = ($event) => {
          if ($event.keyCode === $mdConstant.KEY_CODE.ENTER) this.hide();
        };
        this.hide = () => {
          if (this.result === 'delete') {
            ngModel.$invalid = false;
            $mdDialog.hide();
          } else {
            ngModel.$invalid = true;
          }
        };
      };

      $timeout(() => {
        ngModel = $element.find('input').controller('ngModel');

        ngModel.$validators.matchesDelete = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value === 'delete';
        };
      });
    };

    const opts = { ...promptOpts._options, controller: promptController, multiple: true };
    return this._mdDialog.show(opts);
  }

  /**
   * Close (error) a dialog by ID
   *
   * @param id
   * @returns {*}
   */
  cancel (id) {
    return this._mdDialog.cancel(id);
  }

  /**
   * Hide (success) a dialog by ID
   *
   * @param data
   * @returns {*}
   */
  hide (data) {
    return this._mdDialog.hide(data);
  }
};
