import ResourceDialog from 'common/resource/services/resourceDialog';
import IncidentDialogController from './incident-dialog.controller';

export default class IncidentDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(IncidentDialogController);
    this.setTemplateUrl('core/incidents/services/incident-dialog/incident-dialog.tpl.html');
  }
}
