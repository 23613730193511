import angular from 'angular';
import Utils from './services/utils';
import Dialog from './services/dialog';
import Toast from './services/toast';
import nl2br from './filters/nl2br';
import ucfirst from './filters/ucfirst';
import prettify from './filters/prettify';
import nhsNo from './filters/nhsNo';
import file from './directives/file';
import decimalValidator from './directives/decimalValidator';
import integerValidator from './directives/integerValidator';
import windowResizeVerticalHandler from './directives/windowResizeVerticalHandler';

const module = angular
  .module('gnaas.common.utils', [])
  .service('Utils', Utils)
  .service('Dialog', Dialog)
  .service('Toast', Toast)
  .filter('ucfirst', ucfirst)
  .filter('nl2br', nl2br)
  .filter('prettify', prettify)
  .filter('nhsNo', nhsNo)
  .directive('file', file)
  .directive('decimalValidator', decimalValidator)
  .directive('integerValidator', integerValidator)
  .directive('windowResizeVerticalHandler', windowResizeVerticalHandler);

export default module.name;
