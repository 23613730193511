import angular from 'angular';
import uiRouter from 'angular-ui-router';
import CalendarEventResource from './services/calendarEventResource';
import CalendarAgendaResource from './services/calendarAgendaResource';
import Calendar from './components/calendar';
import CalendarAgenda from './components/calendarAgenda';
import CalendarCell from './components/calendarCell';
import CalendarControls from './components/calendarControls';
import CalendarDay from './components/calendarDay';
import CalendarEvents from './components/calendarEvents';
import CalendarExpiringItems from './components/calendar-expiring-items';

const moduleConfig = function ($stateProvider) {
  'ngInject';
  $stateProvider.state('calendar', {
    url: '/calendar',
    template: '<calendar flex layout="column" window-resize-vertical-handler></calendar>',
    data: {
      title: 'Calendar',
    },
  });
};

const module = angular
  .module('gnaas.pages.calendar', [
    uiRouter,
  ])
  .config(moduleConfig)
  .service('CalendarEventResource', CalendarEventResource)
  .service('CalendarAgendaResource', CalendarAgendaResource)
  .component('calendar', Calendar)
  .component('calendarAgenda', CalendarAgenda)
  .component('calendarCell', CalendarCell)
  .component('calendarControls', CalendarControls)
  .component('calendarDay', CalendarDay)
  .component('calendarEvents', CalendarEvents)
  .component('calendarExpiringItems', CalendarExpiringItems);

export default module.name;
