import TextareaDialogController from './textarea-dialog.controller';

/**
 * @abstract
 */
export default class TextareaDialog {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog) {
    this._Dialog = Dialog;
    this._dialogOptions = {
      controller: TextareaDialogController,
      templateUrl: 'common/services/textarea-dialog/textarea-dialog.tpl.html',
      locals: {},
    };
  }

  show ({$event, value, label, title}) {
    const dialogParams = {
      locals: { value, label, title },
      targetEvent: $event,
    };
    return this._Dialog.show(Object.assign({}, this._dialogOptions, dialogParams));
  }
}
