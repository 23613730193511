import RestResource from 'common/resource/services/restResource';
import { VEHICLE_TYPES_REVERSE } from 'pages/admin/vehicles/module.constants';

export function parse (item) {
  return {
    id: item.id || null,
    registration_number: item.registration_number,
    type: item.type,
  };
}

export function format (item) {
  return {
    id: item.id,
    registration_number: item.registration_number,
    type: item.type,
    typeString: VEHICLE_TYPES_REVERSE[item.type],
  };
}

export default class VehicleResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'vehicles';
  }

  getTypes () {
    let url = this.apiUrl + 'categories/vehicle-types';
    return this._http.get(url).then(response => response.data.data);
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
