export default {
  controller: ['APP_ENV', 'Session', function (APP_ENV, Session) {
    this.isProductionEnvironment = APP_ENV === 'production';
    this.isAuthenticated = function () {
      return Session.exists() && !!Session.user() && !Session.user().should_change_password;
    };
  }],
  template: `
    <div ng-if="!$ctrl.isProductionEnvironment" class="u-login-warning">
        TRAINING VERSION - do not use for real patients/missions.
    </div>

    <div layout="row" class="app" flex>
        <md-sidenav ng-if="$ctrl.isAuthenticated()"
                    class="md-sidenav-left app-sidenav"
                    md-theme="dark"
                    hide-print
                    md-component-id="sidenav-main"
                    md-is-locked-open="$mdMedia('gt-md')"
                    md-disable-backdrop
                    md-whiteframe="2">
            <sidenav-menu layout="column"></sidenav-menu>
        </md-sidenav>

        <div flex role="main" tabindex="-1" layout="column">
              <topbar ng-if="$ctrl.isAuthenticated()"></topbar>
              <div ui-view layout="column" flex></div>
        </div>
    </div>`,
};
