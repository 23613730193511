import {
  stateChangeHandlerWithToken,
  stateChangeHanlerWithoutToken,
} from 'common/auth/services/stateChangeHandlers';

export default function AppRun ($rootScope, $injector, JWT) {
  'ngInject';

  $rootScope.$on('$stateChangeSuccess', function (e, to) {
    document.title = (to.data && to.data.title) ? `ARC-EMS | ${to.data.title}` : 'ARC-EMS';
  });

  const initStateChange = $rootScope.$on('$stateChangeStart', (e, to, toParams) => {
    initStateChange();
    const initStateChangeHandler = JWT.exists() ? stateChangeHandlerWithToken : stateChangeHanlerWithoutToken;
    $injector.invoke(initStateChangeHandler)(e, to, toParams);
  });
}
