export class SidenavSubmenuController {
  /* @ngInject */
  constructor ($element, $state, $stateParams, $timeout) {
    this._element = $element;
    this._state = $state;
    this._stateParams = $stateParams;
    this._timeout = $timeout;
  }

  $postLink () {
    this.toggled = false;
    this._list = this._element.find('ul');
    this._timeout(() => {
      this._list.css('height', 0);
    });
  }

  toggle () {
    this.toggled = !this.toggled;
    if (this.toggled) {
      this._list.css('height', 'auto');
    } else {
      this._list.css('height', 0);
    }
  }

  childIsActive () {
    if (this.item.parentSref) {
      return ~this._state.current.name.indexOf(this.item.parentSref);
    }

    if (this.item.children) {
      return this.item.children.some(element => {
        return ~element.sref.indexOf(this._state.current.name);
      });
    }

    return false;
  }
}

export default {
  bindings: {
    item: '<',
    closeSidenav: '&',
  },
  require: {
    sidenavMenu: '^sidenavMenu',
  },
  controller: SidenavSubmenuController,
  templateUrl: 'common/navigation/templates/sidenavSubmenu.tpl.html',
};
