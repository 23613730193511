import angular from 'angular';
import uiRouter from 'angular-ui-router';
import LoginComponent from './components/login';

const moduleConfig = function ($stateProvider) {
  'ngInject';
  $stateProvider.state('login', {
    url: '/',
    template: '<login></login>',
    data: {
      title: 'Login',
    },
    params: {
      previousState: null,
    },
  });
};

const module = angular
  .module('gnaas.pages.login', [
    uiRouter,
  ])
  .config(moduleConfig)
  .component('login', LoginComponent);

export default module.name;
