import uuid from 'node-uuid';

export default class CreateBaseController {
  constructor (BaseResource, Dialog) {
    'ngInject';
    this._BaseResource = BaseResource;
    this._Dialog = Dialog;
    this.ID = uuid.v4();
  }

  submit (data) {
    if (this.form.$invalid) {
      return;
    }

    if (this.editing) {
      this._update(data);
    } else {
      this._create(data);
    }
  };

  cancel () {
    this._Dialog.cancel();
  };

  delete (id) {
    this._Dialog.confirm().then(() => {
      this._BaseResource.destroy(id).then(() => {
        this._Dialog.cancel(id);
      });
    });
  };

  _create (data) {
    data.id = this.ID;
    this._BaseResource.create(data).then(() => {
      this._Dialog.hide(data);
    }).catch(console.log.bind(console));
  };

  _update (data) {
    this._BaseResource.update(data.id, data).then((item) => {
      this._Dialog.hide(item);
    }).catch(console.log.bind(console));
  };
}
