import { AUTH_EVENTS } from 'common/auth/module.constants';

export default class Session {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($rootScope) {
    this._rootScope = $rootScope;

    this._user = {};
    this._attributes = {};
    this._initialized = false;
  }

  start (user, attributes) {
    if (this._initialized) {
      throw new Error('Session already exists.');
    }

    this._initialized = true;

    this._user = Object.assign(user);

    if (Array.isArray(attributes) && attributes.length > 0) {
      attributes = attributes.reduce(function (previous, current) {
        return Object.assign(previous, current);
      });
    }

    Object.assign(this._attributes, attributes);
  }

  destroy () {
    this._user = {};
    this._attributes = {};
    this._initialized = false;
  }

  exists () {
    return this._initialized;
  }

  set (attribute, value) {
    this._attributes[attribute] = value;
  }

  get (attribute) {
    return this._attributes[attribute];
  }

  user () {
    return this._user;
  }

  setUser (user) {
    this._user = Object.assign(user);
    this._rootScope.$emit(AUTH_EVENTS.sessionUserUpdated);
  }

  updateShouldChangePassword (value) {
    if (!this._user) {
      return;
    }

    this._user.should_change_password = value;
  }

  isAuthenticatedUser (id) {
    if (!this._user) {
      return false;
    }

    return this._user.id === id;
  }

  isSysadminUser () {
    return this._user.group != null && this._user.group.slug === 'system-administrators';
  }
}
