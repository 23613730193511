import ResourceDialog from 'common/resource/services/resourceDialog';
import DialogController from './equipment-location-dialog.controller';

export default class EquipmentLocationDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(DialogController);
    this.setTemplateUrl(
      'core/equipment-locations/services/equipment-location-dialog/equipment-location-dialog.tpl.html'
    );
  }
}
