import moment from 'moment';

export default function FormDate () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      disabled: '=feDisabled',
      classes: '@feClasses',
      errors: '=feErrors',
      isEmbedded: '@feIsEmbedded',
      label: '@feLabel',
      maxDate: '=feMaxDate',
      minDate: '=feMinDate',
      model: '=feModel',
      mode: '@feMode',
      name: '@feName',
      placeholder: '@fePlaceholder',
      required: '=feRequired',
      onChange: '&feOnChange',
    },
    controller: function () {
      this.placeholder = this.placeholder || 'Enter date';
      if (this.mode && ~['month', 'day'].indexOf(this.mode.toLowerCase())) {
        this.mode = this.mode.toLowerCase();
      }
      this.dateLocale = {};
      if (this.mode === 'month') {
        this.dateLocale = {
          formatDate: date => date ? moment(date).format('MM-YYYY') : '',
          parseDate: dateString => {
            const m = moment(dateString, 'MM-YYYY', true);
            return m.isValid() ? m.toDate() : new Date(NaN);
          },
        };
      }
    },
    controllerAs: '$ctrl',
    bindToController: true,
    template: `
      <div class="form-date" ng-class="{'s-is-disabled': $ctrl.disabled, 'form-date--embedded': $ctrl.isEmbedded}">
          <label>{{ $ctrl.label }}</label>
          <md-datepicker
                class="{{ $ctrl.classes }}"
                name="{{ $ctrl.name }}"
                ng-model="$ctrl.model"
                ng-change="$ctrl.onChange({date: $ctrl.model})"
                ng-disabled="!!$ctrl.disabled"
                ng-required="!!$ctrl.required"
                md-mode="{{ $ctrl.mode }}"
                md-min-date="$ctrl.minDate"
                md-max-date="$ctrl.maxDate"
                md-placeholder="{{ $ctrl.placeholder }}"
                md-date-locale="$ctrl.dateLocale"
          ></md-datepicker>
          <div class="validation-messages" ng-messages="$ctrl.errors">
              <div ng-message="valid">This must be a valid date.</div>
              <div ng-message="required">This is required.</div>
              <div ng-message="mindate">Date is too early.</div>
              <div ng-message="maxdate">Date is too late.</div>
          </div>
      </div>
    `,
  };
}
