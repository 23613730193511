export default class TransactionApprovalController {

  /**
   * @ngInject
   * @constructor
   */
  constructor (Dialog, DrugStockTransactionResource, Toast, $q) {
    this._Dialog = Dialog;
    this._DrugStockTransactionResource = DrugStockTransactionResource;
    this._Toast = Toast;
    this._q = $q;

    if (this.dataOnly == null) {
      this.dataOnly = false;
    }

    this.users = {};
    if (this.userEmails && this.userEmails.first) {
      this.users.first_user = { email: this.userEmails.first };
    }
    if (this.userEmails && this.userEmails.second) {
      this.users.second_user = { email: this.userEmails.second };
    }
  }

  submit (users, approvalId) {
    if (this.form.$invalid) return;

    if (this.dataOnly) {
      return this._Dialog.hide(users);
    }

    return this._DrugStockTransactionResource
      .approve(approvalId, users)
      .then(() => {
        this._Dialog.hide();
      })
      .catch(err => {
        if (this.users.first_user) {
          this.users.first_user.password = null;
        }
        this.users.second_user.password = null;
        this._Toast.showSimple('Stock transaction approval unsuccessful.');
        this._q.reject(err.data.errors ? err.data.errors : {});
      });
  }

  cancel () {
    this._Dialog.cancel();
  }
}
