import CheckboxListDialogController from './checkbox-list-dialog.controller';

class CheckboxListDialogService {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog) {
    this.Dialog = Dialog;

    this.dialogParams = {
      controller: CheckboxListDialogController,
      templateUrl: 'common/lists/services/checkbox-list-dialog/checkbox-list-dialog.tpl.html',
    };
  }

  show ({ $event, items, options, resource, title, parse, trackByKey }) {
    return this.Dialog.show({
      ...this.dialogParams,
      targetEvent: $event || null,
      locals: { items, options, resource, title, parse, trackByKey },
    });
  }
}

export default CheckboxListDialogService;
