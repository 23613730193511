import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

function generateStockLocationProperties (stockLocations) {
  return stockLocations.map(location => ({ id: uuid.v4(), stock_location: location, minimum_stock_level: null }));
}

function generateMissingStockLocationProperties (stockLocationProps, stockLocations) {
  const existingStockLocationPropIds = stockLocationProps.map(i => i.stock_location.id);
  const pendingStockLocations = stockLocations.filter(i => existingStockLocationPropIds.indexOf(i.id) === -1);
  const generatedStockLocationProps = generateStockLocationProperties(pendingStockLocations);
  return [...stockLocationProps, ...generatedStockLocationProps];
}

function generateProductCodes (suppliers) {
  return suppliers.map(supplier => ({ id: uuid.v4(), supplier, product_code: null }));
}

function generateMissingProductCodes (productCodes, suppliers) {
  const existingProductCodeIds = productCodes.map(i => i.supplier.id);
  const pendingProductCodes = suppliers.filter(i => existingProductCodeIds.indexOf(i.id) === -1);
  const generatedProductCodes = generateProductCodes(pendingProductCodes);
  return [...productCodes, ...generatedProductCodes];
}

export default class CreateDrugController extends ResourceDialogController {
  init () {
    super.init();
    this.ConsumableResource = this.$injector.get('ConsumableResource');
    this.ID = uuid.v4();

    if (!this.item) {
      this.item = {};
    }

    if (!this.item.stock_location_properties || !this.item.stock_location_properties.length) {
      this.item.stock_location_properties = generateStockLocationProperties(this.relationData.stockLocations);
    } else {
      this.item.stock_location_properties = generateMissingStockLocationProperties(
        this.item.stock_location_properties,
        this.relationData.stockLocations
      );
    }

    if (!this.item.product_codes || !this.item.product_codes.length) {
      this.item.product_codes = generateProductCodes(this.relationData.suppliers);
    } else {
      this.item.product_codes = generateMissingProductCodes(this.item.product_codes, this.relationData.suppliers);
    }
  }

  delete (id) {
    this.Dialog.confirm().then(() => {
      this.ConsumableResource.destroy(id)
        .then(() => {
          this.Dialog.cancel(id);
        })
        .catch(err => {
          if (err.status === 409 && err.data.errors && err.data.errors.hasOwnProperty('live_batches')) {
            this.Dialog.alert('This consumable has batches that have not been removed.', 'Could not delete consumable');
          }
        });
    });
  };

  create (data) {
    data.id = this.ID;

    this.ConsumableResource.create(data).then(() => {
      this.Dialog.hide(Object.assign(data, { typeString: data.type.name }));
    }).catch(console.log.bind(console));
  };

  update (data) {
    this.ConsumableResource
      .update(data.id, data, { include: 'productCodes.supplier,stockLocationProperties,type' })
      .then(drug => {
        this.Dialog.hide(Object.assign(drug, { typeString: drug.type.name }));
      })
      .catch(console.log.bind(console));
  };
}
