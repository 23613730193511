import angular from 'angular';
import ShiftDialog from './services/shift-dialog/shift-dialog.service';
import ShiftCrewDialog from './services/shift-crew-dialog/shift-crew-dialog.service';
import ShiftResource from './resources/shift.resource';
import ShiftSidebarItem from './components/shift-sidebar-item/shift-sidebar-item';
import ShiftService from './services/shift.service';

const module = angular
  .module('gnaas.core.shifts', [])
  .component('shiftSidebarItem', ShiftSidebarItem)
  .service('ShiftDialog', ShiftDialog)
  .service('ShiftCrewDialog', ShiftCrewDialog)
  .service('ShiftResource', ShiftResource)
  .service('ShiftService', ShiftService);

export default module.name;
