export default function ModuleConfig ($stateProvider) {
  'ngInject';

  $stateProvider.state('shifts', {
    url: '/shifts',
    template: '<shifts-page flex layout="column"></shifts-page>',
    data: {
      title: 'Shifts',
    },
  });
}
