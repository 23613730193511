import ResourceDialog from 'common/resource/services/resourceDialog';
import InterventionDialogController from './intervention-dialog.controller';

export default class InterventionDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(InterventionDialogController);
    this.setTemplateUrl('core/prf/services/intervention-dialog/intervention-dialog.tpl.html');
  }
}
