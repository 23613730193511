import CreateConsumableController from '../services/consumable-dialog/consumable-dialog.controller';

const DEFAULT_LIST_FIELDS = {
  name: 'Name',
  typeString: 'Type',
};

export default function ConsumableListController (
  ConsumableResource, ConsumableTypeResource, SupplierResource, StockLocationResource
) {
  'ngInject';

  this.singularName = 'Consumable';
  this.pluralName = 'Consumables';

  this.createDialogController = CreateConsumableController;
  this.createDialogTemplateUrl = 'pages/admin/consumables/services/consumable-dialog/consumable-dialog.tpl.html';

  ConsumableResource.index({ include: 'productCodes.supplier,stockLocationProperties,type' }).then(items => {
    this.items = items.map(item => Object.assign(item, { typeString: item.type.name }));
  });

  this.relationData = { consumableTypes: [], stockLocations: [], suppliers: [] };
  ConsumableTypeResource.index().then(items => (this.relationData.consumableTypes = items));
  SupplierResource.index().then(items => { this.relationData.suppliers = items; });
  StockLocationResource.index().then(items => { this.relationData.stockLocations = items; });

  this.hasProfiles = false;
  this.listFields = Object.assign({}, DEFAULT_LIST_FIELDS);
};
