import RestResource from 'common/resource/services/restResource';

export function formatCompartmentItem (item) {
  const i = {
    id: item.id,
    item_type: item.item_type,
    item_id: item.item_id,
    item_name: item.item_name,
    quantity: item.quantity,
  };
  if (item.item) {
    i.item = {
      id: item.item.data.id,
      name: item.item.data.name,
    };
  }
  if (item.compartment) {
    i.compartment = formatCompartment(item.compartment.data);
  }
  return i;
}

export function formatCompartment (item) {
  const i = {
    id: item.id,
    name: item.name,
  };
  // if (item.parent_id) i.parent_id = item.parent_id;
  if (item.compartments) i.compartments = item.compartments.data.map(formatCompartment);
  if (item.items) i.items = item.items.data.map(formatCompartmentItem);
  if (item.location) i.location = format(item.location.data);
  return i;
}

export function format (item) {
  const i = {
    id: item.id,
    name: item.name,
  };
  if (item.compartments) i.compartments = item.compartments.data.map(formatCompartment);
  return i;
}

export function parseCompartmentItem (item) {
  return {
    id: item.id,
    item_id: item.item_id,
    item_type: item.item_type,
    quantity: item.quantity,
  };
}

export function parseCompartment (item) {
  const i = {
    id: item.id,
    name: item.name,
  };
  if (item.compartments) i.compartments = item.compartments.map(parseCompartment);
  if (item.items) i.items = item.items.map(parseCompartmentItem);
  return i;
}

export function parse (item) {
  const i = {
    id: item.id,
    name: item.name,
  };
  if (item.compartments) i.compartments = item.compartments.map(parseCompartment);
  return i;
}

export default class EquipmentLocationResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'equipment-locations';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
