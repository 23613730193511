import RestResource from 'common/resource/services/restResource';
import { format as formatDrug } from 'pages/admin/drugs/resources/drug.resource';

export default class DrugStockResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'drug-stock';
  }

  format (item) {
    if (item.drug) { item.drug = formatDrug(item.drug.data); }
    if (item.location) { item.location = item.location.data; }
    return item;
  }

  generateDrugOrderForms (data) {
    const url = `${this.apiUrl}drug-order-forms`;

    return this._http({
      url: url,
      method: 'POST',
      data: data,
      responseType: 'arraybuffer',
    }).then(response => response.data);
  }
}
