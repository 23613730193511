import moment from 'moment';
import { BATCH_MANAGEMENT_UPDATE_EVENT } from './batch-management.controller';
import { DATE_TIME_FORMAT } from 'common/resource/module.constants';

const getCellClass = (grid, row) => {
  return row.entity.isExpired ? 'ui-grid-cell-bg-red' : '';
};

const DEFAULT_COLUMNS = {
  batch_number: { name: 'Batch Number' },
  expires_on: { name: 'Batch Expiry', type: 'date', cellFilter: 'date:"dd-MM-yyyy"' },
  location: { name: 'Location' },
  available_quantity: { name: 'Quantity' },
  dose_per_unit: { name: 'Total Dose Per Unit' },
};
Object.values(DEFAULT_COLUMNS).forEach(col => {
  Object.assign(col, { width: '*', cellClass: getCellClass });
});

export default class DrugsBreakdownController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (DrugResource, DrugBatchesResource, $rootScope) {
    this.DrugResource = DrugResource;
    this.DrugBatchesResource = DrugBatchesResource;
    this.$rootScope = $rootScope;
  }

  $onInit () {
    this.showEmptyBatches = false;
    this.showExpiredBatches = true;
    this.pagination = { pageNumber: 0, pageSize: 0 };

    this.columns = Object.assign({}, DEFAULT_COLUMNS);
    this.options = {
      enablePagination: true,
      useExternalPagination: true,
      totalItems: 0,
      resolvePage: (pageNumber, pageAmount) => {
        this.updateData(pageNumber, pageAmount);
      },
    };

    this.DrugResource.index().then(drugs => { this.drugs = drugs; });

    this.$rootScope.$on(BATCH_MANAGEMENT_UPDATE_EVENT, () => {
      this.updateData(this.pagination.pageNumber, this.pagination.pageSize);
    });
  }

  updateData (pageNumber, pageSize) {
    if (!this.drug || !this.drug.id) return;

    if (!pageNumber) { pageNumber = 1; }
    if (!pageSize) { pageSize = 25; }

    this.pagination.pageNumber = pageNumber;
    this.pagination.pageSize = pageSize;

    if (this.lastDrugId !== this.drug.id) { this.batches = undefined; }
    this.lastDrugId = this.drug.id;

    const params = {
      drug_id: this.drug.id,
      quantity_mode: this.showEmptyBatches ? 'all' : 'non-empty',
      expiry_mode: this.showExpiredBatches ? 'all' : 'non-expired',
      page: pageNumber,
      include: ['drug', 'location'],
      page_size: pageSize,
    };

    this.DrugBatchesResource.index(params)
      .then(data => {
        this.options.pageNumber = pageNumber;
        this.options.totalItems = data.pagination.total;
        this.batches = this.transformData(data.data);
      })
      .catch(console.log.bind(console));
  }

  transformData (data) {
    const now = moment();
    return data.map(item => {
      const expiresOn = moment(item.expires_on, DATE_TIME_FORMAT);
      item.isExpired = expiresOn.isSameOrBefore(now);
      item.expires_on = expiresOn.toDate();
      item.location = item.location.name;
      return item;
    });
  }
}
