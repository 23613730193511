import CreateVehicleCallsignController from './createVehicleCallsignController';

export default function VehicleCallsignResourceController (VehicleCallsignResource, VehicleResource,
    BaseResource, OfflineReasonResource) {

  'ngInject';

  this.singularName = 'Vehicle Callsign';
  this.pluralName = 'Vehicle Callsigns';

  this.createDialogController = CreateVehicleCallsignController;
  this.createDialogTemplateUrl = 'pages/admin/vehicleCallsigns/templates/createDialog.tpl.html';

  VehicleCallsignResource
      .index({include: 'vehicle,base,offlineReason'})
      .then(items => {
        this.items = items.map(item => {
          item.baseName = item.base.name;
          return item;
        });
      });
  this.relationData = {
    vehicles: [],
    bases: [],
    offlineReasons: [],
    statuses: {
      'Offline': 0,
      'Online': 1,
    },
  };
  // TODO Abstract in a VehicleCallsignService
  VehicleResource.index().then(data => { this.relationData.vehicles = data; });
  BaseResource.index().then(data => { this.relationData.bases = data; });
  OfflineReasonResource.index().then(data => { this.relationData.offlineReasons = data; });

  this.listFields = {
    name: 'Name',
    statusString: 'Status',
    baseName: 'Base',
  };

  this.usesSidenav = false;
}
