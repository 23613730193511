import { format as formatCrewMember } from 'pages/crewMembers/services/crewMemberResource';

export default class AuthResource {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($http, API_URL) {
    this.$http = $http;
    this.AUTH_URL = `${API_URL}auth`;
  }

  login (email, password) {
    return this.$http
      .post(`${this.AUTH_URL}`, {email: email, password: password})
      .then(response => response.data);
  }

  logout () {
    return this.$http
      .delete(`${this.AUTH_URL}`)
      .then(response => response.data);
  }

  getUser () {
    return this.$http
      .get(`${this.AUTH_URL}`)
      .then(response => formatCrewMember(response.data.data));
  }

  refresh () {
    return this.$http
      .put(`${this.AUTH_URL}`)
      .then(response => response.data);
  }

  check (refresh = false) {
    return this.$http
      .get(`${this.AUTH_URL}/check?refresh_flow=${refresh}`)
      .then(response => response.data);
  }
}
