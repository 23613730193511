import moment from 'moment';
import RestResource from 'common/resource/services/restResource';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'common/resource/module.constants';
import { format as formatConsumable } from './consumable.resource';

export function format (item) {
  if (item.consumable) { item.consumable = formatConsumable(item.consumable.data); }
  if (item.stockLocation) { item.stockLocation = item.stockLocation.data; }
  return item;
}

export function parse (item) {
  return Object.assign({}, item, {
    expires_on: moment(item.expires_on).format(DATE_FORMAT),
    received_on: moment(item.received_on).format(DATE_TIME_FORMAT),
  });
}

export default class ConsumableBatchResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'consumable-batches';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
