import RestResource from 'common/resource/services/restResource';
import { copy } from 'angular';
import { format as formatDrug } from 'pages/admin/drugs/resources/drug.resource';

export function format (item) {
  item.resolved = !!item.resolved;
  item.drug = formatDrug(item.drug.data);
  return item;
}

export function parse (item) {
  const i = copy(item);
  i.drug_id = i.drug.id;
  delete i.drug;
  return i;
}

export default class DrugStockCheckResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'drug-stock-check-items';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
