export default {
  /**
   * CALENDAR
   */
  'calendar-event-types': {
    controller: 'CreateCalendarEventTypeController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Calendar Event Type',
      multiple: 'Calendar Event Types',
    },
    resource: 'CalendarEventTypeResource',
  },

  /**
   * CONSUMABLES
   */
  'consumable-types': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Consumable Type',
      multiple: 'Consumable Types',
    },
    resource: 'ConsumableTypeResource',
  },

  /**
   * DEPLOYMENTS
   */
  /*
  'abort-reasons': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Abort Reason',
      multiple: 'Abort Reasons',
    },
    resource: 'AbortReasonResource',
  },
   */

  'deployment-statuses': {
    controller: 'CreateDeploymentStatusController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Deployment Status',
      multiple: 'Deployment Statuses',
    },
    resource: 'DeploymentStatusResource',
  },

  'risk-assessment-outcomes': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Risk Assessment Outcome',
      multiple: 'Risk Assessment Outcomes',
    },
    resource: 'RiskAssessmentOutcomeResource',
  },

  /**
   * LOCATIONS
   */
  'locations': {
    fields: {
      'name': 'Name',
    },
    form: 'location.tpl.html',
    labels: {
      single: 'Location',
      multiple: 'Locations',
    },
    resource: 'LocationResource',
    query: {
      include: 'locationType',
    },
    relationData: {
      'locationTypes': {
        resource: 'LocationTypeResource',
        method: 'index',
      },
    },
  },

  'location-types': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Location Type',
      multiple: 'Location Types',
    },
    resource: 'LocationTypeResource',
  },

  /**
   * DRUGS
   */
  'infusion-solutions': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Infusion Solution',
      multiple: 'Infusion Solutions',
    },
    resource: 'InfusionSolutionResource',
  },

  'medication-routes': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Medication Route',
      multiple: 'Medication Routes',
    },
    resource: 'MedicationRouteResource',
  },

  /**
   * HOSPITALS
   */
  'hospital-specialties': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Hospital Specialty',
      multiple: 'Hospital Specialties',
    },
    resource: 'HospitalSpecialtyResource',
  },

  /**
   * INCIDENTS
   */
  'ambulance-services': {
    controller: 'CreateAmbulanceServiceController',
    fields: {
      'name': 'Name',
      'has_reference_number': 'Has Reference Number',
    },
    labels: {
      single: 'Ambulance Service',
      multiple: 'Ambulance Services',
    },
    resource: 'AmbulanceServiceResource',
    form: 'ambulance-service.tpl.html',
  },

  'transfer-reasons': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Transfer Reason',
      multiple: 'Transfer Reasons',
    },
    resource: 'DeploymentReasonResource',
  },

  'decline-reasons': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Decline Reason',
      multiple: 'Decline Reasons',
    },
    resource: 'RefusalReasonResource',
  },

  'service-areas': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Service Area',
      multiple: 'Service Areas',
    },
    resource: 'ServiceAreaResource',
  },

  'grades': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Grade',
      multiple: 'Grades',
    },
    resource: 'GradeResource',
  },

  'wards': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Ward',
      multiple: 'Wards',
    },
    resource: 'WardResource',
  },

  /**
   * PRFs
   */
  'body-parts': {
    controller: 'CreateBodyPartController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Body Region',
      multiple: 'Body Regions',
    },
    resource: 'BodyPartResource',
    relationData: {
      'injuries': {
        resource: 'InjuryResource',
        method: 'index',
      },
      'illnesses': {
        resource: 'IllnessResource',
        method: 'index',
      },
      'interventions': {
        resource: 'InterventionResource',
        method: 'index',
      },
    },
    multiSelections: ['injuries', 'illnesses', 'interventions'],
  },

  'injuries': {
    controller: 'CreateCategoryWithTypesController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Injury',
      multiple: 'Injuries',
    },
    resource: 'InjuryResource',
  },

  'interventions': {
    controller: 'CreateInterventionController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Intervention',
      multiple: 'Interventions',
    },
    relationData: {
      'interventionAttributeTypes': {
        resource: 'InterventionResource',
        method: 'getAttributeTypes',
      },
    },
    resource: 'InterventionResource',
  },

  'illnesses': {
    controller: 'CreateCategoryWithTypesController',
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Illness',
      multiple: 'Illnesses',
    },
    resource: 'IllnessResource',
  },

  'patient-types': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Patient Type',
      multiple: 'Patient Types',
    },
    resource: 'PatientTypeResource',
  },

  'infection-statuses': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Infection Status',
      multiple: 'Infection Statuses',
    },
    resource: 'InfectionStatusResource',
  },

  /**
   * CLINICAL INCIDENT
   */

  'communications': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Communication',
      multiple: 'Communications',
    },
    resource: 'CommunicationResource',
  },

  'equipment-problems': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Equipment Problem',
      multiple: 'Equipment Problems',
    },
    resource: 'EquipmentProblemResource',
  },

  'organisational-delays': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Organisational Delay',
      multiple: 'Organisational Delays',
    },
    resource: 'OrganisationalDelayResource',
  },

  'physiological-deteriorations': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Physiological Deterioration',
      multiple: 'Physiological Deteriorations',
    },
    resource: 'PhysiologicalDeteriorationResource',
  },

  /**
   * USERS
   */
  'professions': {
    fields: {
      'title': 'Title',
    },
    form: 'profession.tpl.html',
    labels: {
      single: 'Profession',
      multiple: 'Professions',
    },
    resource: 'ProfessionResource',
  },

  /**
   * VEHICLES
   */
  'offline-reasons': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Offline Reason',
      multiple: 'Offline Reasons',
    },
    resource: 'OfflineReasonResource',
  },

  /**
   * STOCK
   */
  'suppliers': {
    fields: {
      'name': 'Name',
    },
    form: 'supplier.tpl.html',
    labels: {
      single: 'Supplier',
      multiple: 'Suppliers',
    },
    resource: 'SupplierResource',
  },

  'stock-locations': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Stock Location',
      multiple: 'Stock Locations',
    },
    resource: 'StockLocationResource',
  },
};
