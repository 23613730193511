import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class ShiftDialogController extends ResourceDialogController {
  init () {
    this.ID = uuid.v4();
    this.ShiftResource = this.$injector.get('ShiftResource');
  }

  create (formData) {
    formData.id = this.ID;
    this._setDates(formData);
    this.ShiftResource
      .create(formData)
      .then(() => {
        this.Toast.showSimple('Shift created.');
        this.Dialog.hide(formData);
      })
      .catch(err => {
        let msg = 'Error creating shift.';
        if (err && err.status === 422) {
          msg = 'Shift validation error, please check form fields.';
        }
        this.Toast.showSimple(msg);
      });
  }

  update (formData) {
    this._setDates(formData);
    this.ShiftResource
      .update(formData.id, formData, { include: 'properties.base,properties.vehicleCallsign' })
      .then(shift => {
        this.Toast.showSimple('Shift updated.');
        this.Dialog.hide(shift);
      })
      .catch(err => {
        let msg = 'Error updating shift.';
        if (err && err.status === 422) {
          msg = 'Shift validation error, please check form fields.';
        }
        this.Toast.showSimple(msg);
      });
  }

  delete (id) {
    const msg = 'This action is not reversible. ' +
                'It will delete all historical data associated with this shift.';

    this.Dialog.confirm(msg).then(() => {
      this.ShiftResource
        .destroy(id)
        .then(() => {
          this.Toast.showSimple('Shift deleted.');
          this.Dialog.cancel(id);
        })
        .catch(() => {
          this.Toast.showSimple('Error deleting shift.');
        });
    });
  };

  _setDates (data) {
    if (!data.start_date) {
      data.start_date = new Date();
    }
    data.end_date = data.inactive ? new Date() : null;
  }
}
