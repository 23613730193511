import CreateHospitalController from './createHospitalController';
import { RESOURCE_ITEM_FIELD_TYPES } from 'common/resource/module.constants';

export default function HospitalResourceController (HospitalResource, HospitalSpecialtyResource) {
  'ngInject';

  this.singularName = 'Hospital';
  this.pluralName = 'Hospitals';

  this.createDialogController = CreateHospitalController;
  this.createDialogTemplateUrl = 'pages/admin/hospitals/templates/createDialog.tpl.html';

  HospitalResource.index().then(items => { this.items = items; });
  this.relationData = { specialties: [] };
  HospitalSpecialtyResource.index().then(items => { this.relationData.specialties = items; });

  this.listFields = {
    name: 'Name',
    abbreviation: 'Abbreviation',
  };

  this.hasProfiles = true;
  this.profileFields = [
    {
      key: 'abbreviation',
      label: 'Abbreviation',
      type: RESOURCE_ITEM_FIELD_TYPES.item,
    }, {
      key: 'lat',
      label: 'Latitude',
      type: RESOURCE_ITEM_FIELD_TYPES.item,
    }, {
      key: 'lng',
      label: 'Longitude',
      type: RESOURCE_ITEM_FIELD_TYPES.item,
    }, {
      key: 'specialties',
      label: 'Specialties',
      type: RESOURCE_ITEM_FIELD_TYPES.collection,
      collectionKey: 'name',
    },
  ];
};
