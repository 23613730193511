import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ModuleConfig from './module.config';
import FlaggedIncidentList from './components/flagged-incident-list/flagged-incident-list.component';

const module = angular
  .module('gnaas.pages.admin.flaggedIncidents', [
    uiRouter,
  ])
  .config(ModuleConfig)
  .component('flaggedIncidentListPage', FlaggedIncidentList);

export default module.name;
