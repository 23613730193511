import angular from 'angular';
import BodyPartSelector from './components/body-part-selector.component';
import ngCx from './directives/ngCx';
import ngCy from './directives/ngCy';

const module = angular
  .module('gnaas.common.body-part-selector', [])
  .component('bodyPartSelector', BodyPartSelector)
  .directive('ngCx', ngCx)
  .directive('ngCy', ngCy);

export default module.name;
