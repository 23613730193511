import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class HospitalInformationDialogController extends ResourceDialogController {
  init () {
    this._resource = this.$injector.get('PRFResource');
  }

  submit (formData) {
    if (!this.form) {
      throw new Error('Form not found.');
    }

    if (this.form.$invalid) {
      return;
    }

    this._resource.patch(this.prfId, {
      handover_notes: formData,
    }).then(prf => {
      this.Dialog.hide(prf.handover_notes);
    });
  };
}
