export default function Utils () {
  /**
   * Arrays.
   */
  function findObjectInArrayById (array, id) {
    let result;

    array.some(function (element) {
      const match = element.id === id;
      if (match) {
        result = element;
      }
      return match;
    });

    return result;
  }

  function findObjectInArrayByKey (array, keyValue, keyName) {
    let result;

    array.some(function (element) {
      const match = element[keyName] === keyValue;
      if (match) {
        result = element;
      }
      return match;
    });

    return result;
  }

  function removeFromArray (array, value) {
    let index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  function removeFromArrayById (array, id) {
    let item = findObjectInArrayById(array, id);
    removeFromArray(array, item);
  }

  function replaceInArrayById (array, id, replacement) {
    let item = findObjectInArrayById(array, id);
    let index = array.indexOf(item);
    if (index !== -1) {
      array[index] = replacement;
    }
  }

  /**
   * Strings.
   */
  function spacesToCamelCase (string) {
    return string.trim().replace(/\w\S*/g, function (string, index) {
      return index === 0
        ? string.toLowerCase()
        : string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
    }).replace(/\s*/g, '');
  }

  function getDateRangeString (start, end) {
    let dateString = '';
    if (start.year() === end.year() && start.month() === end.month()) {
      // Year and month the same
      dateString = `${start.format('D')} – ${end.format('D MMM YYYY')}`;
    } else if (start.year() === end.year() && start.month() !== end.month()) {
      // Different months
      dateString = `${start.format('D MMM')} – ${end.format('D MMM YYYY')}`;
    } else {
      // Different years
      dateString = `${start.format('D MMM YYYY')} – ${end.format('D MMM YYYY')}`;
    }
    return dateString;
  }

  return {
    findObjectInArrayById: findObjectInArrayById,
    removeFromArray: removeFromArray,
    removeFromArrayById: removeFromArrayById,
    replaceInArrayById: replaceInArrayById,
    spacesToCamelCase: spacesToCamelCase,
    getDateRangeString: getDateRangeString,
    findObjectInArrayByKey: findObjectInArrayByKey,
  };
}
