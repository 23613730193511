import RestResource from 'common/resource/services/restResource';
import moment from 'moment';
import { format as formatDeploymentStatus } from 'core/categories/resources/deployment-status.resource';
import { format as formatDeployment } from 'core/deployments/resources/deployment.resource';

export function parse (item) {
  let parsed = {
    id: item.id,
    time: moment(item.time).format('YYYY-MM-DD HH:mm:ss'),
    destination_type: item.destination_type,
  };

  if (item.deployment) {
    parsed.deployment_id = item.deployment.id;
  }

  if (item.deployment_status) {
    parsed.deployment_status_id = item.deployment_status.id;
  }

  if (item.destination) {
    parsed.destination_id = item.destination.id;
  }

  return parsed;
}

export function format (item) {
  let formatted = {
    id: item.id,
    time: moment(item.time, 'YYYY-MM-DD HH:mm:ss'),
    destination_type: item.destination_type,
  };

  if (item.deploymentStatus) {
    formatted.deployment_status = formatDeploymentStatus(item.deploymentStatus.data);
  }

  if (item.deployment) {
    formatted.deployment = formatDeployment(item.deployment.data);
  }

  if (item.destination) {
    formatted.destination = item.destination.data;
  }

  return formatted;
}

export default class DeploymentStatusUpdateResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'deployment-status-updates';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
