import moment from 'moment';
import RestResource from 'common/resource/services/restResource';
import { format as formatVehicleCallsign } from 'pages/admin/vehicleCallsigns/services/vehicleCallsignResource';
import { format as formatVehicle } from 'pages/admin/vehicles/services/vehicleResource';
import { format as formatIncident } from 'core/incidents/resources/incident.resource';
import { format as formatCrewMember } from 'pages/crewMembers/services/crewMemberResource';
import { format as formatStatusUpdate } from 'core/deployments/resources/deployment-status-update.resource';
import { format as formatHospital } from 'pages/admin/hospitals/services/hospitalResource';
import formatPRF from 'core/prf/services/prf-formatter.service';
import { DATE_TIME_FORMAT } from 'common/resource/module.constants';

export function format (item) {
  let formatted = {
    id: item.id,
    status: item.status,
    date: item.date ? moment(item.date, DATE_TIME_FORMAT) : null,
    comment: item.comment,
    relative_info_leaflet_given: item.relative_info_leaflet_given,
    relative_traveling_in_ambulance: !!item.relative_traveling_in_ambulance,
    accepting_consultant_name: item.accepting_consultant_name,
    accepting_nurse_name: item.accepting_nurse_name,
    accepting_unit_contact_no: item.accepting_unit_contact_no,
    risk_assessment: item.risk_assessment,
  };

  if (item.vehicleCallsign) {
    formatted.vehicle_callsign = formatVehicleCallsign(item.vehicleCallsign.data);
  }

  if (item.vehicle) {
    formatted.vehicle = formatVehicle(item.vehicle.data);
  }

  if (item.incident) {
    formatted.incident = formatIncident(item.incident.data);
  }

  if (item.users) {
    formatted.crew_members = item.users.data.map(user => ({ ...formatCrewMember(user), type: user.type }));
  }

  if (item.deploymentStatuses) {
    formatted.deployment_statuses = item.deploymentStatuses.data.map(formatStatusUpdate);
  }

  if (item.patientReportForms) {
    formatted.patient_report_forms = item.patientReportForms.data.map(formatPRF);
  }

  if (item.acceptingHospital) {
    formatted.accepting_hospital = formatHospital(item.acceptingHospital.data);
  }

  if (item.acceptingConsultantSpecialty) {
    formatted.accepting_consultant_specialty = item.acceptingConsultantSpecialty.data;
  }

  if (item.riskAssessmentOutcome) {
    formatted.risk_assessment_outcome = formatHospital(item.riskAssessmentOutcome.data);
  }

  if (item.departureChecklistCompletedBy) {
    formatted.departure_checklist_completed_by = formatCrewMember(item.departureChecklistCompletedBy.data);
  }

  if (item.departureChecklistApprovedBy) {
    formatted.departure_checklist_approved_by = formatCrewMember(item.departureChecklistApprovedBy.data);
  }

  if (item.refCentreDepartureChecklistCompletedBy) {
    formatted.ref_centre_departure_checklist_completed_by =
      formatCrewMember(item.refCentreDepartureChecklistCompletedBy.data);
  }

  if (item.refCentreDepartureChecklistApprovedBy) {
    formatted.ref_centre_departure_checklist_approved_by =
      formatCrewMember(item.refCentreDepartureChecklistApprovedBy.data);
  }

  return formatted;
}

export function parse (item) {
  let parsed = {
    id: item.id,
    status: item.status,
    date: item.date ? moment(item.date).format(DATE_TIME_FORMAT) : null,
    comment: item.comment || null,
    relative_info_leaflet_given: item.relative_info_leaflet_given,
    relative_traveling_in_ambulance: item.relative_traveling_in_ambulance ? 1 : 0,
    accepting_consultant_name: item.accepting_consultant_name,
    accepting_nurse_name: item.accepting_nurse_name || null,
    accepting_unit_contact_no: item.accepting_unit_contact_no,
    risk_assessment: item.risk_assessment || null,
  };

  if (item.incident) {
    parsed.incident_id = item.incident.id;
  }

  if (item.vehicle_callsign) {
    parsed.vehicle_callsign_id = item.vehicle_callsign.id;
  }

  if (item.accepting_hospital) {
    parsed.accepting_hospital_id = item.accepting_hospital.id;
  }

  if (item.accepting_consultant_specialty) {
    parsed.accepting_consultant_specialty_id = item.accepting_consultant_specialty.id;
  }

  if (item.risk_assessment_outcome) {
    parsed.risk_assessment_outcome_id = item.risk_assessment_outcome.id;
  }

  if (item.crew_members) {
    parsed.users = item.crew_members.map(i => ({ id: i.id, type: i.type }));
  }

  if (item.departure_checklist_completed_by) {
    parsed.departure_checklist_completed_by = item.departure_checklist_completed_by.id;
  }

  if (item.departure_checklist_approved_by) {
    parsed.departure_checklist_approved_by = item.departure_checklist_approved_by.id;
  }

  if (item.ref_centre_departure_checklist_completed_by) {
    parsed.ref_centre_departure_checklist_completed_by = item.ref_centre_departure_checklist_completed_by.id;
  }

  if (item.ref_centre_departure_checklist_approved_by) {
    parsed.ref_centre_departure_checklist_approved_by = item.ref_centre_departure_checklist_approved_by.id;
  }

  return parsed;
}

export default class DeploymentResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'deployments';
  }

  updateStatus (id, deploymentStatus) {
    this._http.put(`${this.apiUrl}${this.resourcePath}/${id}`, {
      deployment_status_id: deploymentStatus.id,
      time: moment(deploymentStatus.time).format('YYYY-MM-DD HH:mm'),
    }).then(() => {
      return deploymentStatus;
    }).catch(console.log.bind(console));
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
