import ResourceDialog from 'common/resource/services/resourceDialog';
import TransferBatchDialogController from './transfer-batch-dialog.controller';

export default class TransferBatchDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(TransferBatchDialogController);
    this.setTemplateUrl('core/drugs/services/transfer-batch-dialog/transfer-batch-dialog.tpl.html');
  }
}
