import { format as formatBodyPart } from 'core/categories/resources/body-part.resource';
import { format as formatIntervention } from 'core/categories/resources/intervention.resource';
import { format as formatCrewMember } from 'pages/crewMembers/services/crewMemberResource';

export default function format (item) {
  let formatted = {
    id: item.id,
    notes: item.notes,
    performed_by_third_party: item.performed_by_third_party,
  };

  if (item.intervention) {
    formatted.intervention = formatIntervention(item.intervention.data);
  }

  if (item.users) {
    formatted.crew_members = [];
    item.users.data.forEach(function (user) {
      formatted.crew_members.push(formatCrewMember(user));
    });
  }

  if (item.bodyPart) {
    formatted.body_part = formatBodyPart(item.bodyPart.data);
  }

  if (item.attributes) {
    formatted.attributes = item.attributes.data.map(attribute => {
      return {
        id: attribute.id,
        value: attribute.value,
        intervention_attribute: attribute.interventionAttribute.data,
        intervention_attribute_value: attribute.interventionAttributeValue
            ? attribute.interventionAttributeValue.data
            : null,
      };
    });
  }

  return formatted;
};
