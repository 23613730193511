export default function Toast ($mdToast) {
  'ngInject';

  this.showSimple = function (textContent) {
    $mdToast.show(
      $mdToast.simple()
        .textContent(textContent)
        .position('top right')
    );
  };
};
