import {element} from 'angular';

export default function WindowResizeVerticalHandler ($rootScope, $window) {
  'ngInject';

  return {
    replace: false,
    restrict: 'A',
    link: function ($scope, $element) {
      let win = element($window);
      let height = win.prop('innerHeight');

      win.on('resize', function () {
        if (height !== win.prop('innerHeight')) {
          $rootScope.$emit('WINDOW_RESIZE');
        }
      });

      $scope.$on('$destroy', function () {
        win.off('resize');
      });
    },
  };
}
