export default {
  controller () {},
  bindings: {
    isLoading: '<',
  },
  template: `
    <span class="loading-indicator">
      <md-progress-linear md-mode="query" ng-if="$ctrl.isLoading" class="md-accent"></md-progress-linear>
    </span>
  `,
};
