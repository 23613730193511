import RestResource from 'common/resource/services/restResource';

export default class ReportResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'reports';
  }

  download ({from, to, vehicleCallsignId = null, csv = false}) {
    let url = `${this.apiUrl}${this.resourcePath}?from=${from}&to=${to}&csv=${csv}`;
    if (vehicleCallsignId) {
      url += `&vehicle_callsign_id=${vehicleCallsignId}`;
    }
    const params = {
      url: url,
      method: 'GET',
    };
    return this._http(params).then(response => response.data);
  }
}
