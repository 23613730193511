import RestResource from 'common/resource/services/restResource';

export default class PrintablePRFResource extends RestResource {
  init () {
    this.resourcePath = 'printable-prf';
  }

  show (id) {
    const url = `${this.apiUrl}${this.resourcePath}/${id}`;

    return this._http({
      url: url,
      method: 'GET',
      responseType: 'arraybuffer',
    }).then(response => {
      return response.data;
    });
  }
}
