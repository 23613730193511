import RestResource from 'common/resource/services/restResource';

export function parse (item) {
  let parsed = {
    id: item.id,
    name: item.name,
    has_destination: item.has_destination ? 1 : 0,
    is_diverted: item.is_diverted ? 1 : 0,
    is_final_status: item.is_final_status ? 1 : 0,
    is_mobile: item.is_mobile ? 1 : 0,
    is_at_hospital: item.is_at_hospital ? 1 : 0,
    is_on_scene: item.is_on_scene ? 1 : 0,
    is_stand_down: item.is_stand_down ? 1 : 0,
    is_transporting_patient: item.is_transporting_patient ? 1 : 0,
  };

  return parsed;
}

export function format (item) {
  let formatted = {
    id: item.id,
    name: item.name,
    has_destination: !!item.has_destination,
    is_diverted: !!item.is_diverted,
    is_final_status: !!item.is_final_status,
    is_mobile: !!item.is_mobile,
    is_at_hospital: !!item.is_at_hospital,
    is_on_scene: !!item.is_on_scene,
    is_stand_down: !!item.is_stand_down,
    is_transporting_patient: !!item.is_transporting_patient,
  };

  return formatted;
}

export default class DeploymentStatusResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'categories/deployment-statuses';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
