import { copy } from 'angular';

/**
 * @abstract
 */
export default class RestResource {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($q, $http, API_URL) {
    if (this.constructor === RestResource) {
      throw new Error('Cannot construct an abstract class.');
    }
    this._q = $q;
    this._http = $http;
    this.apiUrl = API_URL;
    this.init();
  }

  init () {
    this.resourcePath = '';
  }

  /* ****************************** */
  /*    RESTful resource methods    */
  /* ****************************** */

  index (query) {
    let url = this.apiUrl + this.resourcePath;

    if (query) {
      url += this._generateQueryString(query);
    }

    return this._http.get(url).then(response => {
      return response.data ? this._formatData(response.data) : [];
    });
  }

  show (id, query) {
    let url = `${this.apiUrl}${this.resourcePath}/${id}`;

    if (query) {
      url += this._generateQueryString(query);
    }

    return this._http.get(url).then(response => {
      return response.data.data ? this._formatData(response.data.data) : null;
    });
  }

  create (data) {
    const url = this.apiUrl + this.resourcePath;
    return this._http.post(url, this._parseData(data)).then(response => {
      return response.data.data ? this._formatData(response.data.data) : null;
    });
  }

  update (id, data, query) {
    let url = `${this.apiUrl}${this.resourcePath}/${id}`;

    if (query) {
      url += this._generateQueryString(query);
    }

    let parsed = this._parseData(data);
    if (parsed.id) {
      delete parsed.id;
    }

    return this._http.put(url, parsed).then(response => {
      return response.data.data ? this._formatData(response.data.data) : null;
    });
  }

  destroy (id) {
    let url = `${this.apiUrl}${this.resourcePath}/${id}`;

    return this._http.delete(url).then(response => {
      return response.data;
    });
  }

  /* ************************** */
  /*    Parsers & formatters    */
  /* ************************** */

  _parseData (data) {
    if (Array.isArray(data)) {
      return data.map(item => {
        return this.parse(item);
      });
    } else {
      return this.parse(data);
    }
  }

  _formatData (data) {
    if (Array.isArray(data)) {
      return data.map(this.format);
    }

    if (data.data && data.meta && data.meta.pagination) {
      return {
        data: data.data.map(this.format),
        pagination: data.meta.pagination,
      };
    }

    if (data.data) {
      return Array.isArray(data.data) ? data.data.map(this.format) : this.format(data.data);
    }

    return this.format(data);
  }

  parse (item) {
    return copy(item);
  }

  format (item) {
    return copy(item);
  }

  /* ************** */
  /*    Includes    */
  /* ************** */

  _generateQueryString (queryData = {}) {
    let queryStr = '';
    let i = 0;

    Object.keys(queryData).forEach(function (key) {
      if (Array.isArray(queryData[key])) {
        queryData[key] = queryData[key].join(',');
      } else if (typeof queryData[key] === 'number') {
        queryData[key] = `${queryData[key]}`;
      } else if (typeof queryData[key] === 'boolean') {
        queryData[key] = queryData[key] ? 'true' : 'false';
      }

      if (queryData[key].length) {
        queryStr += (++i < 2 ? '?' : '&') + key + '=' + queryData[key];
      }
    });

    return queryStr.length > 1 ? queryStr : '';
  }
}
