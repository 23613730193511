import RestResource from 'common/resource/services/restResource';

export function format (item) {
  return { ...item };
}

export function parse (item) {
  return { ...item };
}

export default class BaseResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'bases';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
};
