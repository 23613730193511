function filterCriteria (item) {
  return [
    'patient_report_forms.first_name',
    'patient_report_forms.last_name',
    'patient_report_forms.date_of_birth',
    'patient_report_forms.address',
  ].indexOf(item.name) === -1;
}

export default {
  bindings: {
    category: '<',
    operations: '<',
    values: '<',
    queryItem: '<',
    formProps: '<',
  },
  controller: function () {
    this.$onInit = () => {
      this.booleanSelectValues = [
        { name: 'Yes', id: 1 },
        { name: 'No', id: 0 },
      ];

      this.category = this.category.filter(filterCriteria);
    };
  },
  templateUrl: 'core/search/components/search-query-item/search-query-item.tpl.html',
};
