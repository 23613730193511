import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ModuleConfig from './module.config';
import EquipmentLocationList from './components/equipment-location-list/equipment-location-list.component';
import EquipmentLocationSingle from './components/equipment-location-single/equipment-location-single.component';

const module = angular
  .module('gnaas.pages.admin.equipmentLocations', [
    uiRouter,
  ])
  .config(ModuleConfig)
  .component('equipmentLocationListPage', EquipmentLocationList)
  .component('equipmentLocationSinglePage', EquipmentLocationSingle);

export default module.name;
