import angular from 'angular';
import AuthResource from './services/authResource';
import IdleCheck from './services/idleCheck';
import JWT from './services/jwt';
import JwtInterceptor from './services/jwtInterceptor';
import Session from './services/session';

const module = angular
  .module('gnaas.common.auth', [])
  .provider('JwtInterceptor', JwtInterceptor)
  .service('AuthResource', AuthResource)
  .service('IdleCheck', IdleCheck)
  .service('JWT', JWT)
  .service('Session', Session);

export default module.name;
