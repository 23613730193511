import uuid from 'node-uuid';

const REPORTABLE_TYPE_DRUG = 'drug';
const REPORTABLE_TYPE_INTERVENTION = 'intervention';

function parseSelected (type, items) {
  return items.map(item => {
    if (item.reportable_type) return item;
    const original = { ...item };
    return {
      id: uuid.v4(),
      reportable_id: item.reportable_id,
      reportable_type: type,
      reportable: original,
    };
  });
}

function getTitle (type) {
  switch (type) {
    case REPORTABLE_TYPE_DRUG:
      return 'Reportable Drugs';
    case REPORTABLE_TYPE_INTERVENTION:
      return 'Reportable Interventions';
  }
}

/**
 * This page supports multiple resource types.
 * By default all reportables are requested and passed to reportables dialog for sync (it's all or nothing).
 * Only reportables with currently active type are visible in table list.
 *
 * TODO There is a theoretical possibility that IDs might match between different reportables types.
 *      In reality it should never occur. But for future reference - refactor this to only work with a single
 *      type at a time. Would require API changes as well.
 */
class ReportablesPageController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($stateParams, Toast, CheckboxListDialog, ReportablesResource, DrugResource, InterventionResource) {
    this.$stateParams = $stateParams;
    this.Toast = Toast;
    this.CheckboxListDialog = CheckboxListDialog;
    this.ReportablesResource = ReportablesResource;
    this.optionsResources = {
      [REPORTABLE_TYPE_DRUG]: DrugResource,
      [REPORTABLE_TYPE_INTERVENTION]: InterventionResource,
    };
  }

  $onInit () {
    this.type = this.$stateParams.type.toLowerCase();
    this.fields = {
      reportableName: 'Name',
    };

    const validTypes = [REPORTABLE_TYPE_DRUG, REPORTABLE_TYPE_INTERVENTION];
    if (!this.type || !~validTypes.indexOf(this.type)) {
      throw new Error(`Type not supported. Must be "${REPORTABLE_TYPE_DRUG}" or "${REPORTABLE_TYPE_INTERVENTION}"`);
    }

    this.title = getTitle(this.type);

    this.items = [];
    this.displayItems = [];
    this.ReportablesResource.index({ include: 'reportable' }).then(this._setItems.bind(this));

    this.options = [];
    this.optionsResources[this.type].index().then(items => {
      Object.assign(this.options, items.map(i => ({ ...i, reportable_id: i.id })));
    });
  }

  showSyncDialog ($event) {
    const params = {
      $event,
      resource: this.ReportablesResource,
      options: this.options,
      items: this.items,
      parse: parseSelected.bind(null, this.type),
      title: this.title,
      trackByKey: 'reportable_id',
    };

    this.CheckboxListDialog
      .show(params)
      .then(items => {
        this._setItems(items);
        this.Toast.showSimple(params.title + ' updated');
      })
      .catch(err => {
        if (err && err.status) {
          this.Toast.showSimple('Error updating ' + params.title.toLowerCase());
        }
      });
  }

  _setItems (items) {
    this.items = items.map(i => ({ ...i, reportableName: i.reportable.name }));
    this.displayItems = this.items.filter(i => i.reportable_type === this.type);
  }
}

const ReportablesPageComponent = {
  controller: ReportablesPageController,
  templateUrl: 'pages/admin/reports/components/reportables-page/reportables-page.tpl.html',
};

export default ReportablesPageComponent;
