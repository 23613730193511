import ResourceDialog from 'common/resource/services/resourceDialog';
import DeviceDialogController from './device-dialog.controller';

export default class DeviceDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(DeviceDialogController);
    this.setTemplateUrl('core/devices/services/device-dialog/device-dialog.tpl.html');
  }
}
