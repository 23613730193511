import moment from 'moment';
import { DATE_TIME_FORMAT, RESOURCE_ITEM_FIELD_TYPES } from 'common/resource/module.constants';

const DEFAULT_COLUMNS = {
  transaction_type: { name: 'Transaction Type', width: '180' },
  date: { name: 'Date', width: '120', type: 'date', cellFilter: 'date:"dd-MM-yyyy"' },
  time: { name: 'Time', width: '100', type: 'date', cellFilter: 'date:"HH:mm"' },
  location: { name: 'Batch Location', width: '180' },
  batch_number: { name: 'Batch Number', width: '180' },
  expiry: { name: 'Expiry', width: '120', type: 'date', cellFilter: 'date:"dd-MM-yyyy"' },
  quantity: { name: 'Quantity', width: '120' },
  drug_stock_left_after: { name: 'Total Stock', width: '180' },
  location_stock_left_after: { name: 'Location Stock', width: '180' },
  batch_stock_left_after: { name: 'Left In Batch', width: '180' },

  incident_number: { name: 'Referral Number', width: '210' },
  patient_name: { name: 'Patient Name', width: '210' },
  amount_given: { name: 'Amount Given', width: '180' },
  amount_wasted: { name: 'Amount Wasted', width: '180' },

  user: { name: 'User', width: '210' },
  approver_1: { name: 'Approver 1', width: '210' },
  approver_2: { name: 'Approver 2', width: '210' },
};

const SIDENAV_FIELDS = Object.keys(DEFAULT_COLUMNS).map(key => {
  return { key, label: DEFAULT_COLUMNS[key].name, type: RESOURCE_ITEM_FIELD_TYPES.item };
});
SIDENAV_FIELDS.splice(1, 0, { key: 'comments', label: 'Comments', type: RESOURCE_ITEM_FIELD_TYPES.item });

const DEFAULT_TRANSACTION_TYPES = [
  { id: 'all', value: 'All Transactions' },
  { id: 'adjust-up', value: 'Adjust Up' },
  { id: 'adjust-down', value: 'Adjust Down' },
  { id: 'issued', value: 'Issued' },
  { id: 'received', value: 'Received' },
  { id: 'destroyed', value: 'Destroyed' },
  { id: 'administered', value: 'Administered' },
  { id: 'broken', value: 'Broken' },
  { id: 'pre-drawn-wasted', value: 'Pre-Drawn Waste' },
];

export default class TransactionHistoryController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (DrugResource, DrugStockTransactionResource, $mdSidenav) {
    this.DrugResource = DrugResource;
    this.DrugStockTransactionResource = DrugStockTransactionResource;
    this.$mdSidenav = $mdSidenav;
  }

  $onInit () {
    this.sidenavFields = [...SIDENAV_FIELDS];
    this.columns = Object.assign({}, DEFAULT_COLUMNS);
    this.transactionTypes = Object.assign({}, DEFAULT_TRANSACTION_TYPES);
    this.transactionType = this.transactionTypes[0].id;
    this.searchGridActionCol = {
      options: {
        width: '64',
      },
      actions: [{
        name: 'View',
        callback: this.viewTransaction.bind(this),
      }],
    };

    this.options = {
      enablePagination: true,
      useExternalPagination: true,
      totalItems: 0,
      resolvePage: (pageNumber, pageAmount) => {
        this.updateData(pageNumber, pageAmount);
      },
    };

    this.DrugResource.index()
      .then(drugs => { this.drugs = drugs; })
      .catch(console.log.bind(console));
  }

  viewTransaction (transaction) {
    console.log({transaction});
    this.selectedTransaction = {
      ...transaction,
      date: moment(transaction.date).format('DD-MM-YYYY'),
      time: moment(transaction.time).format('HH:mm'),
      expiry: moment(transaction.expiry).format('DD-MM-YYYY'),
    };
    this.$mdSidenav('resource-item').open();
  }

  updateData (pageNumber, pageSize) {
    if (!this.transactionType || !this.drugId) { return; }
    if (!pageNumber) { pageNumber = 1; }

    if (this.lastDrugId !== this.drugId) { this.data = undefined; }
    this.lastDrugId = this.drugId;

    const query = {
      type: this.transactionType,
      drug: this.drugId,
      page: pageNumber,
    };

    if (pageSize) { query['page_size'] = pageSize; }

    this.DrugStockTransactionResource.index(query)
      .then(data => {
        this.options.pageNumber = pageNumber;
        this.options.totalItems = data.pagination.total;
        this.data = this.transformData(data.data);
      })
      .catch(console.log.bind(console));
  }

  transformData (data) {
    return data.map(item => {
      const timestamp = moment(item.timestamp, DATE_TIME_FORMAT).toDate();

      const transformed = {
        comments: item.comments,
        transaction_type: item.type.name,
        date: timestamp,
        time: timestamp,
        incident_number: item.prf && item.prf.incident ? item.prf.incident.incident_number : '-',
        patient_name: item.prf ? `${item.prf.first_name} ${item.prf.last_name}` : '-',
        batch_number: item.batch.batch_number,
        expiry: moment(item.batch.expires_on, DATE_TIME_FORMAT).toDate(),
        quantity: item.quantity,
        amount_given: item.amount_given != null ? item.amount_given : '-',
        amount_wasted: item.amount_wasted != null ? item.amount_wasted : '-',
        drug_stock_left_after: item.drug_stock_left_after != null ? item.drug_stock_left_after : '-',
        location_stock_left_after: item.location_stock_left_after != null ? item.location_stock_left_after : '-',
        batch_stock_left_after: item.batch_stock_left_after != null ? item.batch_stock_left_after : '-',
        location: item.location.name,
        user: item.user ? `${item.user.first_name} ${item.user.last_name}` : '-',
        approver_1: '-',
        approver_2: '-',
      };

      if (item.approval && item.approval.first_user) {
        transformed.approver_1 = `${item.approval.first_user.first_name} ${item.approval.first_user.last_name}`;
      }

      if (item.approval && item.approval.second_user) {
        transformed.approver_2 = `${item.approval.second_user.first_name} ${item.approval.second_user.last_name}`;
      }

      return transformed;
    });
  }

  clearSearchTerm () {
    this.searchTerm = '';
  }
};
