export default function ChangePasswordController ($mdDialog, CrewMemberResource) {
  'ngInject';

  this.submit = (passwords) => {
    if (this.form.$invalid) {
      return;
    }

    CrewMemberResource
      .changePassword(passwords)
      .then(() => { $mdDialog.hide(); })
      .catch(console.log.bind(console));
  };

  this.cancel = () => { $mdDialog.cancel(); };
}
