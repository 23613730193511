import L from 'leaflet';

const predefinedIcons = {
  'avoid': { iconUrl: 'assets/images/markers/avoid.svg' },
  'base': { iconUrl: 'assets/images/markers/base.svg' },
  'generic': { iconUrl: 'assets/images/markers/generic.svg' },
  'hazard': { iconUrl: 'assets/images/markers/hazard.svg' },
  'hospital': { iconUrl: 'assets/images/markers/hospital.svg' },
  'incident': { iconUrl: 'assets/images/markers/incident.svg' },
  'landing-site': { iconUrl: 'assets/images/markers/landing-site.svg' },
  'refuel-station': { iconUrl: 'assets/images/markers/refuel-station.svg' },
  'target': { iconUrl: 'assets/images/markers/target.svg' },
  'helicopter': { iconUrl: 'assets/images/icons/helicopter-icon-48px.svg' },
  'car': { iconUrl: 'assets/images/icons/car-icon-48px.svg' },
};

const defaultOpts = {
  iconSize: [36, 36],
};

export default class MapMarkerIcon {
  constructor (opts) {
    return L.icon(Object.assign({}, defaultOpts, opts));
  }

  static getAvailableIcons () {
    return Object.keys(predefinedIcons);
  }

  static predefinedUrl (icon) {
    if (!predefinedIcons[icon]) {
      throw new Error(`Icon '${icon}' does not exist.`);
    }
    return predefinedIcons[icon].iconUrl;
  }

  static predefined (icon) {
    if (!predefinedIcons[icon]) {
      throw new Error(`Icon '${icon}' does not exist.`);
    }
    return new MapMarkerIcon(Object.assign({}, defaultOpts, predefinedIcons[icon]));
  }
}
