import ResourceDialogController from 'common/resource/controllers/resourceDialogController';
import uuid from 'node-uuid';
import { copy } from 'angular';

const convertNumericValues = function (values) {
  return values.map(item => {
    if (!isNaN(item.value)) {
      item.value = parseFloat(item.value);
    }
    return item;
  });
};

const findAttributeById = function (id, array) {
  const result = array.filter(item => {
    return item.intervention_attribute.id === id;
  });
  return result.length ? result[0] : null;
};

export default class InterventionDialogController extends ResourceDialogController {
  init () {
    super.init();
    this._timeout = this.$injector.get('$timeout');
    this._scope = this.$injector.get('$rootScope').$new();

    this.bodyParts = this.relationData.bodyParts.filter(item => {
      return item.interventions.length > 0;
    });

    this.relationData.crewMembers.forEach(function (crewMember) {
      crewMember.name = crewMember.first_name + ' ' + crewMember.last_name;
      crewMember.disabled = false;
    });

    this._setupWatchers();
  }

  submit (item, addAnother) {
    if (!this.form) {
      throw new Error('Form not found.');
    }

    if (this.form.$invalid) {
      return;
    }

    if (!item.id) {
      item.id = uuid.v4();
    }
    this.Dialog.hide({ item: this._transformIntervention(item), addAnother });
  };

  delete (id) {
    this.Dialog.confirm().then(() => {
      this.Dialog.cancel(id);
    });
  }

  _setupWatchers () {
    this._watchInterventions();
    this._watchUsers();
    this._watchPerformedByThirdParty();
  }

  _watchInterventions () {
    this._scope.$watch(() => {
      return this.item ? this.item.intervention : null;
    }, (newIntervention) => {
      if (!newIntervention || !newIntervention.attributes) {
        return;
      }

      this.item.attributes = newIntervention.attributes.map(attribute => {
        if (attribute.values && attribute.values.length) {
          attribute.values = convertNumericValues(attribute.values);
        }

        if (this.item.attributes) {
          const item = findAttributeById(attribute.id, this.item.attributes);
          if (item) {
            return Object.assign({}, item, { intervention_attribute: attribute });
          }
        }

        return {
          id: uuid.v4(),
          intervention_attribute: Object.assign({}, attribute),
          intervention_attribute_value: null,
          value: null,
        };
      });
    });
  }

  _watchUsers () {
    this._scope.$watchGroup([
      () => {
        if (this.item && this.item.crew_members && this.item.crew_members[0]) {
          return this.item.crew_members[0];
        }
      },
      () => {
        if (this.item && this.item.crew_members && this.item.crew_members[1]) {
          return this.item.crew_members[1];
        }
      },
    ], (newVals, oldVals) => {
      for (let i = 0; i < newVals.length; i++) {
        this._disableSelectedCrewMember(newVals[i], oldVals[i]);
      }
    });
  }

  _watchPerformedByThirdParty () {
    this._scope.$watch(
      () => this.item ? this.item.performed_by_third_party : null,
      (newVal) => {
        if (newVal === true) {
          this.item.crew_members = [];
        }
      }
    );
  }

  _transformIntervention (model) {
    const intervention = copy(model);

    intervention.attributes.forEach(item => {
      delete item.values;
      delete item.intervention_attribute.values;
    });

    delete intervention.body_part.injuries;
    delete intervention.body_part.illnesses;
    delete intervention.body_part.interventions;

    if (intervention.crew_members) {
      Object.keys(intervention.crew_members).forEach(key => {
        delete intervention.crew_members[key].name;
        delete intervention.crew_members[key].disabled;
      });
    }

    return intervention;
  }

  _disableSelectedCrewMember (newCrewMember, oldCrewMember) {
    this._timeout(() => {
      if (newCrewMember) {
        newCrewMember.disabled = true;
      }
      if (oldCrewMember) {
        oldCrewMember.disabled = false;
      }
    });
  };
}
