export default {
  bindings: {
    routes: '<',
  },
  template: `
    <div class="c-breadcrumbs">
        <span ng-repeat="route in $ctrl.routes">
            <span ui-sref-active="active">
                <a ng-if="route.state" ui-sref="{{ route.state }}">{{ route.title }}</a>
                <span ng-if="!route.state">{{ route.title }}</span>
            </span>
            <span ng-if="!$last" class="c-breadcrumbs__chevron">
                &gt;
            </span>
        </span>
    </div>
  `,
};
