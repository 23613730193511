export default {
  bindings: {
    onClick: '&',
    label: '@',
  },
  controller: function () {},
  template: `<md-button aria-label="{{ $ctrl.label }}" class="md-icon-button" ng-click="$ctrl.onClick()">
        <md-icon md-svg-icon="assets/images/icons/minus-icon.svg" aria-label="Minus icon"></md-icon>
  </md-button>`,
};
