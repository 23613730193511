import angular from 'angular';
import ConsumableResource from './resources/consumable.resource';
import ConsumableBatchResource from './resources/consumable-batch.resource';
import ConsumableStockResource from './resources/consumable-stock.resource';
import CreateConsumableStockDialog
  from './services/create-consumable-stock-dialog/create-consumable-stock-dialog.service';
import ConsumableOrderFormDialog
  from './services/consumable-order-form-dialog/consumable-order-form-dialog.service';

const module = angular
  .module('gnaas.core.consumables', [])
  .service('CreateConsumableStockDialog', CreateConsumableStockDialog)
  .service('ConsumableOrderFormDialog', ConsumableOrderFormDialog)
  .service('ConsumableResource', ConsumableResource)
  .service('ConsumableBatchResource', ConsumableBatchResource)
  .service('ConsumableStockResource', ConsumableStockResource);

export default module.name;
