import ResourceDialog from 'common/resource/services/resourceDialog';
import ShiftCrewDialogController from './shift-crew-dialog.controller';

export default class ShiftCrewDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(ShiftCrewDialogController);
    this.setTemplateUrl('core/shifts/services/shift-crew-dialog/shift-crew-dialog.tpl.html');
  }
}
