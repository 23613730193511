import { copy } from 'angular';

class CheckboxListDialogController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog) {
    this.Dialog = Dialog;

    if (!this.locals.resource) throw new Error('Resource not provided');
    this.Resource = this.locals.resource;

    this.title = this.locals.title || 'Checkbox List';
    this.trackByKey = this.locals.trackByKey || 'id';
    this.options = this.locals.options ? copy(this.locals.options) : [];
    this.items = this.locals.items ? copy(this.locals.items) : [];
    this.parse = this.locals.parse && typeof this.locals.parse === 'function' ? this.locals.parse : (items) => items;
  }

  submit (items) {
    const data = this.parse(items);
    this.Resource
      .create(data)
      .then(() => {
        this.Dialog.hide(data);
      });
  }

  cancel () {
    this.Dialog.cancel();
  }
}

export default CheckboxListDialogController;
