export default class TranscationApprovalService {
  /**
   * @ngInject
   * @constructor
   */
  constructor (TransactionApprovalDialog, Toast, Session) {
    this._TransactionApprovalDialog = TransactionApprovalDialog;
    this._Toast = Toast;
    this._Session = Session;
  }

  attempt (createFn, input) {
    const successToast = this._showSuccessToast.bind(this);

    return createFn(input)
      .then(successToast)
      .catch(err => {
        const errors = err.data.errors;

        if (errors && errors.hasOwnProperty('stock_transaction_approval_id')) {
          const approvalId = errors.stock_transaction_approval_id[0];
          const userEmails = { first: this._Session.user().email };

          return this._TransactionApprovalDialog
            .show({ approvalId, userEmails })
            .then(() => {
              input.stock_transaction_approval_id = approvalId;
              return createFn(input).then(successToast);
            });
        }

        throw err;
      });
  }

  _showSuccessToast () {
    this._Toast.showSimple('Stock transaction successful.');
  }
}
