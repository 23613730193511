import { format as formatCrewMember } from 'pages/crewMembers/services/crewMemberResource';
import { format as formatDrug } from 'pages/admin/drugs/resources/drug.resource';
import { toDateTimeObject } from 'common/utils/services/dateTime';

export default function format (item) {
  const dateTime = toDateTimeObject(item.time);
  return {
    id: item.id,
    dose: parseFloat(item.dose),
    time: dateTime,
    date: dateTime,
    drug: formatDrug(item.drug.data),
    min_rate: parseFloat(item.min_rate),
    max_rate: parseFloat(item.max_rate),
    route: item.route.data,
    prescribed_by: formatCrewMember(item.prescribedBy.data),
  };
}
