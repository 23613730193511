import { noop } from 'angular';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';
import moment from 'moment';

export default class DeploymentListDialogController extends ResourceDialogController {
  init () {
    super.init();

    this.deploymentStatuses = this.deploymentStatuses.map(item => {
      item.name = item.deployment_status.name;
      item.timeString = moment(item.time).format('HH:mm');
      return item;
    });

    this.fields = {
      timeString: 'Time',
      name: 'Deployment Status',
    };

    if (typeof this.showEditDialog !== 'function') {
      this.showEditDialog = noop;
    }
  }
}
