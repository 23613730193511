import RestResource from 'common/resource/services/restResource';
import { format as formatDrug } from 'pages/admin/drugs/resources/drug.resource';

export function format (item) {
  item.dose_per_unit = parseFloat(item.dose_per_unit);
  if (item.drug) { item.drug = formatDrug(item.drug.data); }
  if (item.location) { item.location = item.location.data; }
  return item;
}

export default class DrugBatchesResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'drug-batches';
  }

  format (item) {
    return format(item);
  }
}
