import uuid from 'node-uuid';

export default class CreateDeploymentStatusController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog, $scope, $timeout) {
    this._Dialog = Dialog;
    this._scope = $scope;
    this._timeout = $timeout;

    this.ID = uuid.v4();

    this.includePath = 'pages/admin/categories/templates/forms/deployment-status.tpl.html';

    this._scope.$watch(
      () => this.categoryData,
      (newVal) => {
        if (!newVal) return;
        if (newVal.is_at_hospital) {
          this.categoryData.has_destination = true;
        }
      }
    );
  }

  submit (categoryData) {
    if (this.form.$invalid) {
      return;
    }

    if (this.editing) {
      this._update(categoryData);
    } else {
      this._create(categoryData);
    }
  };

  cancel () {
    this._Dialog.cancel();
  };

  delete (id) {
    this._Dialog.confirm().then(() => {
      this.resource.destroy(id).then(() => {
        this._Dialog.cancel(id);
      });
    });
  };

  _create (categoryData) {
    categoryData.id = this.ID;

    this.resource.create(categoryData).then(() => {
      this._Dialog.hide(categoryData);
    }).catch(console.log.bind(console));
  };

  _update (categoryData) {
    this.resource.update(categoryData.id, categoryData).then((category) => {
      this._Dialog.hide(category);
    }).catch(console.log.bind(console));
  };
}
