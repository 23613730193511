export const oxygenOptions = [
  { label: '-', value: null, modes: [] },
  { label: 'FiO2', value: 'fio2', modes: [ 'cpap', 'ippv-spont', 'ippv-mand', 'niv' ] },
  { label: 'L/min', value: 'l-min', modes: [ 'sv', 'fm', 'nc' ] },
  { label: '%', value: 'pct', modes: [ 'hfno' ] },
];

const ventilationFields = [
  { name: 'respiratory_rate', modes: [ 'sv', 'fm', 'nc', 'hfno', 'cpap', 'ippv-spont', 'ippv-mand', 'niv' ] },
  { name: 'spo2', modes: [ 'sv', 'fm', 'nc', 'hfno', 'cpap', 'ippv-spont', 'ippv-mand', 'niv' ] },
  { name: 'etco2', modes: [ 'ippv-spont', 'ippv-mand', 'niv' ] },
  { name: 'vt', modes: [ 'ippv-mand' ] },
  { name: 'mv', modes: [ 'cpap', 'ippv-mand' ] },
  { name: 'flow', modes: [ 'hfno' ] },
  { name: 'fio2', modes: [ 'cpap' ] },
  { name: 'ppeak', modes: [ 'ippv-mand' ] },
  { name: 'peep', modes: [ 'ippv-mand' ] },
  { name: 'psupp', modes: [ 'ippv-spont', 'niv' ] },
  { name: 'cpap', modes: [ 'cpap', 'ippv-spont', 'niv' ] },
  { name: 'ti', modes: [ 'ippv-mand' ] },
];

/**
 * This function is used to define which fields should be disabled
 * and which ones should be kept enabled
 */
export function applyVentilationIntelligence (obs) {
  obs.disabledFields.length = 0;

  if (obs.breathing_mode === null) {
    obs.oxygen = null;
    return true;
  }

  const option = oxygenOptions.find(option => option.modes.includes(obs.breathing_mode));
  obs.oxygen = option.value;

  ventilationFields.forEach((vField) => {
    if (!vField.modes.includes(obs.breathing_mode)) {
      obs[vField.name] = null;
      obs.disabledFields.push(vField.name);
    }
  });
};
