import uuid from 'node-uuid';
import InterventionAttributeValuesController from './intervention-attribute-values.controller';

export default class CreateInterventionController {
  constructor (Utils, Dialog) {
    'ngInject';
    this._Utils = Utils;
    this._Dialog = Dialog;

    this.includePath = 'pages/admin/categories/templates/forms/intervention.tpl.html';

    if (!this.categoryData) {
      this.categoryData = {
        id: uuid.v4(),
        attributes: [],
      };
    }
  }

  addAttribute () {
    this.categoryData.attributes.push({
      id: uuid.v4(),
    });
  }

  removeAttribute (item) {
    this._Utils.removeFromArray(this.categoryData.attributes, item);
  }

  showAttributeValuesDialog ($event, attribute) {
    let dialogParams = {
      controller: InterventionAttributeValuesController,
      locals: {
        attribute: attribute,
      },
      targetEvent: $event,
      templateUrl: 'pages/admin/categories/templates/forms/intervention-attribute-values.tpl.html',
      multiple: true,
    };
    this._Dialog.show(dialogParams);
  }

  submit (categoryData) {
    if (this.form.$invalid) {
      return;
    }

    if (this.editing) {
      this._update(categoryData);
    } else {
      this._create(categoryData);
    }
  }

  cancel () {
    this._Dialog.cancel();
  };

  delete (id) {
    this._Dialog.confirm().then(() => {
      this.resource.destroy(id).then(() => {
        this._Dialog.cancel(id);
      });
    });
  }

  _create (categoryData) {
    this.resource.create(categoryData).then(() => {
      this._Dialog.hide(categoryData);
    }).catch(console.log.bind(console));
  }

  _update (categoryData) {
    this.resource.update(categoryData.id, categoryData).then((category) => {
      this._Dialog.hide(category);
    }).catch(console.log.bind(console));
  }
}
