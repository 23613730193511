export default function decimalValidator () {
  return {
    replace: false,
    require: 'ngModel',
    restrict: 'A',
    link: function ($scope, $element, $attrs, $ctrl) {
      $element.on('blur', () => {
        if (/^[0-9]{1,6}\.$/.test($ctrl.$modelValue)) {
          const newValue = $ctrl.$modelValue.replace('.', '');
          $ctrl.$setViewValue(newValue);
          $ctrl.$render();
        }
      });

      $ctrl.$parsers.push(input => {
        if (!input || /^[0-9]{1,6}(\.)?([0-9]{1,2})?$/.test(input)) return input;
        $ctrl.$setViewValue($ctrl.$modelValue);
        $ctrl.$render();
        return $ctrl.$modelValue;
      });

      $ctrl.$formatters.push(value => {
        return parseFloat(value);
      });
    },
  };
};
