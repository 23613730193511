import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class VehicleCallsignStatusUpdateDialogController extends ResourceDialogController {
  init () {
    super.init();
    this._resource = this.$injector.get('VehicleCallsignResource');
  }

  create (formData) {
    this._resource.createStatus(formData.vehicleCallsign.id, formData).then(statusUpdate => {
      this.Dialog.hide(statusUpdate);
    });
  }

  update (formData) {
    this._resource.updateStatus(formData.vehicleCallsign.id, formData.id, formData).then(statusUpdate => {
      this.Dialog.hide(statusUpdate);
    });
  }

  delete (formData) {
    this.Dialog.confirm().then(() => {
      this._resource.deleteStatus(formData.vehicleCallsign.id, formData.id).then(() => {
        this.Dialog.cancel(formData.id);
      });
    });
  }
}
