export default class ResolveIncidentDialogController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog, FlaggedIncidentResource) {
    this.Dialog = Dialog;
    this.Resource = FlaggedIncidentResource;
  }

  submit (formData) {
    if (!this.form || this.form.$invalid) {
      return;
    }

    this.Dialog.confirm('Are you sure you want to resolve this flag?').then(() => {
      formData.resolved = true;
      formData.resolved_on_time = new Date();

      this.Resource
        .update(formData.id, formData, { include: 'incident,flaggedBy,resolvedBy',})
        .then(incident => {
          this.Dialog.hide(incident);
        });
    });
  }

  cancel () {
    this.Dialog.cancel();
  }
}
