import ResourceDialog from 'common/resource/services/resourceDialog';
import DrugOrderFormDialogDialogController from './drug-order-form-dialog.controller';

export default class IncidentDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(DrugOrderFormDialogDialogController);
    this.setTemplateUrl('core/drugs/services/drug-order-form-dialog/drug-order-form-dialog.tpl.html');
  }
}
