import angular from 'angular';
import categoriesModule from './categories/module';
import consumablesModule from './consumables/module';
import deploymentModule from './deployments/module';
import deviceModule from './devices/module';
import drugsModule from './drugs/module';
import equipmentLocationModule from './equipment-locations/module';
import incidentModule from './incidents/module';
import prfModule from './prf/module';
import reportsModule from './reports/module';
import searchModule from './search/module';
import shiftsModule from './shifts/module';
import vehiclesModule from './vehicles/module';

const module = angular.module('gnaas.core', [
  categoriesModule,
  consumablesModule,
  deploymentModule,
  deviceModule,
  drugsModule,
  equipmentLocationModule,
  incidentModule,
  prfModule,
  reportsModule,
  searchModule,
  shiftsModule,
  vehiclesModule,
]);

export default module.name;
