import angular from 'angular';
import CompareTo from './directives/compareTo';
import PasswordValidator from './directives/passwordValidator';
import FormCheckbox from './directives/formCheckbox';
import FormCheckboxList from './directives/formCheckboxList';
import FormDate from './directives/formDate';
import FormInput from './directives/formInput';
import FormLabel from './directives/formLabel';
import FormRadioGroup from './directives/formRadioGroup';
import FormSelect from './directives/formSelect';
import FormTextarea from './directives/formTextarea';

const module = angular
  .module('gnaas.common.form', [])
  .directive('compareTo', CompareTo)
  .directive('passwordValidator', PasswordValidator)
  .directive('formCheckbox', FormCheckbox)
  .directive('formCheckboxList', FormCheckboxList)
  .directive('formDate', FormDate)
  .directive('formInput', FormInput)
  .directive('formLabel', FormLabel)
  .directive('formRadioGroup', FormRadioGroup)
  .directive('formSelect', FormSelect)
  .directive('formTextarea', FormTextarea);

export default module.name;
