import RestResource from 'common/resource/services/restResource';
import { copy } from 'angular';
import { DATE_FORMAT } from 'common/resource/module.constants';
import moment from 'moment';
import { format as formatUser } from 'pages/crewMembers/services/crewMemberResource';
import { format as formatDrug } from 'pages/admin/drugs/resources/drug.resource';

export function format (item) {
  const formatted = {
    id: item.id,
    date: moment(item.date),
    controlled: item.controlled,
    discrepancy_count: item.discrepancy_count,
  };

  if (item.stockLocation) {
    formatted.stock_location = item.stockLocation.data;
  }

  if (item.user) {
    formatted.user = formatUser(item.user.data);
  }

  if (item.stockCheckItems) {
    formatted.drug_stock_check_items = item.stockCheckItems.data.map(item => {
      item.resolved = !!item.resolved;
      item.drug = formatDrug(item.drug.data);
      return item;
    });
  }

  return formatted;
}

export function parse (item) {
  const parsed = {
    id: item.id,
    stock_location_id: item.stock_location_id,
    controlled: item.controlled,
    date: moment(item.date).format(DATE_FORMAT),
    drug_stock_check_items: item.drug_stock_check_items.map(item => {
      const i = copy(item);
      i.drug_id = i.drug.id;
      delete i.drug;
      return i;
    }),
  };

  if (item.first_user) {
    parsed.first_user = item.first_user;
  }

  if (item.second_user) {
    parsed.second_user = item.second_user;
  }

  return parsed;
}

export default class DrugStockCheckResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'drug-stock-checks';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
