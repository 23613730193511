import { copy } from 'angular';

/**
 * @abstract
 */
export default class ResourceDialog {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($injector, Dialog) {
    if (this.constructor === ResourceDialog) {
      throw new Error('Cannot construct an abstract class.');
    }
    this.$injector = $injector;
    this._Dialog = Dialog;
    this._dialogOptions = {
      locals: {},
    };
    this.init();
  }

  init () {}

  setController (controller) {
    this._dialogOptions.controller = controller;
  }

  setTemplateUrl (templateUrl) {
    this._dialogOptions.templateUrl = templateUrl;
  }

  setTemplate (template) {
    this._dialogOptions.template = template;
  }

  show ({$event, item, relationData, locals, multiple} = {}) {
    const dialogParams = {
      locals: {
        editing: typeof item !== 'undefined' && item != null,
        item: copy(item),
        relationData: relationData,
      },
      targetEvent: $event || null,
      multiple: !!multiple,
    };
    if (locals && typeof locals === 'object') {
      Object.assign(dialogParams.locals, locals);
    }
    return this._Dialog.show(Object.assign({}, this._dialogOptions, dialogParams));
  }
}
