export default function JwtInterceptor () {
  let refreshInProgress = false;
  let requestBuffer = [];

  const isProtectedState = (stateName) => {
    return ['', 'login', 'passwordReset'].indexOf(stateName) === -1;
  };

  this.$get = function (
    $injector,
    $q,
    API_URL,
    JWT,
    Session
  ) {
    'ngInject';

    let $http, $state, AuthResource, Dialog, IdleCheck;

    const isAuthRequest = (config) => {
      const method = config.method.toUpperCase();
      return ~['PUT', 'POST'].indexOf(method) && config.url === `${API_URL}auth` ||
        method === 'GET' && ~config.url.indexOf(`${API_URL}auth/check`);
    };

    const retryRequests = () => {
      while (requestBuffer.length) {
        const { config, deferred } = requestBuffer.shift();
        $http(config).then(data => deferred.resolve(data)).catch(err => deferred.reject(err));
      }
    };

    const refreshToken = (config) => {
      refreshInProgress = true;
      $http = $http || $injector.get('$http');
      AuthResource = AuthResource || $injector.get('AuthResource');
      IdleCheck = IdleCheck || $injector.get('IdleCheck');

      const deferred = $q.defer();

      AuthResource
        .refresh()
        .then(data => {
          refreshInProgress = false;
          JWT.set(data.meta.token);
          IdleCheck.start();
          retryRequests();
          return $http(config);
        })
        .then(data => {
          deferred.resolve(data);
        })
        .catch(err => {
          refreshInProgress = false;
          deferred.reject(err);
        });

      return deferred.promise;
    };

    const clearData = () => {
      $state = $state || $injector.get('$state');
      Dialog = Dialog || $injector.get('Dialog');
      IdleCheck = IdleCheck || $injector.get('IdleCheck');

      JWT.destroy();
      Session.destroy();
      IdleCheck.stop();
      Dialog.cancel();
      if (isProtectedState($state.current.name)) {
        $state.go('login', { previousState: { ...$state.current, params: $state.params } });
      }
    };

    return {
      request: config => {
        if (config.url.indexOf(API_URL) === 0) {
          config.headers['Authorization'] = 'Bearer ' + JWT.get();
        }
        return config;
      },
      responseError: err => {
        if (err.status === 401) {
          if (!isAuthRequest(err.config)) {
            if (refreshInProgress) {
              const deferred = $q.defer();
              requestBuffer.push({ config: err.config, deferred });
              return deferred.promise;
            }
            return refreshToken(err.config);
          }
          clearData();
        }

        return $q.reject(err);
      },
    };
  };
};
