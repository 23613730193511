import ResourceDialog from 'common/resource/services/resourceDialog';
import ClinicalIncidentDialogController from './clinical-incident-dialog.controller';

export default class ClinicalIncidentDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(ClinicalIncidentDialogController);
    this.setTemplateUrl('core/prf/services/clinical-incident-dialog/clinical-incident-dialog.tpl.html');
  }
}
