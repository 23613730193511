import {copy} from 'angular';

export class CategoryItemsController {
  constructor (Toast, Utils, Dialog) {
    'ngInject';

    this._Toast = Toast;
    this._Utils = Utils;
    this._Dialog = Dialog;
  }

  $onInit () {
    this.items = [];
    this.category.resource.index(this.category.query || {}).then(categoryData => {
      this.items = categoryData;
    });
    this.relationData = {};
    if (this.category.relationData) {
      Object.keys(this.category.relationData).forEach((key) => {
        let resource = this.category.relationData[key].resource;
        let method = this.category.relationData[key].method;
        resource[method]().then(data => { this.relationData[key] = data; });
      });
    }
  }

  showCreateDialog (evt, categoryData) {
    let editing = !!categoryData;

    let dialogParams = {
      controller: this.category.controller || 'CreateCategoryController',
      locals: {
        categoryData: copy(categoryData), // copy to avoid passing by reference
        editing: editing,
        title: this.category.labels.single,
        resource: this.category.resource,
        relationData: this.relationData,
        multiSelections: this.category.multiSelections,
        form: this.category.form || 'default.tpl.html',
        includeQuery: this.category.include ? { include: this.category.include } : {},
      },
      targetEvent: evt,
      templateUrl: 'pages/admin/categories/templates/create-category-dialog.tpl.html',
    };

    this._Dialog.show(dialogParams).then((categoryItem) => {
      if (editing) {
        let item = this._Utils.findObjectInArrayById(this.items, categoryItem.id);
        Object.assign(item, categoryItem);
        this._Toast.showSimple(`${this.category.labels.single} updated.`);
      } else {
        this.items.push(categoryItem);
        this._Toast.showSimple(`New ${this.category.labels.single} created.`);
      }
    }).catch((categoryItemId) => {
      if (categoryItemId) {
        let item = this._Utils.findObjectInArrayById(this.items, categoryItemId);
        this._Utils.removeFromArray(this.items, item);
        this._Toast.showSimple(`${this.category.labels.single} deleted.`);
      }
    });
  }
}

export default {
  bindings: {
    category: '<',
  },
  controller: CategoryItemsController,
  templateUrl: 'pages/admin/categories/components/category-items/category-items.tpl.html',
};
