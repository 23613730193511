export default {
  bindings: {
    events: '<',
    day: '<',
    onEventClick: '&?',
  },
  controller: function () {
    this.clickActionPresent = typeof this.onEventClick === 'function';
  },
  templateUrl: 'pages/calendar/templates/calendarEvents.tpl.html',
};
