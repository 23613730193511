import moment from 'moment';
import { copy } from 'angular';
import { DATE_TIME_FORMAT } from 'common/resource/module.constants';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class ManualAdjustmentController extends ResourceDialogController {

  init () {
    super.init();

    this.StockLocationResource = this.$injector.get('StockLocationResource');
    this.DrugStockTransactionResource = this.$injector.get('DrugStockTransactionResource');
    this.DrugBatchesResource = this.$injector.get('DrugBatchesResource');
    this.TransactionApprovalService = this.$injector.get('TransactionApprovalService');

    this.StockLocationResource.index().then(items => { this.stockLocations = items; });

    this.item = {
      timestamp: moment().startOf('minute').toDate(),
    };

    this._createWatchers();
  }

  create (input) {
    this.TransactionApprovalService
      .attempt(this._createTransaction.bind(this), input)
      .then(data => {
        this.Dialog.hide(data);
      });
  }

  onBatchIdChange ({name, value}) {
    const arr = this.drugMap[this.item.drug_id].expiryMap[this.item.expires_on];
    const found = arr.filter(item => item.id === value);
    this.item.quantity = found[0].available_quantity;
    this.maxQuantity = found[0].initial_quantity;
  }

  onSelectChange ({ name }) {
    if (name === 'stock_location_id') {
      this.item.drug_id = null;
      this.item.expires_on = null;
      this.item.batch_id = null;
    }
    this.item.quantity = null;
  }

  _createTransaction (input) {
    const data = this._parseData(input);
    return this.DrugStockTransactionResource.create(data);
  }

  _createWatchers () {
    this.scope = this.$injector.get('$rootScope').$new();
    this.scope.$watch(
      () => this.stockLocationId,
      (newVal, oldVal) => {
        if (newVal === oldVal) return;
        this._stockLocationChanged(newVal);
      }
    );
  }

  _stockLocationChanged (stockLocationId) {
    this.DrugBatchesResource
      .index({ location_id: stockLocationId, include: 'drug' })
      .then(drugBatches => {
        this.drugMap = this._mapDrugs(drugBatches);
        this.drugs = Object.values(this.drugMap).map(item => { return { id: item.id, name: item.name }; });
      })
      .catch(console.log.bind(console));
  }

  _mapDrugs (batches) {
    return batches.reduce((acc, item) => {
      const drugId = item.drug.id;
      if (!acc.hasOwnProperty(drugId)) {
        acc[drugId] = { id: drugId, name: item.drug.name, expiryMap: {}, expiries: [] };
      }

      const expiryMap = acc[drugId].expiryMap;
      const expiries = acc[drugId].expiries;
      const expiry = item.expires_on.split(' ')[0];
      if (!expiryMap.hasOwnProperty(expiry)) {
        expiryMap[expiry] = [];
        expiries.push(expiry);
      }

      expiryMap[expiry].push(item);

      return acc;
    }, {});
  }

  _parseData (data) {
    const d = copy(data);

    d.transaction_type = 'manual';
    d.timestamp = moment(d.timestamp).format(DATE_TIME_FORMAT);
    delete d.expires_on;
    delete d.drug_id;

    return d;
  }
}
