import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ModuleConfig from './module.config';
import ShiftsPage from './components/shifts-page/shifts-page';

const module = angular
  .module('gnaas.pages.shifts', [
    uiRouter,
  ])
  .config(ModuleConfig)
  .component('shiftsPage', ShiftsPage);

export default module.name;
