import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class ColumnsToDisplayController extends ResourceDialogController {
  init () {
    super.init();
    if (!this.selected && this.query.columnsToDisplay) {
      this.selected = this.query.columnsToDisplay.map(item => item);
    }
  }

  submit (data) {
    if (this.form.$invalid) {
      return false;
    }
    this.Dialog.hide(data);
  }
}
