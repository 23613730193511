export default function ($stateProvider) {
  'ngInject';

  $stateProvider
    .state('admin.reports', {
      abstract: true,
      url: '/reports',
      template: '<div ui-view layout="column" flex></div>',
    })
    .state('admin.reports.reportables', {
      url: '/reportables/:type',
      controllerAs: '$ctrl',
      template: '<reportables-page layout="column" flex></reportables-page>',
      data: {
        title: 'Reportable Items',
      },
    });
};
