import { copy } from 'angular';
import CreateCalendarAgendaController from 'pages/calendar/controllers/createCalendarAgendaController';

export const CALENDAR_DAY_SIDENAV = 'sidenav-calendar-day';

export class CalendarAgendaController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($mdSidenav, Dialog, Toast) {
    this._mdSidenav = $mdSidenav;
    this._Dialog = Dialog;
    this._Toast = Toast;
  }

  close () {
    this._mdSidenav(CALENDAR_DAY_SIDENAV).close();
  }

  showAgendaDialog (evt, agenda) {
    let editing = !!agenda;

    let dialogParams = {
      controller: CreateCalendarAgendaController,
      editing: editing,
      targetEvent: evt,
      templateUrl: 'pages/calendar/templates/createCalendarAgendaDialog.tpl.html',
      item: copy(agenda),
      date: this.day,
    };

    this._Dialog.show(dialogParams).then((item) => {
      this.day.agenda = item;
    }).catch((id) => {
      if (id) {
        this.day.agenda = null;
      }
    });
  }
}

export default {
  bindings: {
    day: '<',
    onAddEventClick: '&',
  },
  controller: CalendarAgendaController,
  templateUrl: 'pages/calendar/templates/calendarDay.tpl.html',
};
