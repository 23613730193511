/* global Blob, URL */
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class DrugOrderFormDialogDialogController extends ResourceDialogController {
  init () {
    super.init();
    this.StockLocationResource = this.$injector.get('StockLocationResource');
    this.ConsumableTypeResource = this.$injector.get('ConsumableTypeResource');
    this.ConsumableStockResource = this.$injector.get('ConsumableStockResource');

    this.StockLocationResource.index().then(items => { this.stockLocations = items; });
    this.ConsumableTypeResource.index({ include: 'consumables' }).then(items => { this.consumableTypes = items; });

    this.item = {
      stock_locations: [],
      consumables: [],
    };
  }

  submit (data) {
    if (this.form.$invalid || this.item.stock_locations.length === 0 || this.item.consumables.length === 0) {
      return;
    }

    this.ConsumableStockResource.generateOrderForms(this._parseData(data)).then(pdf => {
      const file = new Blob([pdf], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.Dialog.hide();
    });
  }

  addItem () {
    this.item.consumables = [...this.item.consumables, {}];
  }

  removeItem (index) {
    this.item.consumables = [...this.item.consumables.slice(0, index), ...this.item.consumables.slice(index + 1)];
  }

  _parseData (data) {
    return {
      stock_location_ids: data.stock_locations.map(i => i.id),
      consumables: data.consumables.map(i => ({ id: i.consumable_id, quantity: i.quantity })),
      comments: data.comments,
    };
  }
}
