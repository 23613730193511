import CreateBaseController from './createBaseController';

export default function BasesResourceController (BaseResource) {
  'ngInject';

  this.singularName = 'Base';
  this.pluralName = 'Bases';

  this.createDialogController = CreateBaseController;
  this.createDialogTemplateUrl = 'pages/admin/bases/templates/createDialog.tpl.html';

  BaseResource.index().then(items => { this.items = items; });

  this.listFields = {
    name: 'Name',
    abbreviation: 'Abbreviation',
  };

  this.hasProfiles = false;
};
