class EquipmentLocationListController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($state, EquipmentLocationDialog, EquipmentLocationResource) {
    this.$state = $state;
    this.EquipmentLocationDialog = EquipmentLocationDialog;
    this.EquipmentLocationResource = EquipmentLocationResource;
  }

  $onInit () {
    this.listFields = { name: 'Name' };
    this.EquipmentLocationResource.index().then(items => { this.items = items; });
  }

  showCreateDialog ($event) {
    this.EquipmentLocationDialog
      .show({ $event })
      .then(data => { this.goTo(data.id); });
  }

  listButtonAction (event, item) {
    this.goTo(item.id);
  }

  goTo (id) {
    return this.$state.go('admin.equipmentLocations.show', { id: id });
  }
}

export default {
  controller: EquipmentLocationListController,
  templateUrl: 'pages/admin/equipment-locations/components/equipment-location-list/equipment-location-list.tpl.html',
};
