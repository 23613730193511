import angular from 'angular';
import Breadcrumbs from './components/breadcrumbs';
import SidenavMenu from './components/sidenavMenu';
import SidenavSubmenu from './components/sidenavSubmenu';
import Topbar from './components/topbar';

const module = angular
  .module('gnaas.common.navigation', [])
  .component('breadcrumbs', Breadcrumbs)
  .component('sidenavMenu', SidenavMenu)
  .component('sidenavSubmenu', SidenavSubmenu)
  .component('topbar', Topbar);

export default module.name;
