import uuid from 'node-uuid';
import { VEHICLE_TYPES_REVERSE } from 'pages/admin/vehicles/module.constants';

export default class CreateVehicleController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (VehicleResource, Dialog) {
    this._VehicleResource = VehicleResource;
    this._Dialog = Dialog;
    this.ID = uuid.v4();
  }

  submit (data) {
    if (this.form.$invalid) {
      return;
    }

    if (this.editing) {
      this._update(data);
    } else {
      this._create(data);
    }
  };

  cancel () {
    this._Dialog.cancel();
  };

  delete (id) {
    this._Dialog.confirm().then(() => {
      this._VehicleResource.destroy(id).then(() => {
        this._Dialog.cancel(id);
      });
    });
  };

  _create (data) {
    data.id = this.ID;
    this._VehicleResource.create(data).then(() => {
      data.typeString = VEHICLE_TYPES_REVERSE[data.type];
      this._Dialog.hide(data);
    }).catch(console.log.bind(console));
  };

  _update (data) {
    this._VehicleResource.update(data.id, data).then((item) => {
      this._Dialog.hide(item);
    }).catch(console.log.bind(console));
  };
}
