import moment from 'moment';

export default function format (item) {
  return Object.keys(item).reduce((acc, cur) => {
    if (cur === 'time') {
      acc[cur] = moment(item[cur]).toDate();
    } else if (item[cur] != null && !isNaN(item[cur])) {
      acc[cur] = parseFloat(item[cur]);
    } else {
      acc[cur] = item[cur];
    }

    return acc;
  }, {});
};
