import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ModuleConfig from './module.config';

import ReportablesPage from './components/reportables-page/reportables-page.component';

const module = angular
  .module('gnaas.pages.admin.reports', [
    uiRouter,
  ])
  .config(ModuleConfig)
  .component('reportablesPage', ReportablesPage);

export default module.name;
