import RestResource from 'common/resource/services/restResource';

export function format (item) {
  let formatted = {
    id: item.id,
    name: item.name,
    lat: item.lat,
    lng: item.lng,
  };

  if (item.locationType) {
    formatted.location_type = item.locationType.data;
  }

  return formatted;
}

export function parse (item) {
  let parsed = {
    id: item.id || null,
    name: item.name,
    lat: item.lat,
    lng: item.lng,
  };

  if (item.location_type) {
    parsed.location_type_id = item.location_type.id;
  }

  return parsed;
}

export default class LocationResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'locations';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
