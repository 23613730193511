import RestResource from 'common/resource/services/restResource';
import { formatCompartmentItem } from 'core/equipment-locations/resources/equipment-location.resource';

export function format (item) {
  const i = {
    id: item.id,
    name: item.name,
  };

  if (item.type) {
    i.type = item.type.data;
  }

  if (item.stockLocationProperties) {
    i.stock_location_properties = item.stockLocationProperties.data.map(properties => {
      properties.supplier = properties.supplier.data;
      properties.stock_location = properties.stockLocation.data;
      delete properties.stockLocation;
      return properties;
    });
  }

  if (item.equipmentLocationCompartmentItems) {
    i.equipment_location_compartment_items = item.equipmentLocationCompartmentItems.data.map(formatCompartmentItem);
  }

  if (item.productCodes) {
    i.product_codes = item.productCodes.data.map(pc => {
      if (pc.supplier) pc.supplier = pc.supplier.data;
      return pc;
    });
  }

  return i;
}

export function parse (item) {
  const i = {
    id: item.id,
    name: item.name,
  };

  if (item.type) {
    i.consumable_type_id = item.type.id;
  }

  if (item.stock_location_properties) {
    i.stock_location_properties = item.stock_location_properties.map(prop => {
      const copy = Object.assign({}, prop);
      copy.stock_location_id = copy.stock_location.id;
      copy.supplier_id = copy.supplier.id;
      delete copy.stock_location;
      delete copy.supplier;
      return copy;
    });
  }

  if (item.product_codes) {
    i.product_codes = item.product_codes.map(pc => ({
      id: pc.id,
      product_code: pc.product_code,
      supplier_id: pc.supplier.id,
    }));
  }

  return i;
}

export default class ConsumableResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'consumables';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
