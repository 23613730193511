import angular from 'angular';
import uiRouter from 'angular-ui-router';
import VehicleCallsignResourceController from './controllers/vehicleCallsignResourceController';
import VehicleCallsignResource from './services/vehicleCallsignResource';
import VehicleCallsignStatusUpdateResource from './services/vehicleCallsignStatusUpdateResource';

const moduleConfig = function ($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.vehicleCallsigns', {
    url: '/vehicle-callsigns',
    controller: VehicleCallsignResourceController,
    controllerAs: '$ctrl',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    data: {
      title: 'Vehicle Callsigns',
    },
  });
};

const module = angular
  .module('gnaas.pages.admin.callsigns', [
    uiRouter,
  ])
  .config(moduleConfig)
  .service('VehicleCallsignResource', VehicleCallsignResource)
  .service('VehicleCallsignStatusUpdateResource', VehicleCallsignStatusUpdateResource);

export default module.name;
