import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class DrugStockCheckDialogController extends ResourceDialogController {
  init () {
    super.init();
    this.DrugStockCheckItemResource = this.$injector.get('DrugStockCheckItemResource');
  }

  submit (formData) {
    if (this.form.$invalid) return;
    this.DrugStockCheckItemResource.update(this.item.id, formData).then(item => { this.Dialog.hide(item); });
  }
}
