import MapMarkerIcon from 'common/map/services/mapMarkerIcon';

const MAP_MARKER_FILTER_SIDENAV = 'sidenav-map-marker-filters';

export class MapFilterSidenavController {
  constructor ($mdSidenav, LocationTypeResource) {
    'ngInject';
    this._mdSidenav = $mdSidenav;
    this._LocationTypeResource = LocationTypeResource;
  }

  $onInit () {
    this._setupFilters();
  }

  close () {
    this._mdSidenav(MAP_MARKER_FILTER_SIDENAV).close();
  }

  filter () {
    let markers = this.filters.filter(item => item.show).map(item => item.type);
    this.onUpdate({visibleMarkers: markers});
  }

  clearFilters () {
    this.filters.forEach((filter) => {
      filter.show = true;
    });
    this.filter();
  }

  _setupFilters () {
    let availableIcons = MapMarkerIcon.getAvailableIcons();

    this.filters = [
      {
        name: 'Hospitals',
        icon: MapMarkerIcon.predefinedUrl('hospital'),
        type: 'hospital',
        show: true,
      },
      {
        name: 'Bases',
        icon: MapMarkerIcon.predefinedUrl('base'),
        type: 'base',
        show: true,
      },
    ];

    this._LocationTypeResource.index().then((locationTypes) => {
      this.filters = this.filters.concat(locationTypes.map((item) => {
        let iconType = availableIcons.indexOf(item.slug) > -1 ? item.slug : 'target';
        item.icon = MapMarkerIcon.predefinedUrl(iconType);
        item.type = item.slug;
        item.show = true;
        return item;
      }));
    });
  }
}

export default {
  bindings: {
    onUpdate: '&',
  },
  controller: MapFilterSidenavController,
  templateUrl: 'pages/map/templates/mapFilterSidenav.tpl.html',
};
