import moment from 'moment';
import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class PatientDetailsDialogController extends ResourceDialogController {
  init () {
    super.init();
    this._resource = this.$injector.get('PRFResource');
    this._state = this.$injector.get('$state');
    this.maxDateOfBirth = moment().toDate();
    this._ID = uuid.v4();
    this.genders = {
      'Female': 'female',
      'Male': 'male',
      'Other': 'other',
      'Unknown': 'unknown',
    };
    this.booleanEnumValues = [{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }];
    this.allergiesStatusValues = [
      { name: 'No known allergies', value: 'no_known_allergies' },
      { name: 'Allergy status unknown', value: 'allergy_status_unknown' },
      { name: 'Has known allergies', value: 'has_known_allergies' },
    ];
    this.infectionControlIssuesStatusValues = [
      { name: 'Yes', value: 'yes' },
      { name: 'No', value: 'no' },
      { name: 'Colonised', value: 'colonised' },
    ];

    this.selectedInfectionStatuses = [];
    if (this.item && this.item.infectionStatuses) {
      this.selectedInfectionStatuses = this.item.infectionStatuses;
    }
  }

  create (data) {
    data.id = this._ID;
    data.incident = this.incident;
    data.deployment = this.deployment;
    data.date = this.incident.date;
    data.infectionStatuses = this.selectedInfectionStatuses;

    data.nhs_number = data.nhs_number || null;
    data.incident_description = data.incident_description || null;
    data.handover_notes = data.handover_notes || null;
    data.allergies_status = data.allergies_status || null;
    data.allergies = data.allergies || null;
    data.address = data.address || null;
    data.weight = data.weight || null;
    data.regular_medication = data.regular_medication || null;
    data.infection_control_issues_status = data.infection_control_issues_status || null;
    data.infection_control_issues = data.infection_control_issues || null;
    data.nok_name = data.nok_name || null;
    data.nok_contact_no = data.nok_contact_no || null;
    data.known_to_social_care = data.known_to_social_care || null;
    data.social_worker_name = data.social_worker_name || null;
    data.social_worker_contact_no = data.social_worker_contact_no || null;
    data.safeguarding_concerns = data.safeguarding_concerns || null;
    data.safeguarding_concerns_notes = data.safeguarding_concerns_notes || null;

    this._resource.create(data).then(() => {
      this.Dialog.hide(data);
    }).catch(console.log.bind(console));
  }

  update (data) {
    data.infectionStatuses = this.selectedInfectionStatuses;
    this._resource.update(data.id, data).then(item => {
      this.Dialog.hide(item);
    });
  }

  delete (id) {
    this.Dialog.promptDelete().then(() => {
      this._resource.destroy(id)
        .then(() => {
          this.Dialog.cancel(id);
        })
        .catch(err => {
          let msg = 'Error deleting PRF.';
          if (err.status && err.status === 409) {
            msg = err.data.message;
          }
          this.Toast.showSimple(msg);
        });
    });
  }
}
