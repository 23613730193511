export default function ($stateProvider) {
  'ngInject';

  $stateProvider
    .state('admin.flaggedIncidents', {
      url: '/flagged-referrals',
      controllerAs: '$ctrl',
      template: '<flagged-incident-list-page layout="column" flex></flagged-incident-list-page>',
      data: {
        title: 'Flagged Referrals',
      },
    });
};
