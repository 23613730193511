class TabGroupController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($state) {
    this.$state = $state;
  }

  $onInit () {
    if (this.tabs == null) {
      throw new Error('Tabs must be defined');
    }
    this.selectedTab = this.selectedTabId ? this.selectedTabId : this.tabs[0].id;
  }

  selectTab (tab) {
    this.selectedTab = tab.id;

    if (typeof this.onTabSelect === 'function') {
      this.onTabSelect({ tab });
    }
  }
}

export default {
  controller: TabGroupController,
  templateUrl: 'common/components/tab-group/tab-group.tpl.html',
  bindings: {
    onTabSelect: '&tgOnTabSelect',
    tabs: '<tgTabs',
    selectedTabId: '<tgSelectedTabId',
  },
};
