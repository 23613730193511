export class ChangePasswordController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (CrewMemberResource, Session, $state, $mdDialog, APP_ORG) {
    this.CrewMemberResource = CrewMemberResource;
    this.Session = Session;
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    this.APP_ORG = APP_ORG;
  }

  submit (passwords) {
    if (this.form.$invalid) {
      return;
    }

    this.CrewMemberResource
      .changePassword(passwords)
      .then(() => {
        this.Session.updateShouldChangePassword(false);

        if (this.Session.user().group.slug === 'charity-staff') {
          this.$state.go('calendar');
          return;
        }

        this.$state.go('dashboard');
      })
      .catch(console.log.bind(console));
  }
}

export default {
  controller: ChangePasswordController,
  templateUrl: 'pages/changePassword/templates/change-password.tpl.html',
};
