import ResourceDialog from 'common/resource/services/resourceDialog';
import TransferBatchDialogController from './manual-adjustment-dialog.controller';

export default class ManualAdjustmentDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(TransferBatchDialogController);
    this.setTemplateUrl('core/drugs/services/manual-adjustment-dialog/manual-adjustment-dialog.tpl.html');
  }
}
