import RestResource from 'common/resource/services/restResource';
import { format as formatBatch } from './drug-batches.resource';
import { format as formatCrewMember } from 'pages/crewMembers/services/crewMemberResource';
import { format as formatDrug } from 'pages/admin/drugs/resources/drug.resource';
import formatPrf from 'core/prf/services/prf-formatter.service';

export function format (item) {
  const formatted = {...item};

  formatted.amount_given = item.amount_given !== null ? parseFloat(item.amount_given) : null;
  formatted.amount_wasted = item.amount_wasted !== null ? parseFloat(item.amount_wasted) : null;

  if (item.type) { formatted.type = item.type.data; }
  if (item.batch) { formatted.batch = formatBatch(item.batch.data); }
  if (item.drug) { formatted.drug = formatDrug(item.drug.data); }
  if (item.location) { formatted.location = item.location.data; }
  if (item.prf) { formatted.prf = formatPrf(item.prf.data); }
  if (item.user) { formatted.user = formatCrewMember(item.user.data); };

  if (item.approval) {
    formatted.approval = { id: item.approval.data.id };

    if (item.approval.data.firstUser) {
      formatted.approval.first_user = formatCrewMember(item.approval.data.firstUser.data);
    }

    if (item.approval.data.secondUser) {
      formatted.approval.second_user = formatCrewMember(item.approval.data.secondUser.data);
    }
  }

  return formatted;
}

export default class DrugStockTransactionResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'drug-stock-transactions';
  }

  format (data) {
    return format(data);
  }

  approve (id, data) {
    return this._http.put(this.apiUrl + 'drug-stock-transaction-approvals/' + id, data);
  }
}
