import uuid from 'node-uuid';

export default class CreateHospitalController {
  constructor (HospitalResource, Dialog) {
    'ngInject';
    this._HospitalResource = HospitalResource;
    this._Dialog = Dialog;

    this.ID = uuid.v4();

    this.selectedSpecialties = [];
    if (this.item && this.item.specialties) {
      this.selectedSpecialties = this.item.specialties;
    }
  }

  submit (data) {
    if (this.form.$invalid) {
      return;
    }

    data.specialties = this.selectedSpecialties;

    if (this.editing) {
      this._update(data);
    } else {
      this._create(data);
    }
  };

  cancel () {
    this._Dialog.cancel();
  };

  delete (id) {
    this._Dialog.confirm().then(() => {
      this._HospitalResource.destroy(id).then(() => {
        this._Dialog.cancel(id);
      });
    });
  };

  _create (hospitalData) {
    hospitalData.id = this.ID;

    this._HospitalResource.create(hospitalData).then(() => {
      this._Dialog.hide(hospitalData);
    }).catch(console.log.bind(console));
  };

  _update (hospitalData) {
    this._HospitalResource.update(hospitalData.id, hospitalData).then((hospital) => {
      this._Dialog.hide(hospital);
    }).catch(console.log.bind(console));
  };
}
