export class SurveySwitcherController {
  $onChanges (changes) {
    const { surveys } = { ...changes };
    if (!surveys) return;

    const { previousValue, currentValue } = { ...surveys };
    if (!currentValue) {
      this.surveyRange = [];
      return;
    }

    this.surveyRange = Array.apply(null, { length: currentValue.length }).map(Number.call, Number);

    if (!this.isEditing) return;

    if (this.selectedIndex == null && currentValue.length > 0) {
      // Initial surveys loaded
      this.selectedIndex = 0;
      return;
    }

    if (!previousValue || !previousValue.length) return;

    if (currentValue.length > previousValue.length) {
      // New survey added
      this.selectedIndex = currentValue.length - 1;
    } else if (currentValue.length < previousValue.length) {
      // Survey deleted
      this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : null;
    }
  }

  select (index) {
    if (this.onSelect({ $event: { index: index } })) {
      this.selectedIndex = index;
    }
  }

  add () {
    if (this.onAdd()) {
      this.selectedIndex = this.surveys.length;
    }
  }
}

export default {
  bindings: {
    isEditing: '<',
    surveys: '<',
    onSelect: '&',
    onAdd: '&',
  },
  controller: SurveySwitcherController,
  templateUrl: 'core/prf/components/survey-switcher/survey-switcher.tpl.html',
};
