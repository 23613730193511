export default function FormTextarea () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      errors: '=feErrors',
      label: '@feLabel',
      model: '=feModel',
      name: '@feName',
      required: '=feRequired',
      disabled: '=feDisabled',
      rows: '@feRows',
    },
    controller: function () {},
    controllerAs: '$ctrl',
    bindToController: true,
    template: `
      <div class="form-textarea">
          <md-input-container>
              <label>{{ $ctrl.label }}</label>
              <textarea name="{{ $ctrl.name }}" rows="{{ $ctrl.rows || 3 }}"
                  ng-model="$ctrl.model" ng-required="!!$ctrl.required" ng-disabled="!!$ctrl.disabled">
              </textarea>
              <div ng-messages="$ctrl.errors">
                  <div ng-message="required">This is required.</div>
              </div>
          </md-input-container>
      </div>
    `,
  };
}
