import angular from 'angular';
import 'angular-animate';
import 'angular-aria';
import 'angular-messages';
import 'angular-material';
import 'angular-sanitize';
import 'angular-filter';
import 'angular-ui-grid';
import uiRouter from 'angular-ui-router';

import AppConfig from './app.config';
import AppRun from './app.run';
import AppComponent from './app.component';

import commonModule from './common/module';
import coreModule from './core/module';
import pagesModule from './pages/module';

let appModule = angular.module('gnaas.app', [
  'ngAnimate',
  'ngAria',
  'ngMessages',
  'ngMaterial',
  'ngSanitize',
  'angular.filter',
  uiRouter,
  commonModule,
  coreModule,
  pagesModule,
])
.config(AppConfig)
.run(AppRun)
.component('app', AppComponent);

export default appModule;
