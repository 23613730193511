import moment from 'moment';

export default {
  controller: function ($scope) {
    'ngInject';
    $scope.$watch(() => {
      return this.dateString ? this.dateString : null;
    }, (newVal, oldVal) => {
      if (this.datePicker) {
        this.date = moment(newVal).toDate();
      }
    });
  },
  bindings: {
    maxDate: '<',
    dateString: '@',
    datePicker: '@',
    onDateChange: '&',
    onNextClick: '&',
    onCurrentClick: '&',
    onPreviousClick: '&',
  },
  templateUrl: 'pages/calendar/templates/calendarControls.tpl.html',
};
