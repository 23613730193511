export default class ForgottenPasswordController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog, $http, API_URL) {
    this._Dialog = Dialog;
    this._http = $http;
    this._apiUrl = API_URL;

    this.init();
  }

  init () {
    this.resourcePath = 'password/email';
  }

  create (data) {
    this._http.post(`${this._apiUrl}${this.resourcePath}`, { email: data.email })
      .then(() => this._Dialog.hide(data.email))
      .catch(console.log.bind(console));
  }

  submit (data) {
    if (this.form.$invalid) {
      return;
    }

    this.create(data);
  }

  cancel () {
    this._Dialog.cancel();
  }
}
