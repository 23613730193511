import uuid from 'node-uuid';

export default class CreateBodyPartController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog) {
    this._Dialog = Dialog;
    this.ID = uuid.v4();
    this.includePath = 'pages/admin/categories/templates/forms/body-part.tpl.html';

    if (!this.categoryData) {
      this.categoryData = this._initialiseCategoryData();
    }

    if (this.multiSelections) {
      this.selected = {};

      this.multiSelections.forEach((select) => {
        this.selected[select] = [];

        if (this.categoryData && this.categoryData[select]) {
          this.selected[select] = this.categoryData[select];
        }
      });
    }
  }

  showAttributesDialog ($event, title, options, selected) {
    const dialog = this._Dialog;
    const dialogParams = {
      controller: function () { this.submit = () => { dialog.hide(); }; },
      locals: {
        title: title,
        options: options,
        selected: selected,
      },
      targetEvent: $event,
      templateUrl: 'pages/admin/categories/templates/forms/body-part-attributes.tpl.html',
      multiple: true,
    };
    this._Dialog.show(dialogParams);
  }

  submit (categoryData) {
    if (this.form.$invalid) {
      return;
    }

    if (this.multiSelections) {
      this.multiSelections.forEach((selection) => {
        categoryData[selection] = this.selected[selection];
      });
    }

    if (this.editing) {
      this._update(categoryData);
    } else {
      this._create(categoryData);
    }
  };

  cancel () {
    this._Dialog.cancel();
  };

  delete (id) {
    this._Dialog.confirm().then(() => {
      return this.resource.destroy(id);
    }).then(() => {
      this._Dialog.cancel(id);
    });
  };

  _initialiseCategoryData () {
    return {
      id: '',
      name: '',
      marker: {
        x: 50,
        y: 50,
      },
    };
  }

  _create (categoryData) {
    categoryData.id = this.ID;

    this.resource.create(categoryData).then(() => {
      this._Dialog.hide(categoryData);
    }).catch(console.log.bind(console));
  };

  _update (categoryData) {
    this.resource.update(categoryData.id, categoryData).then((category) => {
      this._Dialog.hide(category);
    }).catch(console.log.bind(console));
  };
}
