import { mapSearchCategoryName } from 'swacctsMapper';

export class SearchCategoriesController {
  constructor () {
    this.mapSearchCategoryName = mapSearchCategoryName;
  }

  isCategoryActive (name) {
    return name === this.selectedCategoryName;
  }

  hasCriteria (categoryName) {
    return this.query.hasOwnProperty(categoryName) && this.query[categoryName].criteria &&
        this.query[categoryName].criteria.length;
  }

  hasColumnsToDisplay (categoryName) {
    return ~['incidents', 'deployments', 'patients'].indexOf(categoryName.toLowerCase()) &&
        this.query.hasOwnProperty(categoryName) && this.query[categoryName].columnsToDisplay &&
        this.query[categoryName].columnsToDisplay.length;
  }
}

export default {
  bindings: {
    categories: '<',
    query: '<',
    selectedCategoryName: '@',
    onCategoryClick: '&',
  },
  controller: SearchCategoriesController,
  templateUrl: 'core/search/components/search-categories/search-categories.tpl.html',
};
