import { BATCH_MANAGEMENT_UPDATE_EVENT } from './batch-management.controller';

const getCellClass = (grid, row) => {
  return row.entity.has_expired_batches ? 'ui-grid-cell-bg-red' : '';
};

const DEFAULT_COLUMNS = {
  drug_name: { name: 'Drug Name', type: 'string', width: '*' },
  location: { name: 'Location', type: 'string', width: '*' },
  quantity: { name: 'Quantity', type: 'number', width: '*' },
};
Object.values(DEFAULT_COLUMNS).forEach(col => {
  Object.assign(col, { width: '*', cellClass: getCellClass });
});

export default class AllDrugsController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (DrugStockResource, $rootScope, uiGridConstants) {
    this._DrugStockResource = DrugStockResource;
    this._rootScope = $rootScope;
    this._uiGridConstants = uiGridConstants;
  }

  $onInit () {
    const defaultCols = Object.keys(DEFAULT_COLUMNS).reduce((acc, cur) => {
      acc[cur] = Object.assign({}, DEFAULT_COLUMNS[cur], { defaultSort: { direction: this._uiGridConstants.ASC } });
      return acc;
    }, {});
    this.columns = Object.assign({}, defaultCols);
    this._loadData();
    this._rootScope.$on(BATCH_MANAGEMENT_UPDATE_EVENT, () => { this._loadData(); });
  }

  _loadData () {
    this._DrugStockResource.index()
      .then(data => {
        this.data = data.map(item => ({
          drug_name: item.drug.name,
          location: item.location.name,
          quantity: item.quantity,
          has_expired_batches: item.has_expired_batches,
        }));
      })
      .catch(console.log.bind(console));
  }
}

