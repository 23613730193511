import uuid from 'node-uuid';
import moment from 'moment';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

function getDestinationsWithTypes (destinations) {
  let updated = {};

  if (destinations.hospitals) {
    updated.hospitals = destinations.hospitals.map(item => {
      item.destinationType = 'hospital';
      return item;
    });
  }

  if (destinations.locations) {
    updated.locations = destinations.locations.map(item => {
      item.destinationType = 'location';
      return item;
    });
  }

  if (destinations.bases) {
    updated.bases = destinations.bases.map(item => {
      item.destinationType = 'base';
      return item;
    });
  }

  return updated;
}

function formatTime (deploymentDate, statusUpdateTime) {
  const date = moment(deploymentDate).format('YYYY-MM-DD');
  const time = moment(statusUpdateTime).format('HH:mm:ss');
  return moment(date + ' ' + time).toDate();
}

export default class DeploymentStatusUpdateDialogController extends ResourceDialogController {
  init () {
    super.init();
    this._resource = this.$injector.get('DeploymentStatusUpdateResource');
    this._scope = this.$injector.get('$rootScope').$new();
    this._ID = uuid.v4();
    this.searchTerm = '';
    this.availableDestinations = this.destinations;
    if (this.item) {
      this.item.date = moment(this.item.time).toDate();
    } else {
      this.item = {
        date: this._getDeploymentDate(),
      };
    }

    if (this.deployments) {
      this.deployments = this.deployments.map(deployment => {
        deployment.date = moment(deployment.date).toDate();
        return deployment;
      });
    } else if (this.deployment) {
      this.deployment.date = this._getDeploymentDate();
    }

    this._setupWatchers();
  }

  create (formData) {
    formData.id = this._ID;
    formData.deployment = { id: this.deployment.id };
    formData.time = formatTime(formData.date, formData.time);
    if (formData.destination) {
      formData.destination_type = formData.destination.destinationType;
    }

    this._resource.create(formData).then(() => {
      let copy = Object.assign({}, formData);
      copy.time = moment(copy.time);
      this.Dialog.hide(copy);
    }).catch(console.log.bind(console));
  }

  update (formData) {
    if (!this.deployment) {
      this.deployment = formData.deployment;
    }
    formData.time = formatTime(formData.date, formData.time);
    if (formData.destination) {
      formData.destination_type = formData.destination.destinationType;
    }

    this._resource.update(formData.id, formData, {
      include: ['deploymentStatus', 'destination'],
    }).then(deployment => {
      this.Dialog.hide(deployment);
    }).catch(console.log.bind(console));
  }

  delete (id) {
    this.Dialog.confirm().then(() => {
      this._resource.destroy(id).then(() => {
        this.Dialog.cancel(id);
      }).catch(console.log.bind(console));
    });
  }

  _setupWatchers () {
    this._scope.$watch(() => {
      return this.relationData;
    }, (newVal) => {
      if (newVal && newVal.destinations) {
        this.destinations = getDestinationsWithTypes(newVal.destinations);
      }
    }, true);

    this._scope.$watch(() => {
      if (this.item) {
        return this.item.deployment_status;
      }
    }, (newVal) => {
      if (!newVal) {
        return;
      }

      if (newVal.is_at_hospital) {
        this.availableDestinations = {
          hospitals: this.destinations.hospitals,
        };
      } else if (this.availableDestinations !== this.destinations) {
        this.availableDestinations = this.destinations;
      }
    });
  }

  _getDeploymentDate () {
    return this.deployment ? moment(this.deployment.date).toDate() : new Date();
  }
}
