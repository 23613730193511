import ResourceDialog from 'common/resource/services/resourceDialog';
import DeploymentDialogController from './columns-to-display-dialog.controller';

export default class ColumnsToDisplayDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(DeploymentDialogController);
    this.setTemplateUrl('core/search/services/columns-to-display-dialog/columns-to-display-dialog.tpl.html');
  }
}
