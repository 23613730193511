import ResourceDialogController from 'common/resource/controllers/resourceDialogController';
import uuid from 'node-uuid';
import moment from 'moment';

export default class CreateConsumableDialogController extends ResourceDialogController {
  init () {
    super.init();

    this.StockLocationResource = this.$injector.get('StockLocationResource');
    this.ConsumableTypeResource = this.$injector.get('ConsumableTypeResource');
    this.ConsumableBatchResource = this.$injector.get('ConsumableBatchResource');

    this.today = new Date();
    this.stockLocationId = null;
    this.timestamp = moment().startOf('minute').toDate();
    this.batches = [];

    this.StockLocationResource.index().then(items => { this.stockLocations = items; });
    this.ConsumableTypeResource.index({ include: 'consumables' }).then(items => {
      this.consumableTypes = items;
      this.consumableTypeMap = items.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur.consumables }), {});
    });
  }

  newBatch () {
    this.batches.push({ id: uuid.v4() });
  }

  removeBatch (id) {
    this.batches = this.batches.filter(item => item.id !== id);
  }

  create (input) {
    this.ConsumableBatchResource
      .create(this._parseData(input))
      .then(data => { this.Dialog.hide(data); })
      .catch(err => {
        if (err.status === 409) {
          const e = err.data.errors;
          this.Dialog.alert(
            `${e.consumable_name} batch expiring on ${e.expires_on} already exist.`,
            'Consumable batch already exists'
          );
        }
      });
  }

  _parseData (data) {
    return data.map(item => Object.assign({}, item, {
      stock_location_id: this.stockLocationId,
      received_on: this.timestamp,
    }));
  }
}
