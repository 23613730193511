import moment from 'moment';
import RestResource from 'common/resource/services/restResource';

export function parse (item) {
  return {
    id: item.id,
    text: item.text,
    date: item.date.clone().format('YYYY-MM-DD'),
  };
}

export function format (item) {
  return {
    id: item.id,
    text: item.text,
    date: moment(item.date, 'YYYY-MM-DD'),
  };
}

export default class CalendarAgendaResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'calendar-agendas';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
