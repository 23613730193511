/**
 * Calculate timer delay, based off token TTL.
 * Add 1 second delay padding.
 *
 * @param payload JWT payload
 * @returns {number} Timer delay (ms)
 */
function getDelay (payload) {
  const diff = payload.exp - payload.iat;
  const tokenTtlInMinutes = Math.floor(diff / 60);
  const result = tokenTtlInMinutes * 60 * 1000 + 1000;

  // timeouts that overflow a 32 bit int resolve immediately
  if (result > 0x7FFFFFFF) {
    return 0x7FFFFFFF;
  }

  return result;
}

export default class IdleCheck {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($state, $timeout, AuthResource, JWT, Session) {
    this.$state = $state;
    this.$timeout = $timeout;
    this.AuthResource = AuthResource;
    this.JWT = JWT;
    this.Session = Session;
    this.timer = null;
  }

  start () {
    this.stop();

    this.timer = this.$timeout(() => {
      this.AuthResource
        .check(true)
        .then(() => {
          this.start();
        })
        .catch(() => {
          this.JWT.destroy();
          this.Session.destroy();
          this.stop();
          this.$state.go('login', { previousState: { ...this.$state.current, params: this.$state.params } });
        });
    }, getDelay(this.JWT.parse()));
  }

  stop () {
    if (this.timer === null) return;
    this.$timeout.cancel(this.timer);
    this.timer = null;
  }
}
