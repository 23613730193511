/**
 * Helpers
 * Temporary - should be moved to app-wide utils
 */
function ucFirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function prettify (str) {
  return str.split('_').map(item => ucFirst(item)).join(' ');
}

/**
 * Search category property mapping
 */

export function mapSearchCategories (categories) {
  return Object.keys(categories).reduce((acc, cur) => {
    if (cur === 'incidents') {
      acc[cur] = mapIncidentProperties(acc[cur]);
    }
    return acc;
  }, JSON.parse(JSON.stringify(categories)));
}

function mapIncidentProperties (properties) {
  return properties.map(item => {
    switch (item.name.toLowerCase()) {
      case 'incidents.incident_number':
        item.title = 'Referral Number';
        return item;

      case 'incidents.deployment_reason_id':
        item.title = 'Reason for Transfer';
        return item;

      case 'incidents.call_connect_time':
        item.title = 'Time to Retrieve';
        return item;

      case 'incidents.mission_refused_time':
        item.title = 'Task Declined Time';
        return item;

      case 'incidents.mission_accepted_time':
        item.title = 'Task Accepted Time';
        return item;

      case 'incidents.status':
        item.title = 'Referral Status';
        return item;

      default: return item;
    }
  });
}

/**
 * Search category name mapping
 */

const SEARCH_CATEGORY_NAME_MAP = {
  'incidents': 'referrals',
};

export function mapSearchCategoryName (value, pretty = false) {
  const mappedName = SEARCH_CATEGORY_NAME_MAP[value] || value;
  return pretty ? prettify(mappedName) : mappedName;
}

/**
 * Search property mapping
 */

const INCIDENT_SEARCH_PROPERTY_MAP = {
  'incident_number': 'referral_number',
  'incident_date': 'referral_date',
  'incident_created_by': 'referral_created_by',
  'deployment_reason': 'reason_for_transfer',
  'call_connect_time': 'time_to_retrieve',
  'mission_refused_time': 'task_declined_time',
  'mission_accepted_time': 'task_accepted_time',
  'status': 'referral_status',
};

const DEPLOYMENT_SEARCH_PROPERTY_MAP = {
  'status': 'deployment_status',
};

export function mapSearchProperty (category, value) {
  if (category === 'incidents') {
    return INCIDENT_SEARCH_PROPERTY_MAP[value] || value;
  } else if (category === 'deployments') {
    return DEPLOYMENT_SEARCH_PROPERTY_MAP[value] || value;
  }

  return value;
}

/**
 * Search result heading mapping
 */

export function mapSearchResultHeading (value) {
  const parts = value.split('.');
  const category = mapSearchCategoryName(parts[0]);
  const property = mapSearchProperty(parts[0], parts[1]);
  return category + '.' + property;
}
