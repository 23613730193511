import angular from 'angular';
import uiRouter from 'angular-ui-router';
import BasesResourceController from './controllers/basesResourceController';
import BaseResource from './services/baseResource';

const moduleConfig = function ($stateProvider) {
  'ngInject';
  $stateProvider.state('admin.bases', {
    url: '/bases',
    controller: BasesResourceController,
    controllerAs: '$ctrl',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    data: {
      title: 'Bases',
    },
  });
};

const module = angular
  .module('gnaas.admin.bases', [
    uiRouter,
  ])
  .config(moduleConfig)
  .service('BaseResource', BaseResource);

export default module.name;
