export default function FormInput () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      // Attributes
      disabled: '=feDisabled',
      errors: '=feErrors',
      label: '@feLabel',
      model: '=feModel',
      name: '@feName',
      required: '=feRequired',
      type: '@feType',
      // Directives
      noFloat: '=feNoFloat',
      modelOptions: '=feModelOptions',
      // Validators
      max: '@feMax',
      maxLength: '@feMaxLength',
      min: '@feMin',
      minLength: '@feMinLength',
      pattern: '=fePattern',
      // Error messages
      patternError: '@fePatternError',
      // Outputs
      onChange: '&feOnChange',
    },
    controller: function () {
      this.type = this.type || 'text';
    },
    controllerAs: '$ctrl',
    bindToController: true,
    link: function ($scope, $el) {
      if ($scope.$ctrl.type.toLowerCase() === 'number') {
        $el.on('mousewheel', function (e) {
          e.target.blur();
        });
      }
    },
    template: function (element, $attrs) {
      let onChange = '';
      if ($attrs.feOnChange) {
        onChange = 'ng-change="$ctrl.onChange({ $event: { value: $ctrl.model, name: $ctrl.name } })"';
      }

      let modelOptions = '';
      if ($attrs.feModelOptions) {
        modelOptions = `ng-model-options="${$attrs.feModelOptions}"`;
      }

      return `<div class="form-input" ng-class="{'s-is-disabled': $ctrl.disabled}">
            <md-input-container md-no-float="$ctrl.noFloat">
                <input type="{{ $ctrl.type }}" name="{{ $ctrl.name }}"
                        ng-disabled="$ctrl.disabled"
                        ng-model="$ctrl.model"
                        ${modelOptions}
                        ng-pattern="$ctrl.pattern"
                        ng-required="!!$ctrl.required"
                        ng-max="{{ $ctrl.max }}"
                        ng-maxlength="{{ $ctrl.maxLength }}"
                        ng-min="{{ $ctrl.min }}"
                        ng-minlength="{{ $ctrl.minLength }}"
                        ${onChange}
                        placeholder="{{ $ctrl.label }}">
                <div ng-messages="$ctrl.errors" role="alert">
                    <div ng-message="required">This is required.</div>
                    <div ng-message="max">Value can't be greater than {{ $ctrl.max }}.</div>
                    <div ng-message="maxlength">Value can't be longer than {{ $ctrl.maxLength }} characters.</div>
                    <div ng-message="min">Value must be at least {{ $ctrl.min }}.</div>
                    <div ng-message="minlength">Value must be at least {{ $ctrl.minLength }} characters long.</div>
                    <div ng-message="email">This must be a valid email.</div>
                    <div ng-message="time">This must be a valid time.</div>
                    <div ng-message="pattern">{{ $ctrl.patternError || 'Pattern does not match.'}}</div>
                </div>
            </md-input-container>
        </div>
      `;
    },
  };
};
