const MAP_MARKER_FILTER_SIDENAV = 'sidenav-map-marker-filters';

export class MapSidenavController {
  constructor ($mdSidenav) {
    'ngInject';
    this._mdSidenav = $mdSidenav;
  }

  openFilterSidenav () {
    this._mdSidenav(MAP_MARKER_FILTER_SIDENAV).open();
  }
}

export default {
  bindings: {
    markers: '<',
  },
  controller: MapSidenavController,
  templateUrl: 'pages/map/templates/mapSidenav.tpl.html',
};
