import { CoordsToGridRef } from 'common/utils/services/gridRefToCoords';
import Map from 'common/map/services/map';
import MapMarkerIcon from 'common/map/services/mapMarkerIcon';

const MARKER_SIDENAV = 'sidenav-map-markers';

export class MapController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($timeout, $mdSidenav, MapService, Dialog, Location, BING_MAPS_KEY) {
    this._timeout = $timeout;
    this._mdSidenav = $mdSidenav;
    this._MapService = MapService;
    this._Dialog = Dialog;
    this._Location = Location;
    this._BING_MAPS_KEY = BING_MAPS_KEY;
  }

  $onInit () {
    this.map = null;
    this.mapMarkers = [];
    this.vehicleMarkers = [];
    this.incidentMarker = null;
    this.sidebarOpen = false;
    this.routeDistance = null;
    this._incidentMarkerOpts = { icon: MapMarkerIcon.predefined('incident') };
    this._routePlanningActive = false;
    this._vehicleUpdateTimer = null;
  }

  $postLink () {
    this._timeout(() => {
      this.map = new Map('map', { bingMapsKey: this._BING_MAPS_KEY });
      this._loadMapMarkers();
    });
  }

  $onDestroy () {
    this.map.destroy();
    this.map = null;
    this._timeout.cancel(this._vehicleUpdateTimer);
  }

  /**
   * Incidents
   */

  findIncidentLocation (location) {
    this._disableDropIncidentMarker();
    this._Location
      .resolveCoordinates(location)
      .then(this._setMarker.bind(this))
      .catch(e => {
        this._Dialog.alert(e.message, 'Error');
      });
  }

  _setMarker (coords) {
    if (this.incidentMarker) {
      this._clearIncidentMarker();
    }
    this._createIncidentMarker([coords.lat, coords.lng]);
  }

  toggleDropIncidentMarker () {
    if (this.dropToggleEnabled) {
      this._disableDropIncidentMarker();
    } else {
      this._enableDropIncidentMarker();
    }
  }

  clearIncidentLocation () {
    if (this.incidentMarker) {
      this._clearIncidentMarker();
      if (this.location) {
        this.location = null;
      }
    }
  }

  _enableDropIncidentMarker () {
    this.dropToggleEnabled = true;
    this._boundDropMarkerOnClickHandler = this._handleDropIncidentMarker.bind(this);
    this.map.addEventListener('click', this._boundDropMarkerOnClickHandler);
  }

  _disableDropIncidentMarker () {
    this.dropToggleEnabled = false;
    if (this._boundDropMarkerOnClickHandler) {
      this.map.removeEventListener('click', this._boundDropMarkerOnClickHandler);
      delete this._boundDropMarkerOnClickHandler;
    }
  }

  _handleDropIncidentMarker (e) {
    this._disableDropIncidentMarker();
    this._timeout(() => {
      this._createIncidentMarker(e.latlng);
      this._setLocation(e.latlng);
    });
  }

  _createIncidentMarker (latLng) {
    const opts = Object.assign({}, this._incidentMarkerOpts, { draggable: true });
    this.incidentMarker = this.map.addMarker(latLng, opts);
    this.map.panTo(latLng);
    this.incidentMarker.on('dragend', (e) => {
      this._timeout(() => {
        this._setLocation(e.target._latlng);
      });
    });
    this._calculateMarkerDistances();
  }

  _calculateMarkerDistances () {
    this.mapMarkers.forEach(marker => {
      const distance = this.incidentMarker._latlng.distanceTo(marker.marker._latlng);
      marker.distance = this._metresToNauticalMiles(distance);
    });
  }

  _clearIncidentMarker () {
    this.map.removeMarker(this.incidentMarker);
    this.incidentMarker = null;
    this._clearMarkerDistances();
  }

  _clearMarkerDistances () {
    this.mapMarkers.forEach(marker => {
      marker.distance = null;
    });
  }

  _setLocation (coords) {
    const grid = CoordsToGridRef(coords);
    this.location = grid.square + ' ' + grid.reference;
  }

  /**
   * Route planning
   */

  toggleRoutePlanning () {
    if (this._routePlanningActive) {
      this.map.enableRoutePlanning(this._routePlanningCallback.bind(this));
    } else {
      this.map.disableRoutePlanning();
      this.routeDistance = null;
    }
  }

  clearRoute () {
    this._timeout(() => {
      this.map.clearRoute();
      this.routeDistance = null;
    });
  }

  routePlanningInProgress () {
    return this.routeDistance !== null;
  }

  _routePlanningCallback () {
    this._timeout(() => {
      this.routeDistance = this._metresToNauticalMiles(this.map.getRouteDistance());
    });
  }

  /**
   * Map markers
   */

  onMarkerFilterUpdate (visibleMarkers) {
    this.mapMarkers.forEach(item => {
      if (visibleMarkers.indexOf(item.type) > -1) {
        item.show = true;
        item.marker._icon.style.display = null;
      } else {
        item.show = false;
        item.marker._icon.style.display = 'none';
      }
    });
  }

  _loadMapMarkers () {
    this.showLoader = true;
    this._MapService.getMarkerData().then(markerData => {
      this._addBaseMarkers(markerData.bases);
      this._addHospitalMarkers(markerData.hospitals);
      this._addLocationMarkers(markerData.locations);

      this._addVehicleMarkers(markerData.vehicles);
      this._vehicleUpdateTimer = this._timeout(() => {
        this._addVehicleMarkers(markerData.vehicles);
      }, 60000);
    }).finally(() => (this.showLoader = false));
  }

  _addBaseMarkers (locations) {
    const opts = { icon: MapMarkerIcon.predefined('base') };

    Array.prototype.forEach.call(locations, location => {
      const popup = this._getMarkerPopup(location.name);

      this.mapMarkers.push({
        location: location,
        marker: this.map.addMarker([location.lat, location.lng], opts, popup),
        type: 'base',
        show: true,
      });
    });
  }

  _getMarkerPopup (name, specialties) {
    const open = '<div class="map-popup"><div class="map-popup__header">[header]</div>';
    const close = '</div>';

    let result = open.replace('[header]', name);
    if (specialties && specialties.length) {
      const content = `<div class="map-popup__content">
        <div class="map-popup__content-label">Hospital specialties</div>
        <ul>[specialties]</ul>
      </div>`;
      specialties = specialties.map((s) => '<li>' + s.name + '</li>').join('');
      result += content.replace('[specialties]', specialties);
    }
    result += close;

    return result;
  }

  _addHospitalMarkers (locations) {
    const opts = { icon: MapMarkerIcon.predefined('hospital') };

    Array.prototype.forEach.call(locations, location => {
      const popup = this._getMarkerPopup(location.name, location.specialties);

      this.mapMarkers.push({
        location: location,
        marker: this.map.addMarker([location.lat, location.lng], opts, popup),
        type: 'hospital',
        show: true,
      });
    });
  }

  _addLocationMarkers (locations) {
    const availableIcons = MapMarkerIcon.getAvailableIcons();

    Array.prototype.forEach.call(locations, location => {
      const popup = this._getMarkerPopup(location.name);
      const opts = {};
      const type = availableIcons.indexOf(location.location_type.slug) > -1
          ? location.location_type.slug
          : 'generic';

      opts.icon = MapMarkerIcon.predefined(type);
      this.mapMarkers.push({
        location: location,
        marker: this.map.addMarker([location.lat, location.lng], opts, popup),
        type: type,
        show: true,
      });
    });
  }

  _addVehicleMarkers (vehicleCallsigns) {
    this._clearVehicleMarkers();
    Array.prototype.forEach.call(vehicleCallsigns, vehicleCallsign => {
      if (!vehicleCallsign.lastDeployment) {
        return;
      }

      const opts = { icon: MapMarkerIcon.predefined(vehicleCallsign.vehicle.typeString.toLowerCase()) };
      const popup = this._getMarkerPopup(vehicleCallsign.name);

      this.vehicleMarkers.push({
        location: vehicleCallsign,
        marker: this.map.addMarker([
          vehicleCallsign.lastDeployment.destination.lat,
          vehicleCallsign.lastDeployment.destination.lng,
        ], opts, popup),
        type: vehicleCallsign.vehicle.typeString.toLowerCase(),
        show: true,
      });
    });
  }

  _clearVehicleMarkers () {
    this.vehicleMarkers.map(item => item.marker).forEach(item => { this.map.removeMarker(item); });
    this.vehicleMarkers = [];
  }

  /**
   * Sidebar
   */
  toggleSidebar () {
    this.sidebarOpen = !this._mdSidenav(MARKER_SIDENAV).isOpen();
    this._mdSidenav(MARKER_SIDENAV).toggle();
  }

  /**
   * Utils
   */
  _metresToNauticalMiles (metres) {
    return parseFloat((metres * 0.000539957).toFixed(2));
  }
}

export default {
  templateUrl: 'pages/map/templates/map-page.tpl.html',
  controller: MapController,
};
