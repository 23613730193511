import angular from 'angular';
import uiRouter from 'angular-ui-router';
import Settings from './components/settings';

const moduleConfig = function ($stateProvider) {
  'ngInject';
  $stateProvider.state('settings', {
    url: '/settings',
    template: '<settings flex layout="column"></settings>',
    data: {
      title: 'Settings',
    },
  });
};

const module = angular
  .module('gnaas.pages.settings', [
    uiRouter,
  ])
  .config(moduleConfig)
  .component('settings', Settings);

export default module.name;
