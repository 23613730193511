import moment from 'moment';
import uuid from 'node-uuid';

export default function CreateCalendarAgendaController (Dialog, CalendarAgendaResource) {
  'ngInject';

  const AGENDA_ID = uuid.v4();
  const AGENDA_DATE = moment(this.date);

  const create = function (data) {
    data.id = AGENDA_ID;
    data.date = AGENDA_DATE;

    CalendarAgendaResource.create(data).then(function () {
      Dialog.hide(data);
    }).catch(console.log.bind(console));
  };

  const update = function (data) {
    CalendarAgendaResource.update(data.id, data).then(function (agenda) {
      Dialog.hide(agenda);
    }).catch(console.log.bind(console));
  };

  this.submit = function (agenda) {
    if (this.form.$invalid) {
      return false;
    }

    if (this.editing) {
      update(agenda);
    } else {
      create(agenda);
    }
  };

  this.cancel = function () {
    Dialog.cancel();
  };

  this.delete = function (id) {
    Dialog.confirm().then(() => {
      CalendarAgendaResource.destroy(id).then(function () {
        Dialog.cancel(id);
      });
    });
  };
}
