import uuid from 'node-uuid';

export default class InterventionAttributeValuesController {
  constructor (Utils, Dialog) {
    'ngInject';
    this._Utils = Utils;
    this._Dialog = Dialog;

    if (!this.attribute.values) {
      this.attribute.values = [];
    }
  }

  submit () {
    this._Dialog.hide();
  };

  addValue () {
    this.attribute.values.push({
      id: uuid.v4(),
    });
  };

  removeValue (item) {
    this._Utils.removeFromArray(this.attribute.values, item);
  };
};
