import angular from 'angular';
import Pagination from './components/pagination/pagination.component';
import TableList from './components/table-list/table-list.component';
import CheckboxListDialog from './services/checkbox-list-dialog/checkbox-list-dialog.service';

const module = angular
  .module('gnaas.common.lists', [])
  .component('pagination', Pagination)
  .component('tableList', TableList)
  .service('CheckboxListDialog', CheckboxListDialog);

export default module.name;
