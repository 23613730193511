import moment from 'moment';
import { copy } from 'angular';
import { SHIFT_EVENTS } from 'core/shifts/module.constants';
import { DATE_FORMAT } from 'common/resource/module.constants';

const TABS = [
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'Inactive' },
];

const TABLE_FIELDS = {
  name: 'Name',
  startTimeString: 'Start Time',
  endTimeString: 'End Time',
  baseName: 'Base',
  vehicleCallsignName: 'Vehicle Callsign',
};

function formatShift (shift) {
  return {
    ...shift,
    baseName: shift.base ? shift.base.name : '-',
    vehicleCallsignName: shift.vehicle_callsign ? shift.vehicle_callsign.name : '-',
    endDateString: shift.end_date ? moment(shift.end_date).format(DATE_FORMAT) : '-',
    startTimeString: moment(shift.start_time).format('HH:mm'),
    endTimeString: moment(shift.end_time).format('HH:mm'),
  };
}

class ShiftsPageController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $mdSidenav,
    $rootScope,
    BaseResource,
    CrewMemberResource,
    ShiftDialog,
    ShiftResource,
    VehicleCallsignResource
  ) {
    this.$mdSidneav = $mdSidenav;
    this.$rootScope = $rootScope;
    this.BaseResource = BaseResource;
    this.CrewMemberResource = CrewMemberResource;
    this.ShiftDialog = ShiftDialog;
    this.ShiftResource = ShiftResource;
    this.VehicleCallsignResource = VehicleCallsignResource;
  }

  $onInit () {
    this.today = moment().format(DATE_FORMAT);
    this.tabs = TABS;
    this.selectedTab = this.tabs[0].id;
    this.tableFields = TABLE_FIELDS;

    // Set up pagination & load shifts
    this.resetPagination();
    this.loadPage(1);

    // Load relation data
    this.loadRelations();

    // Listen to shift updated & deleted events
    this.$rootScope.$on(SHIFT_EVENTS.updated, (e, shift) => {
      this.shifts = this.shifts.map(item => item.id === shift.id ? formatShift(shift) : item);
      this.loadPage(this.pagination.currentPage);
    });
    this.$rootScope.$on(SHIFT_EVENTS.deleted, (e, shift) => {
      this.shifts = this.shifts.filter(item => item.id !== shift.id);
      this.loadPage(this.pagination.currentPage);
      this.selectedShift = null;
    });
  }

  /**
   * Pagination & shift data loading
   */

  resetPagination () {
    this.pagination = { currentPage: 1, totalPages: 1 };
  }

  loadPage (page) {
    this.dataLoading = true;
    this.ShiftResource
      .index({
        include: 'properties.base,properties.vehicleCallsign',
        page,
        'active_status': this.selectedTab,
        'order_by': 'start_date',
      })
      .then(shifts => {
        this.shifts = shifts.data.map(formatShift);
        this.pagination.currentPage = shifts.pagination.current_page;
        this.pagination.totalPages = shifts.pagination.total_pages;
      })
      .finally(() => (this.dataLoading = false));
  }

  previousPage () {
    if (this.pagination.currentPage === 1) return;
    this.pagination.currentPage--;
    this.loadPage(this.pagination.currentPage);
  }

  nextPage () {
    if (this.pagination.currentPage === this.pagination.totalPages) return;
    this.pagination.currentPage++;
    this.loadPage(this.pagination.currentPage);
  }

  loadRelations () {
    let requestsFinished = 0;
    const requestsRequired = 3;
    this.relationDataLoading = true;
    this.BaseResource.index()
      .then(data => (this.bases = data))
      .finally(() => (this._handleDependencyLoadingIndicator(++requestsFinished, requestsRequired)));
    this.VehicleCallsignResource.index()
      .then(data => (this.vehicleCallsigns = data))
      .finally(() => (this._handleDependencyLoadingIndicator(++requestsFinished, requestsRequired)));
    this.CrewMemberResource
      .index({ filter: 'operational-crew', include: 'profile.profession,profile.image' })
      .then(data => {
        this.crewMembers = data.map(item => ({ ...item, name: `${item.first_name} ${item.last_name}` }));
      })
      .finally(() => (this._handleDependencyLoadingIndicator(++requestsFinished, requestsRequired)));
  }

  /**
   * Tabs
   */

  selectTab (tab) {
    if (this.selectedTab === tab.id) return;
    if (this.tabs.map(item => item.id).indexOf(tab.id) === -1) {
      throw new Error('Invalid tab.');
    }
    this.selectedTab = tab.id;
    this.resetPagination();
    this.loadPage(1);
  }

  /**
   * Sidenavs & dialogs
   */

  showSidenav (shift) {
    this.selectedShift = copy(shift);
    this.$mdSidneav('shift-sidebar-item').open();
  }

  showShiftDialog ({ $event }) {
    const dialogParams = {
      $event,
      locals: {
        bases: this.bases,
        vehicleCallsigns: this.vehicleCallsigns,
      },
    };

    this.ShiftDialog
      .show(dialogParams)
      .then(shift => {
        this.loadPage(this.pagination.currentPage);
      });
  }

  /**
   * Helpers
   */
  _handleDependencyLoadingIndicator (requestsFinished, requestsRequired) {
    if (requestsFinished === requestsRequired) {
      this.relationDataLoading = false;
    }
  }
}

export default {
  controller: ShiftsPageController,
  templateUrl: 'pages/shifts/components/shifts-page/shifts-page.tpl.html',
};
