import angular from 'angular';
import AdministeredDrugs from './components/administered-drugs/administered-drugs.component';
import AdministeredInfusions from './components/administered-infusions/administered-infusions.component';
import HospitalInformationDialog from './services/hospital-information-dialog/hospital-information-dialog.service';
import IllnessDialog from './services/illness-dialog/illness-dialog.service';
import InjuryDialog from './services/injury-dialog/injury-dialog.service';
import InterventionDialog from './services/intervention-dialog/intervention-dialog.service';
import Observations from './components/observations/observations.component';
import PatientDetails from './components/patient-details/patient-details.component';
import PatientDetailsDialog from './services/patient-details-dialog/patient-details-dialog.service';
import PRFListItem from './components/prf-list-item/prf-list-item.component';
import PRFApprovalDialog from './services/prf-approval-dialog/prf-approval-dialog.service';
import PRFResource from './resources/prf.resource';
import PrintablePRFResource from './resources/printable-prf.resource';
import SurveyItemDetails from './components/survey-item-details/survey-item-details.component';
import SurveyInterventionDetails from './components/survey-intervention-details/survey-intervention-details.component';
import SurveyResource from './resources/survey.resource';
import SurveySwitcher from './components/survey-switcher/survey-switcher.component';
import RecordDrugUseDialog from './services/record-drug-use-dialog/record-drug-use-dialog.service';
import ReviewDrugUseDialog from './services/review-drug-use-dialog/review-drug-use-dialog.service';
import ClinicalIncidentDialog from './services/clinical-incident-dialog/clinical-incident-dialog.service';
import HandoverDialog from './services/handover-dialog/handover-dialog.service';
import ClinicalIncidentResource from './resources/clinical-incident.resource';
import HandoverResource from './resources/handover.resource';
import SurveyLaboratoryFindingsDetail
  from './components/survey-laboratory-findings-detail/survey-laboratory-findings-detail.component';
import LaboratoryFindingsDialog from './services/laboratory-findings-dialog/laboratory-findings-dialog.service';
import PrescribedDrugs from './components/prescribed-drugs/prescribed-drugs.component';
import PrescribedInfusions from './components/prescribed-infusions/prescribed-infusions.component';

const module = angular
  .module('gnaas.core.prf', [])
  .component('administeredDrugs', AdministeredDrugs)
  .component('administeredInfusions', AdministeredInfusions)
  .component('observations', Observations)
  .component('patientDetails', PatientDetails)
  .component('prescribedDrugs', PrescribedDrugs)
  .component('prescribedInfusions', PrescribedInfusions)
  .component('prfListItem', PRFListItem)
  .component('surveyItemDetails', SurveyItemDetails)
  .component('surveyInterventionDetails', SurveyInterventionDetails)
  .component('surveySwitcher', SurveySwitcher)
  .component('surveyLaboratoryFindingsDetail', SurveyLaboratoryFindingsDetail)
  .service('HospitalInformationDialog', HospitalInformationDialog)
  .service('IllnessDialog', IllnessDialog)
  .service('InjuryDialog', InjuryDialog)
  .service('InterventionDialog', InterventionDialog)
  .service('PatientDetailsDialog', PatientDetailsDialog)
  .service('PRFApprovalDialog', PRFApprovalDialog)
  .service('PRFResource', PRFResource)
  .service('PrintablePRFResource', PrintablePRFResource)
  .service('SurveyResource', SurveyResource)
  .service('RecordDrugUseDialog', RecordDrugUseDialog)
  .service('ReviewDrugUseDialog', ReviewDrugUseDialog)
  .service('ClinicalIncidentDialog', ClinicalIncidentDialog)
  .service('HandoverDialog', HandoverDialog)
  .service('ClinicalIncidentResource', ClinicalIncidentResource)
  .service('HandoverResource', HandoverResource)
  .service('LaboratoryFindingsDialog', LaboratoryFindingsDialog);

export default module.name;
