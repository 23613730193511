import angular from 'angular';
import EquipmentLocationCompartment
  from './components/equipment-location-compartment/equipment-location-compartment.component';
import EquipmentLocationResource from './resources/equipment-location.resource';
import EquipmentLocationDialog from './services/equipment-location-dialog/equipment-location-dialog.service';

const module = angular
  .module('gnaas.core.equipment-locations', [])
  .component('equipmentLocationCompartment', EquipmentLocationCompartment)
  .service('EquipmentLocationDialog', EquipmentLocationDialog)
  .service('EquipmentLocationResource', EquipmentLocationResource);

export default module.name;
