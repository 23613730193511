import { copy } from 'angular';
import ReviewDrugUseDialogController from './review-drug-use-dialog.controller';

class ReviewDrugUseDialog {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog) {
    this.Dialog = Dialog;
    this._dialogParams = {
      controller: ReviewDrugUseDialogController,
      templateUrl: 'core/prf/services/review-drug-use-dialog/review-drug-use-dialog.tpl.html',
      locals: {},
    };
  }

  show ({ $event, prf, onApproveClick, onRejectClick }) {
    const dialogParams = {
      locals: { prf: copy(prf), onApproveClick, onRejectClick },
      targetEvent: $event || null,
    };
    return this.Dialog.show({ ...this._dialogParams, ...dialogParams });
  }
}

export default ReviewDrugUseDialog;
