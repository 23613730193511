import RestResource from 'common/resource/services/restResource';

export function format (item) {
  let formated = {
    id: item.id,
    name: item.name,
  };

  if (item.attributes) {
    formated.attributes = item.attributes.data.map(attribute => {
      let formatedAttribute = {
        id: attribute.id,
        name: attribute.name,
        type: attribute.type,
      };

      if (attribute.values) {
        formatedAttribute.values = attribute.values.data.map(value => {
          return {
            id: value.id,
            value: value.value,
          };
        });
      }

      return formatedAttribute;
    });
  }

  return formated;
}

export default class InterventionResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'categories/interventions';
  }

  getAttributeTypes () {
    let url = this.apiUrl + 'categories/intervention-attribute-types';
    return this._http.get(url).then(response => response.data.data);
  }

  format (item) {
    return format(item);
  }
}
