import { GridRefToCoords } from 'common/utils/services/gridRefToCoords';
import InvalidGridReferenceError from 'common/utils/errors/invalidGridReferenceError';

const REGEX_POSTCODE = /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]?\s?[0-9][A-Za-z]{2}|[Gg][Ii][Rr]\s0[Aa]{2})$/;
const REGEX_GRID_REFERENCE = /^[a-zA-Z]{2}\s?\d{2,6}\s?\d{0,6}?$/;

class Location {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($q, $http, GOOGLE_GEOCODING_KEY) {
    this._$q = $q;
    this._$http = $http;
    this._GOOGLE_GEOCODING_KEY = GOOGLE_GEOCODING_KEY;
  }

  isGridReference (value) {
    return REGEX_GRID_REFERENCE.test(value);
  }

  isPostcode (value) {
    return REGEX_POSTCODE.test(value);
  }

  resolveCoordinatesFromGridReference (gridref) {
    try {
      return GridRefToCoords(gridref);
    } catch (e) {
      if (e instanceof InvalidGridReferenceError) {
        throw new Error('Invalid grid reference');
      } else {
        throw new Error('Converting grid reference to coordinates failed');
      }
    }
  }

  resolveCoordinatesFromPostcode (postcode) {
    const key = this._GOOGLE_GEOCODING_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode},+UK&key=${key}`;
    return this._$http
      .get(url)
      .then(
        response => {
          const data = response.data;
          if (data.status !== 'OK' || !data.results) {
            throw new Error('Postcode lookup error');
          }
          return data.results[0].geometry.location;
        },
        () => {
          throw new Error('Postcode lookup error');
        }
      );
  }

  resolveCoordinates (value) {
    const isPostcode = this.isPostcode(value);
    const isGridReference = this.isGridReference(value);

    if (!isPostcode && !isGridReference) {
      return this._$q.reject(new Error('Invalid grid reference or postcode'));
    }

    if (isPostcode) {
      return this.resolveCoordinatesFromPostcode(value);
    } else {
      try {
        return this._$q.resolve(this.resolveCoordinatesFromGridReference(value));
      } catch (e) {
        return this._$q.reject(e);
      }
    }
  }
}

export default Location;
