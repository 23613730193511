import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ChangePasswordComponent from './components/change-password';

const moduleConfig = function ($stateProvider) {
  'ngInject';
  $stateProvider.state('changePassword', {
    url: '/change-password',
    template: '<change-password></change-password>',
    data: {
      title: 'Change Password',
    },
  });
};

const module = angular
  .module('gnaas.pages.changePassword', [
    uiRouter,
  ])
  .config(moduleConfig)
  .component('changePassword', ChangePasswordComponent);

export default module.name;
