export default class AuditToolResource {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($http, API_URL) {
    this._http = $http;
    this.baseUrl = `${API_URL}audit-tool`;
  }

  getCategories () {
    return this._http.get(`${this.baseUrl}/categories`).then(res => res.data.data);
  }

  search (criteria, columnsToDisplay) {
    return this._http.post(`${this.baseUrl}/search`, {
      criteria: criteria,
      columns: columnsToDisplay,
    }).then(res => res.data.data);
  }
}
