import angular from 'angular';
import uiRouter from 'angular-ui-router';
import CrewMembersComponent from './components/crewMembers';
import CrewMemberListComponent from './components/crewMemberList';
import CrewMemberProfileComponent from './components/crewMemberProfile';
import CrewMemberResource from './services/crewMemberResource';

const moduleConfig = function ($stateProvider) {
  'ngInject';
  $stateProvider.state('crewMembers', {
    url: '/crew-members',
    template: '<crew-members layout="column" flex></crew-members>',
    data: {
      title: 'Crew Members',
    },
  });
};

const module = angular
  .module('gnaas.pages.crewMembers', [
    uiRouter,
  ])
  .config(moduleConfig)
  .component('crewMembers', CrewMembersComponent)
  .component('crewMemberList', CrewMemberListComponent)
  .component('crewMemberProfile', CrewMemberProfileComponent)
  .service('CrewMemberResource', CrewMemberResource);

export default module.name;
