import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class HandoverDialogController extends ResourceDialogController {
  init () {
    super.init();
    // This resource will temporarily use the PRF resource and update handover as part of the PRF.
    // This is to save significand amount of time on development in the short term (especially iPad & offline sync).
    // Eventually this is to be refactored to use the proper HandoverResource.
    this._resource = this.$injector.get('PRFResource');
    this._ID = uuid.v4();
  }

  create (data) {
    data.handover.id = this._ID;
    if (data.handover.controlled_drugs_disposed_at_receiving_hospital == null) {
      data.handover.controlled_drugs_disposed_at_receiving_hospital = false;
    }
    if (data.handover.infusions_continued_by_receiving_team == null) {
      data.handover.infusions_continued_by_receiving_team = false;
    }

    this._resource.update(data.id, data, { include: 'handover' }).then(item => {
      this.Dialog.hide(item);
    });
  }

  update (data) {
    this._resource.update(data.id, data, { include: 'handover' }).then(item => {
      this.Dialog.hide(item);
    }).catch();
  }
}
