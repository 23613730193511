import RestResource from 'common/resource/services/restResource';
import moment from 'moment';
import { format as formatIncident } from './incident.resource';
import { format as formatCrewMember } from 'pages/crewMembers/services/crewMemberResource';

export function format (item) {
  const i = {
    id: item.id,
    comment: item.comment,
    flagged_on_time: moment(item.flagged_on_time).format('YYYY-MM-DD HH:mm:ss'),
    resolved_comment: item.resolved_comment,
    resolved: !!item.resolved,
    resolved_on_time: item.resolved_on_time
      ? moment(item.resolved_on_time).format('YYYY-MM-DD HH:mm:ss')
      : null,
  };

  if (item.incident) i.incident = formatIncident(item.incident.data);
  if (item.flaggedBy) i.flagged_by = formatCrewMember(item.flaggedBy.data);
  if (item.resolvedBy) i.resolved_by = formatCrewMember(item.resolvedBy.data);

  return i;
}

export function parse (item) {
  const i = {
    id: item.id,
    comment: item.comment,
    flagged_on_time: moment(item.flagged_on_time).format('YYYY-MM-DD HH:mm:ss'),
    resolved_comment: item.resolved_comment,
    resolved: item.resolved,
    resolved_on_time: moment(item.resolved_on_time).format('YYYY-MM-DD HH:mm:ss'),
  };

  if (item.incident) {
    i.incident_id = item.incident.id;
  }

  return i;
}

export default class IncidentResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'flagged-incidents';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
