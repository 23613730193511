import angular from 'angular';
import VehicleCallsignStatusUpdateDialog from
    './services/vehicle-callsign-status-update-dialog/vehicle-callsign-status-update-dialog.service';
import VehicleCallsignStatus from './components/vehicle-callsign-status.component';

const module = angular
  .module('gnaas.core.vehicles', [])
  .service('VehicleCallsignStatusUpdateDialog', VehicleCallsignStatusUpdateDialog)
  .component('vehicleCallsignStatus', VehicleCallsignStatus);

export default module.name;
