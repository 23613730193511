import moment from 'moment';
import { copy } from 'angular';
import { DATE_TIME_FORMAT } from 'common/resource/module.constants';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

const DEFAULT_DESTROY_TYPES = [
  { id: 'destroyed', name: 'Destroyed' },
  { id: 'broken', name: 'Broken' },
  { id: 'pre-drawn-wasted', name: 'Pre-Drawn Wasted' },
];

export default class DestroyBatchController extends ResourceDialogController {

  init () {
    super.init();

    this.StockLocationResource = this.$injector.get('StockLocationResource');
    this.DrugStockTransactionResource = this.$injector.get('DrugStockTransactionResource');
    this.DrugBatchesResource = this.$injector.get('DrugBatchesResource');
    this.TransactionApprovalService = this.$injector.get('TransactionApprovalService');

    this.StockLocationResource.index().then(items => { this.stockLocations = items; });
    this.destroyTypes = [...DEFAULT_DESTROY_TYPES];

    this._createWatchers();

    // Handles pre-populating data from calendar.
    // Not the cleanest solution, but acceptable for the time being.
    // If this dialog needs to be more flexible - refactor!
    if (this.locals.fromCalendar && this.item) {
      this._setupFromCalendarUseCase();
    }

    this.item = Object.assign({}, this.item, { timestamp: moment().startOf('minute').toDate() });
  }

  submit (input) {
    if (!this.form) throw new Error('Form not found.');
    if (this.form.$invalid) return;

    this.TransactionApprovalService
      .attempt(this._createTransaction.bind(this), input)
      .then(data => {
        this.Dialog.hide(data);
      })
      .catch(err => {
        const errors = err.data.errors;
        if (errors && errors.hasOwnProperty('quantity')) {
          this.Dialog.alert('Could not destroy unit\\-s: ' + errors.quantity[0], 'Destroy Batch Error');
        }
      });
  }

  onSelectChange ({ name }) {
    if (name === 'stock_location_id') {
      this.item.drug_id = null;
      this.item.expires_on = null;
      this.item.batch_id = null;
    }
    this.item.quantity = null;
  }

  _createWatchers () {
    this.$injector.get('$rootScope').$new().$watch(
      () => this.stockLocationId,
      (newVal, oldVal) => {
        if (!newVal) return;
        this._stockLocationChanged(newVal);
      }
    );
  }

  _createTransaction (input) {
    const data = this._parseData(input);
    return this.DrugStockTransactionResource.create(data);
  }

  _stockLocationChanged (stockLocationId) {
    this.DrugBatchesResource
      .index({ location_id: stockLocationId, include: 'drug', quantity_mode: 'non-empty' })
      .then(drugBatches => {
        this.drugMap = this._mapDrugs(drugBatches);
        this.drugs = Object.values(this.drugMap).map(item => { return { id: item.id, name: item.name }; });
      })
      .catch(console.log.bind(console));
  }

  _mapDrugs (batches) {
    return batches.reduce((acc, item) => {
      const drugId = item.drug.id;
      if (!acc.hasOwnProperty(drugId)) {
        acc[drugId] = { id: drugId, name: item.drug.name, expiryMap: {}, expiries: [] };
      }

      const expiryMap = acc[drugId].expiryMap;
      const expiries = acc[drugId].expiries;
      const expiry = item.expires_on.split(' ')[0];
      if (!expiryMap.hasOwnProperty(expiry)) {
        expiryMap[expiry] = [];
        expiries.push(expiry);
      }

      expiryMap[expiry].push(item);

      return acc;
    }, {});
  }

  _parseData (data) {
    const d = copy(data);

    d.timestamp = moment(d.timestamp).format(DATE_TIME_FORMAT);
    delete d.expires_on;
    delete d.drug_id;

    return d;
  }

  _setupFromCalendarUseCase () {
    this.stockLocationId = this.item.location ? this.item.location.id : null;
    this.item.transaction_type = 'destroyed';
    this.item.expires_on = this.item.expires_on.split(' ')[0];
    this.item.drug_id = this.item.drug.id;
    this.item.batch_id = this.item.id;
    this.item.quantity = this.item.available_quantity;
  }
}
