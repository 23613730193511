export default class DashboardPageService {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($q, BaseResource, CalendarAgendaResource, CalendarEventResource, CrewMemberResource,
      DeploymentStatusResource, HospitalResource, IncidentResource, LocationResource, OfflineReasonResource,
      VehicleCallsignResource, VehicleCallsignStatusUpdateResource
  ) {
    this._q = $q;
    this._BaseResource = BaseResource;
    this._CalendarAgendaResource = CalendarAgendaResource;
    this._CalendarEventResource = CalendarEventResource;
    this._CrewMemberResource = CrewMemberResource;
    this._DeploymentStatusResource = DeploymentStatusResource;
    this._HospitalResource = HospitalResource;
    this._IncidentResource = IncidentResource;
    this._LocationResource = LocationResource;
    this._OfflineReasonResource = OfflineReasonResource;
    this._VehicleCallsignResource = VehicleCallsignResource;
    this._VehicleCallsignStatusUpdateResource = VehicleCallsignStatusUpdateResource;
  }

  getDeploymentRelationData () {
    return this._q.all({
      deploymentStatuses: this._DeploymentStatusResource.index(),
      hospitals: this._HospitalResource.index(),
      locations: this._LocationResource.index(),
    }).then(relationData => {
      return {
        deploymentStatuses: relationData.deploymentStatuses,
        destinations: {
          hospitals: relationData.hospitals,
          locations: relationData.locations,
        },
      };
    });
  }

  getVehicleCallsignRelationData () {
    return this._OfflineReasonResource.index().then(relationData => {
      return {
        offlineReasons: relationData,
        statuses: {
          'Offline': 0,
          'Online': 1,
        },
      };
    });
  }

  getCrewMembers () {
    return this._CrewMemberResource.index({
      filter: 'operational-crew',
      include: 'group,profile.profession,profile.image',
    }).then(data => data.map(item => ({ ...item, name: `${item.first_name} ${item.last_name}` })));
  }

  getCalendarEvents (date) {
    return this._CalendarEventResource.index({
      include: 'type,vehicle,users',
      start_date: date,
      end_date: date,
    });
  }

  getCalendarAgenda (date) {
    return this._CalendarAgendaResource.index({
      start_date: date,
      end_date: date,
    }).then(agendas => agendas.length ? agendas[0] : null);
  }

  getIncidents (date) {
    return this._IncidentResource.index({
      include: [
        'serviceArea', 'refusalReason',
        'ambulanceService', 'deploymentReason', 'deployments.vehicleCallsign',
        'deployments.deploymentStatuses', 'deployments.deploymentStatuses.deploymentStatus',
        'deployments.patientReportForms',
      ],
      start_date: date,
      end_date: date,
    });
  }

  getBases () {
    return this._BaseResource.index();
  }

  getVehicleCallsigns () {
    return this._VehicleCallsignResource.index({
      include: [
        'vehicle',
        'deploymentStatusUpdates:limit(1):order(time)',
        'deploymentStatusUpdates.deploymentStatus',
        'deploymentStatusUpdates.destination',
      ],
    });
  }

  getVehicleCallsign (id) {
    return this._VehicleCallsignResource.show(id);
  }

  getVehicleCallsignStatusUpdates (date) {
    return this._VehicleCallsignStatusUpdateResource.index({
      start_date: date,
      end_date: date,
    });
  }
}
