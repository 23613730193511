export default {
  bindings: {
    searchQuery: '@',
    items: '<',
    disableSelection: '@',
    onSelect: '&',
  },
  controller: class {
    $onChanges (changesObj) {
      if (!this.disableSelection &&
          !this.selectedItemId &&
          changesObj.items &&
          changesObj.items.currentValue &&
          changesObj.items.currentValue.length) {
        this.selectedItemId = this.items[0].id;
      }
    }

    selectItem (item) {
      this.selectedItemId = item.id;
      this.onSelect({$event: { crewMember: item }});
    }
  },
  template: function ($element, $attrs) {
    'ngInject';

    const clickEvent = $attrs.onSelect ? 'on-click="$ctrl.selectItem(crewMember)"' : '';

    return `
      <div ng-repeat="crewMember in $ctrl.items |
              filter: $ctrl.searchQuery |
              orderBy:['first_name','last_name']
              track by $index">
          <avatar-item class="c-crew-list__item"
                  ng-class="{'s-is-selected': $ctrl.selectedItemId === crewMember.id}"
                  ${clickEvent}
                  title="{{ crewMember.first_name }} {{ crewMember.last_name }}"
                  text="{{ crewMember.profession.title }}"
                  image="{{ crewMember.profile_image.url }}">
          </avatar-item>
          <md-divider md-inset ng-if="!$last"></md-divider>
      </div>
    `;
  },
};
