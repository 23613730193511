import RestResource from 'common/resource/services/restResource';
import { format as formatCallsign } from 'pages/admin/vehicleCallsigns/services/vehicleCallsignResource';

export function format (item) {
  return {
    id: item.id,
    name: item.name,
    device_id: item.device_id,
    vehicle_callsign: item.vehicleCallsign ? formatCallsign(item.vehicleCallsign.data) : null,
    active: !!item.active,
  };
}

export function parse (item) {
  const i = { ...item };
  i.vehicle_callsign_id = i.vehicle_callsign ? i.vehicle_callsign.id : null;
  delete i.vehicle_callsign;
  return i;
}

export default class DeviceResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'devices';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
