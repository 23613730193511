import ResourceDialog from 'common/resource/services/resourceDialog';
import VehicleCallsignStatusUpdateDialogController from './vehicle-callsign-status-update-dialog.controller';

export default class VehicleCallsignStatusUpdateDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(VehicleCallsignStatusUpdateDialogController);
    this.setTemplateUrl('core/vehicles/services/vehicle-callsign-status-update-dialog/' +
        'vehicle-callsign-status-update-dialog.tpl.html');
  }
}
