import ResourceDialog from 'common/resource/services/resourceDialog';
import DestroyBatchController from './destroy-batch-dialog.controller';

export default class ManualAdjustmentDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(DestroyBatchController);
    this.setTemplateUrl('core/drugs/services/destroy-batch-dialog/destroy-batch-dialog.tpl.html');
  }
}
