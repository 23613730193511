import { toDateTimeString } from 'common/utils/services/dateTime';

export default function parse (item) {
  return {
    id: item.id,
    prescription_id: item.prescription.id,
    dose: item.dose,
    rate: item.rate,
    time: toDateTimeString(item.date, item.time),
  };
}
