import angular from 'angular';
import uiRouter from 'angular-ui-router';
import MapPage from './components/map-page';
import MapSidenav from './components/mapSidenav';
import MapFilterSidenav from './components/mapFilterSidenav';
import MapService from './services/mapService';

const moduleConfig = function ($stateProvider) {
  'ngInject';
  $stateProvider.state('map', {
    url: '/map',
    template: '<map-page layout="column" flex></map-page>',
    data: {
      title: 'Map',
    },
  });
};

const module = angular
  .module('gnaas.pages.map', [
    uiRouter,
  ])
  .config(moduleConfig)
  .component('mapPage', MapPage)
  .component('mapSidenav', MapSidenav)
  .component('mapFilterSidenav', MapFilterSidenav)
  .service('MapService', MapService);

export default module.name;
