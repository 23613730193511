import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class CreateInfusionController extends ResourceDialogController {
  init () {
    super.init();
    this.InfusionResource = this.$injector.get('InfusionResource');
    this.ID = uuid.v4();
  }

  delete (id) {
    this.Dialog.confirm().then(() => {
      this.InfusionResource.destroy(id).then(() => {
        this.Dialog.cancel(id);
      });
    });
  };

  create (data) {
    data.id = this.ID;

    this.InfusionResource.create(data).then(() => {
      this.Dialog.hide(data);
    }).catch(err => {
      if (err.status) {
        this.Toast.showSimple('Error: could not create infusion.');
      }
    });
  };

  update (data) {
    this.InfusionResource
      .update(data.id, data)
      .then(infusion => { this.Dialog.hide(infusion); })
      .catch(err => {
        if (err.status) {
          this.Toast.showSimple('Error: could not update infusion.');
        }
      });
  };
}
