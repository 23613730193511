import TransactionApprovalController from './transaction-approval-dialog.controller';

export default class TransactionApprovalDialog {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog) {
    this._Dialog = Dialog;
    this._dialogOptions = {
      controller: TransactionApprovalController,
      templateUrl: 'core/drugs/services/transaction-approval-dialog/transaction-approval-dialog.tpl.html',
    };
  }

  show ({ $event, approvalId, dataOnly, userEmails }) {
    const dialogParams = {
      ...this._dialogOptions,
      targetEvent: $event || null,
      multiple: true,
      locals: { approvalId, dataOnly, userEmails },
    };
    return this._Dialog.show(dialogParams);
  }
}
