import { element } from 'angular';

export default function FormCheckboxList () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      columns: '@feColumns',
      formSubmitted: '<feFormSubmitted',
      hideListLabel: '<feHideListLabel',
      hideListToggle: '<feHideListToggle',
      labelKey: '@feLabelKey',
      list: '=feList',
      listTitle: '@feListTitle',
      required: '<feRequired',
      hideToggleAllButton: '<feHideToggleAllButton',
      selectedItems: '=feSelectedItems',
      trackByKey: '@feTrackByKey',
    },
    controller: function ($element, $scope, $timeout, Utils) {
      'ngInject';

      this.trackByKey = this.trackByKey || 'id';
      this.labelKey = this.labelKey || 'name';
      this.columns = this.columns || '3';
      this.list = this.list || [];
      this.selectedItems = this.selectedItems || [];
      this.flex = {1: 1, 2: 50, 3: 33, 4: 25}[this.columns];
      this.hideListLabel = !!this.hideListLabel;
      this.hideListToggle = !!this.hideListToggle;
      this.hideToggleAllButton = !!this.hideToggleAllButton;
      this.required = !!this.required;
      this.formSubmitted = !!this.formSubmitted;
      this.listToggled = true;

      let list, listHeight;

      const getIndex = typeof this.list[0] === 'object'
          ? (item, list) => {
            const object = Utils.findObjectInArrayByKey(list, item[this.trackByKey], this.trackByKey);
            return list.indexOf(object);
          }
          : (item, list) => {
            return list.indexOf(item);
          };

      const setHasErrors = () => {
        this.hasErrors = this.required ? (this.formSubmitted && !this.selectedItems.length) : false;
      };

      const setHeight = () => {
        $timeout(() => {
          list = element($element[0].querySelector('div.form-checkbox-list__items'));
          listHeight = list.prop('clientHeight');
          if (listHeight !== 0) {
            list.css('height', this.listToggled ? listHeight : 0);
          }
        }, 100);
      };

      setHeight();
      setHasErrors();
      $scope.$watch(() => this.list, () => { setHeight(); });
      $scope.$watch(() => this.formSubmitted, () => { setHasErrors(); });
      $scope.$watch(() => this.selectedItems, () => { setHasErrors(); }, true);

      this.toggleList = () => {
        this.listToggled = !this.listToggled;
        if (this.listToggled) {
          list.css('height', listHeight + 'px');
        } else {
          list.css('height', 0);
        }
      };

      this.exists = (item) => {
        return getIndex(item, this.selectedItems) > -1;
      };

      this.toggleCheckbox = (item) => {
        const i = getIndex(item, this.selectedItems);
        if (i > -1) {
          this.selectedItems.splice(i, 1);
        } else {
          this.selectedItems.push(item);
        }
      };

      this.toggleAll = () => {
        this.selectedItems = this.list.length === this.selectedItems.length ? [] : [...this.list];
      };
    },
    controllerAs: '$ctrl',
    bindToController: true,
    templateUrl: 'common/form/directives/formCheckboxList.tpl.html',
  };
};
