export const BATCH_MANAGEMENT_UPDATE_EVENT = 'BATCH_MANAGEMENT_UPDATE_EVENT';

export default class BatchManagementController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($state, $stateParams, RecordDeliveryDialog, TransferBatchDialog, DrugOrderFormDialog,
                ManualAdjustmentDialog, DestroyBatchDialog, $rootScope) {
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.RecordDeliveryDialog = RecordDeliveryDialog;
    this.TransferBatchDialog = TransferBatchDialog;
    this.DrugOrderFormDialog = DrugOrderFormDialog;
    this.ManualAdjustmentDialog = ManualAdjustmentDialog;
    this.DestroyBatchDialog = DestroyBatchDialog;
    this.$rootScope = $rootScope;
  }

  $onInit () {
    this.actions = [
      {
        name: 'Record Delivery',
        action: () => this.showDialog(this.RecordDeliveryDialog),
      },
      {
        name: 'Manual Adjustment',
        action: () => this.showDialog(this.ManualAdjustmentDialog),
      },
      {
        name: 'Transfer Batch',
        action: () => this.showDialog(this.TransferBatchDialog),
      },
      {
        name: 'Destroy Batch',
        action: () => this.showDialog(this.DestroyBatchDialog),
      },
      {
        name: 'Print Order',
        action: () => this.showDrugOrderFormDialog(),
      },
    ];

    this.tabIndex = this.$state.includes('**.batchManagement.drugBreakdown') ? 1 : 0;
  }

  showDialog (Resource) {
    Resource.show({}).then(() => {
      this.$rootScope.$emit(BATCH_MANAGEMENT_UPDATE_EVENT);
    });
  }

  showDrugOrderFormDialog () {
    this.DrugOrderFormDialog.show({});
  }
}
