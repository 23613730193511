import ResourceDialog from 'common/resource/services/resourceDialog';
import RecordDrugUseDialogController from './record-drug-use-dialog.controller';

export default class RecordDrugUseDialog extends ResourceDialog {
  init () {
    super.init();
    this.setController(RecordDrugUseDialogController);
    this.setTemplateUrl('core/prf/services/record-drug-use-dialog/record-drug-use-dialog.tpl.html');
  }
}
