export default {
  bindings: {
    agenda: '@',
  },
  template: `
    <h3 class="f-subheading f-subheading--small u-padding-wide-x">Agenda</h3>
    <div class="c-calendar-agenda">
        <div ng-if="$ctrl.agenda" class="c-calendar-agenda__textarea"
            ng-bind-html="$ctrl.agenda | nl2br"></div>
        <div ng-if="$ctrl.agenda" class="c-calendar-agenda__gradient"></div>
        <span ng-if="!$ctrl.agenda" class="o-no-content o-no-content--padding-x">
            There is no agenda created for today.
        </span>
    </div>
  `,
};
