import ResolveIncidentDialogController from './resolve-incident-dialog.controller';

export default class FlagIncidentDialog {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog) {
    this.Dialog = Dialog;
    this.dialogParams = {
      controller: ResolveIncidentDialogController,
      templateUrl: 'core/incidents/services/resolve-incident-dialog/resolve-incident-dialog.tpl.html',
    };
  }

  show ({ $event, item }) {
    const dialogParams = {
      ...this.dialogParams,
      targetEvent: $event || null,
      multiple: true,
      locals: { item },
    };
    return this.Dialog.show(dialogParams);
  }
}
