import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class RejectDrugController extends ResourceDialogController {
  init () {
    super.init();
    this.DrugResource = this.$injector.get('DrugResource');
    this.Toast = this.$injector.get('Toast');
  }

  reject (id, drug) {
    this.DrugResource
      .reject(id, drug)
      .then(() => { this.Dialog.hide(); })
      .catch(err => {
        if (err.status) {
          this.Toast.showSimple('Error: could not reject drug.');
        }
      });
  }
}
