function detectLeftButton (evt = window.event) {
  if ('buttons' in evt) {
    return evt.buttons === 1;
  }
  let button = evt.which || evt.button;
  return button === 1;
}

function calcDistancePercentageMovedX (elX, svgWidth) {
  return (elX / svgWidth) * 100 + '%';
}

function calcDistancePercentageMovedY (elY, svgHeight) {
  return (elY / svgHeight) * 100 + '%';
}

class BodyPartSelector {
  $onInit () {
    const bodySvg = document.getElementById('body-svg');
    this.svgWidth = bodySvg.getAttribute('width');
    this.svgHeight = bodySvg.getAttribute('height');
    this.circleRadius = document.getElementById('marker').getAttribute('r');

    if (this.item) {
      this.item = this.updateMarkerPosition(this.item, this.item.x, this.item.y);
    }

    if (this.markers) {
      this.markers.forEach(item => (this.updateMarkerPosition(item, item.x, item.y)));
    }
  }

  $onChanges (changesObj) {
    if (!changesObj.markers || !changesObj.markers.currentValue) return;

    this.markers = changesObj.markers.currentValue.map(item => {
      item = this.updateMarkerPosition(item, item.x, item.y);
      return item;
    })
    .sort((a, b) => {
      if (a.class === 'green') {
        return -1;
      }
      if (a.class === 'red') {
        return 1;
      }
      return 0;
    });
  }

  move (evt) {
    if (!detectLeftButton(evt) || !this.item) {
      return;
    }
    evt.preventDefault();
    this.item = this.updateMarkerPosition(this.item, evt.offsetX, evt.offsetY);
  }

  updateMarkerPosition (marker, x, y) {
    marker.x = x;
    marker.y = y;
    marker.cx = calcDistancePercentageMovedX(x - (this.circleRadius / 2), this.svgWidth);
    marker.cy = calcDistancePercentageMovedY(y - (this.circleRadius / 2), this.svgHeight);
    return marker;
  }
}

export default {
  bindings: {
    item: '<',
    markers: '<',
    isEditing: '<',
  },
  controller: BodyPartSelector,
  templateUrl: 'common/body-part-selector/components/body-part-selector.tpl.html',
};
